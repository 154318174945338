@import "../../../../styles/_colors";

.taxonomyList {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  gap: 8px;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 360px;
}

.taxonomyListWrap {
  display: flex;
  flex-direction: row;
  list-style: none;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  gap: 8px;
  overflow-y: hidden;
  width: 100% !important;
}

.taxonomyList::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.taxonomyList::-webkit-scrollbar-thumb,
.taxonomyList::-webkit-scrollbar-track {
  border-radius: 20px;
}

.taxonomyList::-webkit-scrollbar-thumb {
  outline: 1px solid $brand-hover-accent;
  background-color: $brand-hover-accent;
}

.taxonomyList::-webkit-scrollbar-track {
  //background-color: $brand-hover-accent;
}

.addTagInput {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border: 1px solid $main-operational-accent;
  border-radius: 80px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: 8px;
  cursor: pointer;
}

.addTaxonomy {
  border: 1px solid $main-operational-accent;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 80px;
  height: 32px !important;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  padding-left: 16px;
}
.addTaxonomy > *:first-child {
  margin-right: 8px;
}

.addTaxonomy:focus {
  border: none;
  outline: none;
  background: transparent;
}

.taxonomyInput {
  width: 100%;
  justify-self: center;
  align-self: center;
  height: 32px;
}
