@import "../../../../styles/colors";

@keyframes showModal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hidden {
  display: none;
}

.hidden * {
  display: none;
}

.hidden > * {
  display: none;
}

.overviewEventWrapper {
  width: 471px;
  height: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000000002;

  &Mobile {
    width: 100svw;
    height: 100svh;
    overflow-y: scroll;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    overflow-y: scroll;
    background: #ffffff;
    width: 100%;
    padding: 40px 32px;
    animation-name: showModal;
    animation-duration: 1s;
    box-shadow: 0px 5px 30px rgba(40, 27, 36, 0.05);
  }

  .flexRow {
    display: flex;
    gap: 8px;
  }

  .public {
    color: #47817E;
  }

  .private {
    color: #98243A;
  }

  .highlighted {
    color: #835878;
  }

  .overviewEventModal {


    > p {
      margin-bottom: 16px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      > div {
        display: flex;
        align-items: center;
        gap: 24px;

        .eventIcon {
          width: 48px;
          height: 48px;
          border-radius: 8px;

          img {
            padding: 8px;
          }
        }
      }
    }
  }

  .invitedGuests {
    margin: 36px 0;
    .invited {
      margin: 16px 0;
      display: flex;
      flex-direction: column;
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
          display: flex;
          gap: 12px;
          align-items: center;
          margin-top: 8px;
        }
      }

      .bubbleCoupleInitials {
        width: 48px;
        height: 48px;
        background-color: var(--primaryLight);
        border-radius: 50%;
        padding: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-right: -4px;
        border: 0.1em $main-strong-accent solid;

        p {
          color: $main-strong-accent;
          justify-self: center;
          align-self: center;
        }
      }
    }
  }

  .eventQuestions {
    .imgWrapper {
      padding: 8px;
      background-color: $brand-operational-accent;
      border-radius: 4px;
      cursor: pointer;
    }
    .questions {
      display: flex;
      flex-direction: column;
      gap: 16px;
      > div {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}