@import "../TheArtist.module.scss";

.ScheduleComponentWrapper {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 66px;
  width: 100%;
  height: fit-content;
  padding: 91px 16px;

  .title {
    font-family: Syne;
    color: #333; 
    font-size: 50.52px;
    font-weight: 400;
    line-height: 54px; /* 106.888% */
    letter-spacing: -0.63px;
    text-align: center;

    @media only screen and (min-width: 640px) {
      padding-left: 100px;
    }
  }

  div > span {
    color: #333;
    font-family: Syne; 
    font-size: 37.897px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 126.657% */
    letter-spacing: -1.137px;
  }

  @media only screen and (max-width: 640px) {

    div:nth-of-type(2) {
      flex-direction: column;
      align-items: center;
    }

    img:nth-of-type(2) {
      display: none;
    }
  }
}

.ScheduleComponent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  div > h2 {
    color: #333;
    text-align: center;
    font-family: CenturyGothic;
    font-size: 28.43px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 140.695% */
    letter-spacing: -0.853px;
  }

  div > div > div {
    color: #333;
    font-family: Syne;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 133.333% */
    letter-spacing: -0.72px;
  }

  div > div > a > p {
    color: #333;
    font-family: CenturyGothic;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.54px;
  }

  .RSVPButton {
    font-family: CenturyGothic;
    font-size: 16px;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.54px;
    color: #FBFBFB;
    text-align: center;
    width: 100%;
    padding: 12px 32px;
    border-radius: 4px;
    background: #4A4848;
    border: 1px solid transparent;

    &:hover {
      background: #FBFBFB;
      color: #000000;
      border: 1px solid #4A4848;
    }

  }
}

.schedule  {
  text-align: center;
  padding: 16px;

  * {
    color: #333;
    font-family: CenturyGothic;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.54px;
  }
}

@media only screen and (max-width: 660px) {
  .ScheduleComponentWrapper {
    padding-top: 44px;
    padding-bottom: 44px;
  }

  .ScheduleComponent > div:first-child {
    display: none;
  }

  .ScheduleComponent > div:nth-child(2) {
    width: 100%;
    justify-content: center;
    align-items: flex-end;
  }
  //EVENT NAME
  .ScheduleComponent > div:nth-child(2) > div:nth-child(1) {
    display: none;
  }

  .ScheduleComponent > div:nth-child(2) > div:nth-child(3) {
    > h2 {
      text-align: left;
    }
  }

  .ScheduleComponent > div:nth-child(2) > div:nth-child(3) > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div:first-child {
      font-size: 16px;
      font-family: "DidactGothic-Regular";
      font-weight: 400;
      letter-spacing: -0.03em;
      text-align: left;
      width: 112px;
      margin-right: 0px;
      margin-left: 0px;
      padding: 0;
      display: flex;
      height: 38px;
      text-align: left;
    }
    > div:nth-child(2) {
      height: 40px;
    }
  }
  .ScheduleComponent > div:nth-child(2) > div > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div:first-child {
      font-size: 16px;
      font-family: "DidactGothic-Regular";
      font-weight: 400;
      letter-spacing: -0.03em;
      text-align: left;
      width: 112px;
      margin-right: 0px;
      margin-left: 0px;
      padding: 0;
      display: flex;
      height: 38px;
      text-align: left;
    }
    > div:nth-child(2) {
      height: 40px;
    }
  }

  .ScheduleComponent > div:nth-child(2) > div > a {
    margin-left: 126px;
  }
  .ScheduleComponent > div:nth-child(2) > div:nth-child(3) > div:first-child {
    justify-content: center;
    display: flex;
    width: 100%;
    position: relative;
    margin: 0;
  }

  .ScheduleComponent > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div:first-child {
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;
  }
  .ScheduleComponent > div:nth-child(2) > h2 {
    font-size: 37.48px !important;
  }
  .ScheduleComponent > div:nth-child(2) > div > h2 {
    font-size: 28.43px !important;
  }
  .ScheduleComponent > div:nth-child(2) > div > h2 {
    font-size: 28.43px !important;
  }
  .ScheduleComponent > div:nth-child(2) > div > a {
    font-size: 16px !important;
  }
  .ScheduleComponent > div:nth-child(2) > div > div > div {
    font-size: 21.33px !important;
  }
  .ScheduleComponent > div:last-child {
    display: none;
  }
}
