@import "../../../../styles/colors";

@mixin flex() {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

@mixin flexColumn() {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

@keyframes showModal {
  0% {
    transform: translate(492px, 0);
    opacity: 0.7;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes showModalOpacity {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  gap: 24px;
  border-radius: 32px 0 0 32px;
  background: $white;
  box-shadow: -4px 4px 18px 0px #00000040;
  width: 528px;
  height: 100vh;
  padding: 64px 52px;
  z-index: 100;
  transform: translateX(1000px);
  transition: transform 1s ease-in-out;

  .hr {
    margin: 0 !important;
    border-top: 1px solid $main-operational-accent;
    background: none !important;
  }
}

.show {
  transform: translateX(0);
  transition: all 1s ease-in-out;
}

.flex {
  @include flex;
}

.flexColumn {
  @include flexColumn;
}

.checkboxWrapper {
  @include flex;
  justify-content: left;
}

.attendance,
.status {
  @include flexColumn;
  height: 192px;
  border-radius: 20px;
  gap: 8px;
  background: $brand-background-accent;
  padding: 16px;
  &Disabled {
    background: $main-background-accent;
    h4 {
        color: $secondary-hover-accent;
    }
    p {
        color: $main-operational-accent;
    }
  }
}

.tags {
  @include flexColumn;
  max-width: 416px;
  border-radius: 20px;
  gap: 8px;
  background: $brand-background-accent;
  padding: 16px;
  > div {
    @include flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}
