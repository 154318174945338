.SepiaContactInfoComponent {
  background-color: #fdf6eb;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 91px 16px;
  gap: 32px;
  > h1 {
    font-family: "Friday-Regular";
    font-size: 38px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.04em;
  }
  > div:last-child {
    max-width: 896px;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.Card {
  background-color: #fbccb1;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex-direction: column;
  border-radius: 24px;
  width: 350px;
  height: 200px;

  > div:first-child {
    display: flex;
    justify-content: center;
    gap: 16px;
    align-items: center;
    h3 {
      font-family: "DidactGothic-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 28.4302px;
      line-height: 32px;
      letter-spacing: -0.03em;
    }
    h4 {
      font-family: "DidactGothic-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 21.328px;
      line-height: 32px;
      letter-spacing: -0.03em;
    }
  }
}

//mobile
@media only screen and (max-width: 768px) {
  .SepiaContactInfoComponent {
    height: 60vh !important;
  }
  .SepiaContactInfoComponent {
    background-position: center;
    background-size: 400px !important;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 568px) {
  .SepiaContactInfoComponent {
    height: 50vh !important;
  }
  .SepiaContactInfoComponent {
    background-position: center;
    background-size: 350px !important;
    background-repeat: no-repeat;
  }
}
