@import "../../style";

.postedComment {
    margin: 16px 0;
}
.commentInfoSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}
.commentProfile {
    display: flex;
    align-items: center;
    grid-gap: 8px;
}
.commentProfile img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}
.postedComment > .cProfile > span {
    align-self: center;
    margin-right: 8px;
}
.postedComment > .cProfile  .edit-btn {
    width: 4px;
}
.postedComment > p {
    font-size: 1em;
    letter-spacing: -0.63px;
    font-weight: 400;
    line-height: 24px;
}
.commentProfile > span {
    color: #A1A1A1;
}
.commentArea {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    .content-css {
        width: 90%;
        word-break: break-all;
    }
}
.commenterFullname{
    color: $secondary-operational-accent;
}