@font-face {
  font-family: "Catamount";
  src: url("../../../fonts/CatamountSignature.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Noto";
  src: url("../../../fonts/NotoSerifDisplay.ttf") format("opentype");
}

.ScheduleComponentWrapper {
  background-color: #f1ede2;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 91px 16px;
  .title {
    font-family: Catamount !important;
    font-size: 128px;
    font-weight: 400;
    line-height: 130px;
    letter-spacing: 0.01em; 
    color: #1C3924;  
    text-align: center;
  }
  * {
    font-family: Noto !important;  
    font-weight: 300; 
    letter-spacing: -0.03em; 
  }
  
}

.ScheduleComponent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: #f1ede2;
}

 
.ScheduleComponent {
  text-align: left;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 32px; 
  div {
    gap: 26px;  
    display: flex;
    flex-direction: column; 
    
  }
  div > div:first-child {
    width: 100px; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  div > div { 
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
    justify-content: space-evenly;
  }
   
}

 

.ScheduleComponent > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;

  height: 100%;
}

.ScheduleComponent > div > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  letter-spacing: -0.03em;
  max-width: 299px;
  padding: 16px;
}

.ScheduleComponent > div:nth-child(2) > div > a {
  color: #835978;
}
.ScheduleComponent > div:nth-child(2) > div > a:hover {
  color: #f9ac80;
}

.schedule  {
  * {
    font-family: Noto !important; 
  }
}
.schedule > h1 {
  font-weight: 400;
  font-size: 37.8975px;
  line-height: 40px;
  /* identical to box height, or 106% */
  display: flex;
  align-items: center;
  letter-spacing: -1.5px;
  /* Text / Primary Text */
  color: #281b24;
  
  font-family: Noto !important; 
}

.schedule > h2 {
  font-weight: 400;
  font-size: 28.4302px;
  line-height: 32px;
  /* identical to box height, or 113% */
  display: flex;
  align-items: center;
  letter-spacing: -1.12px;
  /* Text / Primary Text */
  color: #281b24;
}

.schedule > h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.63px;

  /* Brand Color / Purple 2 */

  color: #835978;
}
.ScheduleComponent > div:nth-child(2) > div:nth-child(3) > div {
  > div:first-child {
    font-size: 16px;
    font-family: "DidactGothic-Regular";
    font-weight: 400;
    letter-spacing: -0.03em;
    text-align: left;
    width: 100px;
    display: flex;
  }
  > div:nth-child(2) {
    height: 40px;
  }
}

@media only screen and (max-width: 660px) {
  .ScheduleComponentWrapper {
    padding-top: 44px;
    padding-bottom: 44px;
  }

  .ScheduleComponent > div:first-child {
    display: none;
  }

  .ScheduleComponent > div:nth-child(2) {
    width: 100%;
    justify-content: center;
    align-items: flex-end;
  }
  //EVENT NAME
  .ScheduleComponent > div:nth-child(2) > div:nth-child(1) {
    display: none;
  }

  .ScheduleComponent > div:nth-child(2) > div:nth-child(3) {
    > h2 {
      text-align: left;
    }
  }

  .ScheduleComponent > div:nth-child(2) > div:nth-child(3) > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div:first-child {
      font-size: 16px;
      font-family: "DidactGothic-Regular";
      font-weight: 400;
      letter-spacing: -0.03em;
      text-align: left;
      width: 112px;
      margin-right: 0px;
      margin-left: 0px;
      padding: 0;
      display: flex;
      height: 38px;
      text-align: left;
    }
    > div:nth-child(2) {
      height: 40px;
    }
  }
  .ScheduleComponent > div:nth-child(2) > div > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div:first-child {
      font-size: 16px;
      font-family: "DidactGothic-Regular";
      font-weight: 400;
      letter-spacing: -0.03em;
      text-align: left;
      width: 112px;
      margin-right: 0px;
      margin-left: 0px;
      padding: 0;
      display: flex;
      height: 38px;
      text-align: left;
    }
    > div:nth-child(2) {
      height: 40px;
    }
  }

  .ScheduleComponent > div:nth-child(2) > div > a {
    margin-left: 126px;
  }
  .ScheduleComponent > div:nth-child(2) > div:nth-child(3) > div:first-child {
    justify-content: center;
    display: flex;
    width: 100%;
    position: relative;
    margin: 0;
  }

  .ScheduleComponent > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div:first-child {
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;
  }
  .ScheduleComponent > div:nth-child(2) > h2 {
    font-size: 37.48px !important;
  }
  .ScheduleComponent > div:nth-child(2) > div > h2 {
    font-size: 28.43px !important;
  }
  .ScheduleComponent > div:nth-child(2) > div > h2 {
    font-size: 28.43px !important;
  }
  .ScheduleComponent > div:nth-child(2) > div > a {
    font-size: 16px !important;
  }
  .ScheduleComponent > div:nth-child(2) > div > div > div {
    font-size: 21.33px !important;
  }
  .ScheduleComponent > div:last-child {
    display: none;
  }
}
