.SepiaGiftComponent {
  background-color: #fdf6eb;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 91px 16px;

  .Inside {
    max-width: 912px;
    border: 1px dashed #63435b;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 72px;
    gap: 72px;
    flex-wrap: wrap;

    &Graphic {
      width: 100%;
      max-width: 250px;
      height: 100%;
      max-height: 250px;
    }

    &Info {
      display: flex;
      flex-direction: column;
      gap: 16px;
      p {
        max-width: 401px;
      }
    }
  }
  .giftModal {
    display: flex;
    position: absolute;
    min-width: 467px;
    top: 0;
    left: 60px;
    flex-direction: column;
    gap: 16px;
    padding: 32px;
    background: #63435b;
    box-shadow: 0 3px 20px rgba(47, 46, 65, 0.05);
    border-radius: 16px;
    @media screen and (max-width: 900px) {
      max-width: 366px;
      left: 0;
      top: 63px;
    }
    * {
      color: #fdf6eb !important;
    }

    .highlighted {
      color: #fbccb1 !important;
      margin-right: 8px;
    }

    &Details {
      display: flex;
      gap: 16px;
      width: 100%;
      > button {
        margin-left: auto;
      }
    }
  }
}

.center {
  * {
    margin: auto;
  }
}
