@import "../../../styles/colors";

:root {
  --dark: #281b24;
  --primaryDark: #63435b;
  --primary: #835978;
  --primaryLight: #c7adc1;
  --accent: #f9ac80;
  --accentLight: #fbccb1;
  --greenLight: #c1c6a8;
}
.navbar {
  height: 100svh;
  position: absolute;
  left: 0;
  top: 0;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9;
  background-color: white;
  overflow-y: scroll !important;
  gap: 16px;
  min-width: 136px !important;
  width: 136px !important;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }
  .top {
    width: 100%;
  }

  .bottom {
    width: 100%;
    &Popup {
      width: 270px;
      max-width: 300px;
      padding: 16px 8px;
      display: none;
      flex-direction: column;
      position: absolute;
      background: #ffffff;
      //box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      border: 1px solid $main-operational-accent;
      border-radius: 8px;
      left: 0;
      margin-left: 116px;
      margin-top: -32px;
      bottom: 24px;
  
      &Show {
        display: flex;
      }
      &Item {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 0 16px;
        &:hover {
          background-color: $brand-hover-accent;
        }
        p {
          width: 100%;
          word-break: keep-all;
        }
      }
      &ItemCode {
        display: flex;
        gap: 2px;
        padding: 0 8px;
        &:hover {
          background-color: $brand-hover-accent;
        }
        p {
          width: 100%;
          word-break: keep-all;
        }
      }
    }
    .bubbleCoupleInitials {
      background-color: var(--primaryLight);
      border-radius: 50%;
      height: 72px;
      width: 72px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    
    .bubbleCoupleInitials > p {
      color: $main-strong-accent;
      justify-self: center;
      align-self: center;
    
      font-style: normal;
      font-weight: normal;
      font-size: 21.328px;
      line-height: 24px;
      letter-spacing: -2.84px;
    }
  }

  .app {
    background: linear-gradient(0deg, #c1c6a8, #c1c6a8), linear-gradient(0deg, #fbccb1, #fbccb1);
    border: 1px solid #fbccb1;
    height: 88px;
  }

  //MOBILE
  &Mobile {   
    left: 0 !important;
    bottom: 0 !important;
    background-color: #ffffff;
    height: 64px !important;
    width: 100% !important;
    display: flex !important;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(40, 27, 36, 0.25);
    border-radius: 16px 16px 0px 0px;
    
    .top {
      display: none;
    }
    .app {
      color: linear-gradient(0deg, #c1c6a8, #c1c6a8), linear-gradient(0deg, #fbccb1, #fbccb1);
      border: none;
      height: 24px;
    }

   
  }
  
  //TABLET
  &Tablet { 
    left: 0 !important;
    bottom: 0 !important;
    height: 80px !important;
    width: 100vw !important;
    display: flex !important;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(40, 27, 36, 0.25);
    border-radius: 16px 16px 0px 0px;
  }

  //SMALL LAPTOPS
  &Laptop { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .top {
      position:relative;
      //left:16px;
      //top:20px;
      width: 44px;
      height: 44px;
    }
    width: 96px !important; 
    padding: 40px 24px !important;

    .bottom {
      width: 100%;
      &Popup {
        width: 272px;
        max-width: 300px;
        padding: 16px 8px;
        display: none;
        flex-direction: column;
        position: absolute;
        background: #ffffff;
        //box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        border: 1px solid $main-operational-accent;
        border-radius: 8px;
        left: 0;
        margin-left: 96px;
        margin-top: -32px;
        bottom: 24px;
    
        &Show {
          display: flex;
        }
        &Item {
          display: flex;
          align-items: center;
          gap: 24px;
          padding: 0 16px;
          &:hover {
            background-color: $brand-hover-accent;
          }
          p {
            width: 100%;
            word-break: keep-all;
          }
        }
        &ItemCode {
          display: flex;
          gap: 2px;
          padding: 0 8px;
          &:hover {
            background-color: $brand-hover-accent;
          }
          p {
            width: 100%;
            word-break: keep-all;
          }
        }
      }
      .bubbleCoupleInitials {
        background-color: var(--primaryLight);
        border-radius: 50%;
        height: 48px;
        width: 48px;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      
      .bubbleCoupleInitials > p {
        color: $main-strong-accent;
        justify-self: center;
        align-self: center;
      
        font-style: normal;
        font-weight: normal;
        font-size: 21.328px;
        line-height: 24px;
        letter-spacing: -2.84px;
      }
    }

  }
}

.pointerClick {
  cursor: pointer;
}


.middle {
  display: flex;
  justify-content: center;
  align-items: center;
}


.routes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 72px;
    height: 88px;
    border-radius: 100px;
    cursor: pointer;
    padding: 24px 0; 
  }
  
  div:hover {
    font-weight: normal;
    color: $secondary-operational-accent;
  
    a {
      color: $secondary-operational-accent;
    }
  }
  div > a:hover {
    color: $secondary-operational-accent;
  }
  
  div > a {
    text-decoration: none;
    align-self: center;
  }
  
  &Mobile { 
    display: flex; 
    justify-content: center;
    align-items: center; 
    flex-direction: row !important;
    gap: 8px;
    width: 100svw;
    height: 64px;
    justify-content: space-evenly;
    align-items: center;
    .active {
      height: 44px;
      display: flex;
      padding: 14px;
      border-radius: 20px;
    }
    a {
      display: none;
    }
  }

  &Tablet { 
    display: flex; 
    justify-content: center;
    align-items: center; 
    flex-direction: row !important;
    gap: 8px;
    height: 80px;
    width: 100svw;
    justify-content: space-evenly;
    align-items: center;
    .active {
      height: 44px;
      display: flex;
      padding: 14px;
      border-radius: 20px;
    }
    a {
      display: none;
    }
    .reminder {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 24px;
      gap: 16px;
    
      position: absolute;
      max-width: 50svw;
      height: 144px;
      left: 20px;
      bottom: 100px;
      background: #fae28f;
      border-radius: 16px;
      z-index: 10;
    }
  }

  &Laptop { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
       
      border-radius: 100px;
      cursor: pointer;
      padding: 24px 0;
      width: 48px;
      height: 48px;

      > a {
        display: none;
      }
    }
  }
}


.popupOpen {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
}

.active {
  background-color: $brand-operational-accent;
  animation: activeAnimation 0.5s;
  height: 88px;
  -webkit-transition: background-color 0.2s ease-in;
  transition: background-color 0.2s ease-in;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}





 

.hidden {
  display: none;
}

.tabs-list__active {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #dacff4;
  border-radius: 30px;
  transition: background-color 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.weddingPathCode {
  font-family: "Fira Mono", "Andale Mono", "Consolas", monospace;
  letter-spacing: 0px;
  line-height: 24px;
  font-size: 16px;
  //font-feature-settings: none;
  font-variant-ligatures: none;
  position: relative;
  border-style: solid;
  /* border: 1px; */
  border-color: #c1c6a8;
  border-width: 1px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  background-color: #c1c6a829;
}

.reminder {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;

  position: absolute;
  max-width: 514px;
  height: 144px;
  left: 184px;
  bottom: 40px;
  background: #fae28f;
  border-radius: 16px;
  z-index: 10;
}
