@font-face {
  font-family: "Yiggivoo";
  src: url("../../../fonts/Yiggivoo-UC.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Cyntho";
  src: url("../../../fonts/Cyntho-Regular.otf") format("opentype");
}


.Baptism2QNAComponent { 
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 91px 32px;
  gap: 40px; 

}
.panel > * > * {
  font-family: Cyntho !important;
font-size: 18px;
font-weight: 300;
line-height: 24px;
letter-spacing: -0.6299999952316284px;
text-align: left;
color: black;

}
.Baptism2QNAComponent > h2 {
  width: 100%;
  max-width: 880px;
  padding: 0px; 
  font-family: Yiggivoo !important;
  font-size: 38px;
  font-weight: 400;
  line-height: 130px;
  letter-spacing: 0.01em;
  text-align: left;
  
}

.Baptism2QNAComponent > div {
  width: 100%;
  max-width: 880px;
}

.Baptism2QNAComponent > div > button {
  border: none;
  background: transparent;
  justify-self: flex-start;
  align-self: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 200px;
  color: #000000 !important;
  font-family: Cyntho !important;
  font-size: 21.33px !important;
  text-align: start;
}
 
.Baptism2QNAComponent > div > div {
  width: 100%;
}

.Baptism2QNAComponent > div > div > p {
  padding-top: 24px;
  width: 100%;
  color: #000000 !important;
  font-size: 16px !important;
  font-family: Cyntho !important;
  text-align: start;
}

@media only screen and (max-width: 660px) {
  .Baptism2QNAComponent {
    padding-top: 44px !important;
    background: #fdf6eb;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 91px 32px;
  }
   
}
