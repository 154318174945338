@import "../../styles/colors";

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(131, 89, 120, 0.8);
  z-index: 100;
  backdrop-filter: blur(10px);

  .modalBox {
    position: relative;
    top: calc(50% - 172px);
    left: calc(50% - 256px);
    width: 512px;
    max-height: 344px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: $white;
    padding: 40px;
    border: 1px solid $secondary-strong-accent;
    border-radius: 8px;

    .btns {
      display: flex;
      gap: 48px;
      button {
        width: fit-content;
        padding: 12px 32px;
      }
    }

    @media screen and (max-width: 520px) {
      width: 320px;
      left: calc(50% - 160px);
      .btns {
        gap: 16px;
        button {
          font-size: 16px;
          padding: 6px 16px;
        }
      }
    }
  }
}
