@import "../../styles/colors";

.Main {
  background: #FBF9F8;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 82px 0;
  margin-left: calc(50% - 400px);
  margin-right: calc(50% - 400px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}
.Main > p {
  color : black;

}

.imgWrapper {
  text-align: center;
  > img {
    width: 50%;
    height: auto;
    max-height: 1200px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}

.eventsWrapper {
  h3, .events {
    margin: auto;
    width: 90vw;
    max-width: 600px;
  }
  .events{
    display: flex;
    gap: 16px;
    padding: 8px 0;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      border-radius: 20px;
      padding: 0 40px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $brand-hover-accent;
      outline: 1px solid $brand-hover-accent;
      border-radius: 20px;
      width: 1px;
      height: 1px;
    }

    .event {
      display: flex;
      min-width: 221px;
      flex-direction: column;
      gap: 8px;
      padding: 16px;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(40, 27, 36, 0.16);
      border-radius: 8px;
      cursor: pointer;

      div {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

.websiteWrapper {
  width: 100%;
  margin-top: 16px;
  .websiteButton {
    margin: auto;
    width: 100%;
    max-width: 330px;
    height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    border: none;

    border-radius: 8px;
    color: $brand-operational-accent;
    cursor: pointer;

    h4 {
      text-align: center;
      margin: auto;
      position: relative;
    }
  }
  .blur {
    position: absolute;
    background: rgba(255, 255, 255, 0.6);
    height: 250px;
    margin: auto;
    width: 100%;
    max-width: 330px;
    border: 1px solid $brand-operational-accent;
    border-radius: 8px;
  }
}
