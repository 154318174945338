@import "../../../styles/colors";

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.TemplatePreviewExpanded {
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  overflow-y: scroll;
}
.TemplatePreviewExpanded > div {
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow-y: scroll;
  border-radius: 0;
}

.TemplatePreviewExpanded > div ::-webkit-scrollbar {
  height: 12px;
}

.TemplatePreviewExpanded > div ::-webkit-scrollbar-thumb,
.TemplatePreviewExpanded > div ::-webkit-scrollbar-track {
  border-radius: 92px;
}

.TemplatePreviewExpanded > div ::-webkit-scrollbar-thumb {
  background: var(--accent);
}

.TemplatePreviewExpanded > div ::-webkit-scrollbar-track {
  background: #ffb2862b;
}

.TemplatePreview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.Selected {
  background: #f4f3f4;
  border: 1px solid #63435b !important;
  border-radius: 8px;
}

.TemplatePreview > div:first-child > button {
  cursor: pointer;
  border-radius: 8px;
  padding: 12px;
  margin: 16px;
  border: none;
}
.TemplatePreview > p {
  margin: 0px 0px 16px 0px;
}

.TemplatePreview > div:last-child {
  background: #ffffff !important;
  padding: 8px;
}

.TemplatePreview > div:last-child > div > div {
  width: 100%;
  border-radius: 0px 0px 16px 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.TemplatePreview > div:last-child > div > div::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.TemplatePreview > div:last-child > div > div::-webkit-scrollbar-thumb,
.TemplatePreview > div:last-child > div > div::-webkit-scrollbar-track {
  border-radius: 20px;
}

.TemplatePreview > div:last-child > div > div::-webkit-scrollbar-thumb {
  outline: 1px solid $brand-hover-accent;
  background-color: $brand-hover-accent;
}

.TemplatePreview > div:last-child > div > div::-webkit-scrollbar-track {
  //background: #ffb2862b;
}

.TemplatePreview > div:last-child > div > svg {
  margin-left: 16px;
  margin-bottom: 2px;
}

.TemplatePreview > div:last-child > div {
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}
.TemplatePreview > div:last-child > div > div:first-child {
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  background: #e5e5e5;
}

.TemplatePreview > div:last-child > div > div:last-child {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.desktopPreview {
  background: transparent;
  width: 80%;
  height: 600px;
  max-height: 600px;
  min-width: 752px;
  max-width: 1024px;
  box-shadow: 0px 10px 20px rgba(40, 27, 36, 0.1);
  border-radius: 24px;
  animation: fade-in ease-in-out;
  animation-duration: 0.5s;
}
.desktopPreview > div {
  border-radius: 16px !important;
  height: 100%;
}

.desktopPreview > div > div:first-child > svg {
  margin: 8px 0px 40px 24px;
}

//MOBILE PREVIEW
.mobilePreview {
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(40, 27, 36, 0.1);
  border-radius: 24px;
  max-width: 344px;
  min-width: 344px;
  height: 564px;
  animation: fade-in ease-in-out;
  animation-duration: 0.5s;
}
.mobilePreview > div {
  border-radius: 16px !important;
  height: 100%;
}

.mobilePreview > div > div:first-child {
  display: none;
}
.mobilePreview > div > div {
  height: 100%;
  border-radius: 16px !important;
}

//INVITATION PREVIEW
.invitationPreview {
  width: 30%;
  max-width: 336px;
  min-width: 336px;
  background: #dad8d9;
  box-shadow: 0px 10px 20px rgba(40, 27, 36, 0.1);
  padding: 0 !important;
  height: 488px;
  animation-name: fade-in;
  animation-duration: 1s;
}
.invitationPreview > div {
  height: 100%;
  border-radius: 0;
}
.invitationPreview > div > div {
  height: 100%;
  padding: 0;
  border-radius: 0 !important;
}
.invitationPreview > div > div:first-child {
  display: none;
}
