@import "../../../../styles/colors";

@mixin flex() {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

@mixin flexColumn() {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

@keyframes showModal {
  0% {
    transform: translate(492px, 0);
    opacity: 0.7;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 32px 0 0 32px;
  background: $white;
  box-shadow: -4px 4px 18px 0px #00000040;
  width: 528px;
  height: 100vh;
  z-index: 100;
  padding: 22px 40px 32px 40px;
  animation-name: showModal;
  animation-duration: 0.5s;
  .informationWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: calc(100vh - 104px);
    overflow-y: scroll;
    padding-right: 20px;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      outline: 1px solid $brand-hover-accent;
      background-color: $brand-hover-accent;
    }
  }

  .hr {
    margin: 0 !important;
    border-top: 1px solid $main-operational-accent;
    background: none !important;
  }
  &Mobile {
    width: 100vw !important;
    max-width: 100vw !important;
    height: auto;
    border-radius: 0;
    animation-name: showModal;
    animation-duration: 0.5s;
  }
}

.show {
  display: none;
}

.flex {
  @include flex;
}

.flexColumn {
  @include flexColumn;
}

.cancelBtn {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  background: none;
  padding: 8px 16px;
  color: $secondary-strong-accent;
  border: 1px solid $secondary-strong-accent;
  border-radius: 33px;
}

.guestInformation {
  @include flexColumn;
  padding: 16px;
  gap: 16px;
  border-radius: 16px;
  background: $brand-background-accent;

  > input[type="checkbox"] {
    border: 1px solid #F9AC80;
  }
}

.checkboxWrapper {
  @include flex;
  justify-content: left;
}

.guestEvents {
  @include flexColumn;
  padding: 16px;
  gap: 16px;
  border-radius: 16px;
  background: $main-background-accent;
}

.rsvpEvents {
  display: flex;
  flex-wrap: wrap;

  .item {
    flex-basis: 50%;
    box-sizing: border-box;
    margin-bottom: 16px;
  }
}

.errorText {
  color: $error-state;
  margin-bottom: 8px;
}

.smallText {
  color: $main-operational-accent;
  font-size: 12px;
  margin-top: -16px;
}

.tags {
  @include flex;
  flex-wrap: wrap;
}

.searchBar {
  height: 56px;
  display: flex;
  //justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 724px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.63px;
  flex-direction: row;
  padding: 16px;
  gap: 8px;
  border: 1px solid #dad8d9;
  border-radius: 8px;

  &Active {
    border: 1px solid $brand-operational-accent;
  }

  > input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 728px;
    border: 0;
    font-size: 16px;
    background-color: transparent;
  }
}

.searchResults {
  position: absolute;
  width: 391px;
  background-color: transparent;
  max-height: 100px;
  background-color: $white;
  border: 1px solid $secondary-strong-accent;
  padding: 8px;
  overflow-y: scroll;
  z-index: 1;
  border: 1px solid $accent;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }

  &Button {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 8px;
    background: none;
    border: none;
    border-radius: 4px;
    width: 100%;

    &:hover {
      background-color: $brand-operational-accent;
    }
  }
}
