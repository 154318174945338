.scheduleComponent {
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  padding: 91px 16px;

  @media only screen and (max-width: 525px) {
    flex-direction: row;
  }

  // TO DO: Add media queries for tablet and mobile

  &_Title {
    color: #000;
    font-family: Savoye;
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &_Wrapper {
    display: flex;
    gap: 70px;

    @media only screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }
  
  &_TitleImgWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &_Graphics {
    max-width: 600px;

    @media only screen and (max-width: 525px) {
      max-width: 360px;
    }
  }

  &_Schedule {
    display: flex;
    flex-direction: column;
    gap: 30px;

    > div {
      > h2 {
        font-family: Savoye;
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      > div {
        font-family: Cera;
        font-size: 30px;
        margin-bottom: 20px;
      }
    }

  }
}

.eventTitle, .eventLocatioNTime {
  color: #000;
  font-family: Cera;
  font-style: normal;
  font-weight: 400;
}

.eventTitle {
  font-size: 24px;
  line-height: 32px; /* 133.333% */
  text-transform: uppercase;
}

.eventLocatioNTime {
  font-size: 12px;
  line-height: 24px; /* 200% */
}
