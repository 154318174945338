@import "styles/colors";
@import "styles/typography";
@import "styles/components";
 

@font-face {
  font-family: 'Friday-Regular';
  src: url('./fonts/Friday-Regular.ttf') format("opentype");
  font-display: swap;
}

@font-face {
  font-family: 'DidactGothic-Regular';
  src: url('./fonts/DidactGothic-Regular.ttf') format("opentype");
}

* {
  font-family: 'DidactGothic-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  color: $main-strong-accent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.MuiList-root {


  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }
}

@media screen and (max-width: 450px) {
  h1 {
    font-size: 32px;
    margin-bottom: 32px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 16px;
  }
}

button {
  cursor: pointer;
}

/* MENTIONS AND HASTAGS ========================================= */
.mentions {

}

.mentions textarea {
  color: #626262;
  border: none;
  overflow: auto hidden;
  resize: none !important;
  width: 100%;
  margin: 16px 0px;
  font-size: 16px;
}




.editComment > .mentions__suggestions > ul {
  display: flex;
  flex-direction: column-reverse;
}

.editComment > .mentions__suggestions {
  left: 67px !important;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
}

.mentions--singleLine .mentions__higlighter {
  padding: 0px;
  /*border: 2px inset transparent;*/
}

.mentions__input {
  margin: 0 !important;
}

.mentions__suggestions{
  border: 0.5px solid rgb(229, 229, 229) ;
  border-radius: 8px;
  left: 80px !important;
}



.mentions--singleLine .mentions__input {
  padding: 5px;
  color: #626262;
  border: none;
  overflow: auto hidden;
  resize: none !important;
  width: 100%;
  margin: 16px 0;
  font-size: 16px;
}

.mentions--multiLine .mentions__control {
  /*font-family: monospace;*/
  /*font-size: 11pt;*/
  /*border: 1px solid silver;*/
}

.mentions--multiLine .mentions__highlighter {
  padding: 0;
  border: none !important;
}

.mentions--multiLine .mentions__highlighter > strong {
  color:   $brand-strong-accent !important;
  position: relative;
  background-color: transparent !important;
}

.mentions--multiLine .mentions__input {
  padding: 16px;
  outline: 0;
  color: #000000;
  border: none;
  overflow: auto hidden;
  resize: none !important;
  width: 100%;
  margin: 16px 0;
  font-size: 16px;
}

.mentions__suggestions__list {
  background-color: white;
  border-radius: 0 0 8px 8px;
  /*font-size: 10pt;*/
  top:0;
  left: 0;
  margin-top: 300px;
  overflow-y: scroll;
}

.fixed {
  position: fixed;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  padding: 16px;
}

.mentions__suggestions__item:first-child {
  border-radius: 8px 8px 0 0;
}

.mentions__suggestions__item:last-child {
  border-radius: 0 0 8px 8px;
}

.mentions__suggestions__item--focused {
  background-color: $brand-operational-accent;
}

.mentions__mention {
  background-color: #c31d4f;
}

@media screen and (max-width: 450px) {
  .mentions--multiLine .mentions__input {
    padding: 16px;
  }
}

.mentionLink, .hashtagLink {
  text-decoration: none;
}

.mentionLink span {
  color: $brand-strong-accent;
}

.hashtagLink {
  color: $brand-strong-accent;
}
