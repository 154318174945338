$color1-boy: #4B4965;
$color2-boy: #D9D9D9;
$color3-boy: #F4F3F4;
$color4-boy: #E09E41;
$color5-boy: #686B8A;
$color6-boy: #393946;

$color1-girl: #CC7974;
$color2-girl: #F9DCD8;
$color3-girl: #FFFBF4;
$color4-girl: #F0BC69;
$color5-girl: #84A6B1;
$color6-girl: #3C4545;

$color1-neutral: #755742;
$color2-neutral: #E3D2BA;
$color3-neutral: #FFFEF6;
$color4-neutral: #E09E41;
$color5-neutral: #8A9172;
$color6-neutral: #1C3924;

@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/components";
@import "../../styles/spacing";
.dragNDrop1 .dropzone1 {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 72px;
  padding: 16px 24px;
  border: 1px solid $main-operational-accent;
  box-sizing: border-box;
  border-radius: 8px;
  color: $main-strong-accent;
  margin-bottom: 24px;
  margin-top: 4px;
  cursor: pointer;
}

.dragNDrop .dropzone {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 346px;
  height: 72px;
  padding: 16px 24px;
  border: 1px solid $main-operational-accent;
  box-sizing: border-box;
  border-radius: 8px;
  color: $main-strong-accent;
  margin-bottom: 24px;
  margin-top: 4px;
  cursor: pointer;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Gifts {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  &Detail {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

//=============
//  PALETTES
//=============

// COLOR BOY
.color1Boy {
  color: $color1-boy !important;
}
.color2Boy {
  color: $color2-boy !important;
}
.color3Boy {
  color: $color3-boy !important;
}
.color4Boy {
  color: $color4-boy !important;
}
.color5Boy {
  color: $color5-boy !important;
}
.color6Boy {
  color: $color6-boy !important;
}

.color1Neutral {
  color: $color1-neutral !important;
}
.color2Neutral {
  color: $color2-neutral;
}
.color3Neutral {
  color: $color3-neutral !important;
}
.color4Neutral {
  color: $color4-neutral !important;
}
.color5Neutral {
  color: $color5-neutral !important;
}
.color6Neutral {
  color: $color6-neutral !important;
}

.color1Girl {
  color: $color1-girl !important;
}
.color2Girl {
  color: $color2-girl !important;
}
.color3Girl {
  color: $color3-girl !important;
}
.color4Girl {
  color: $color4-girl !important;
}
.color5Girl {
  color: $color5-girl !important;
}
.color6Girl {
  color: $color6-girl !important;
}


//BACKGROUNDS

.bg1Boy {
  background: $color1-boy !important;
}
.bg2Boy {
  background: $color2-boy !important;
}
.bg3Boy {
  background: $color3-boy !important;
}
.bg4Boy {
  background: $color4-boy !important;
}
.bg5Boy {
  background: $color5-boy !important;
}
.bg6Boy {
  background: $color6-boy !important;
}

.bg1Neutral {
  background: $color1-neutral !important;
}
.bg2Neutral {
  background: $color2-neutral !important;
}
.bg3Neutral {
  background: $color3-neutral !important;
}
.bg4Neutral {
  background: $color4-neutral !important;
}
.bg5Neutral {
  background: $color5-neutral !important;
}
.bg6Neutral {
  background: $color6-neutral !important;
}

.bg1Girl {
  background: $color1-girl !important;
}
.bg2Girl {
  background: $color2-girl !important;
}
.bg3Girl {
  background: $color3-girl !important;
}
.bg4Girl {
  background: $color4-girl !important;
}
.bg5Girl {
  background: $color5-girl !important;
}
.bg6Girl {
  background: $color6-girl !important;
}