@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/spacing";

.planningWrapper {
  display: flex;
  gap: 32px;
  flex: 0 0 100%;
  justify-content: space-between;
}

.main {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.main::-webkit-scrollbar {
  display: none;
}

.main > header {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 40px;
  flex: 0 0 55%;
}

.main > header > h1 {
  margin: 0;
}

.main > header > img {
  width: 32px;
}

.left {
  margin: 32px 0;
  display: flex;
  flex-direction: column;
}

.left > header {
  margin-bottom: 32px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.stepHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  > img {
    fill: $secondary-strong-accent;
    padding-right: 0;
  }
  > h3 {
    padding-left: 0;
    > span {
      color: $secondary-strong-accent;
      padding-right: 16px;
    }
  }
}

.stepHeader h4 {
  margin: 0;
}

.nextStepButton {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 15;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .timeDiv {
    flex-direction: row;
    align-items: center;
    gap: 42px;

    .timePickerDiv {
      max-width: 116px;
      background: #FFFFFF;
      border: 1px solid #A9A4A7;
      border-radius: 8px;
    }
  }

  > div {
    text-align: left;
  }

  textarea,
  .dragNDrop .dropzone {
    display: flex;
    gap: 16px;
    min-width: 350px;
    padding: 24px;
    border: 1px solid $main-operational-accent;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 8px;
    color: $main-strong-accent;
  }

  h4 {
    margin-bottom: 8px;
  }

  textarea {
    overflow: auto;
    resize: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.dragNDrop {
  width: 472px;
  cursor: copy;

  > .dropzone:active {
    border: dotted 1px #00c9a5;
  }

  .highlighted {
    color: $secondary-hover-accent;
  }
}

.nextBtn {
  width: fit-content;
  padding: 12px 32px;
  margin-right: 16px;
}

.preview {
  background-color: $main-background-accent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 100vh;
  &App {
    background-color: $white;
    justify-content: start;
    padding: 82px 0;
  }
  > .btns {
    width: 40px;
    height: 40px;
  }
}

.anotherEvent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 56px;
  gap: 8px;
  cursor: pointer;
  width: 214px;
  border: 1px solid $secondary-strong-accent;
  border-radius: 32px;
  padding: 8px 16px 8px 16px;
  margin-bottom: 16px;
  p {
    font-size: 16px;
    color: $secondary-strong-accent !important;
  }
}

.btns {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}

.imgBorder {
  background-color: #ffffff;
  border-radius: 24px;
  padding: 12px 12px;
  width: 372px;
  height: 624px;

  &Full {
    width: 656px;
    height: 428px;
    > div {
      display: flex;
      flex-direction: row;
      > div {
        width: 50% !important;
      }
    }
  }
  &App {
    width: 100%;
    height: 100%;
    max-width: 656px;
    > div {
      border-radius: 0 !important;
      > div {
        border-radius: 0 !important;
      }
    }
  }
}

.imgBorder > div {
  height: 100%;
  border-radius: 16px;
  background-color: $brand-background-accent;
  > img {
    border-radius: 16px;
  }
}

.preview {
  .image {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #281b24;
    gap: 8px;
    width: 100%;
    height: 100%;
    padding: 12px 0;
    border-radius: 32px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  &Wrapper {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.preview .image > .center {
  margin-top: 45%;
  text-align: center;
}

.highlighted {
  color: $secondary-operational-accent;
}

.addEventBtn {
  display: flex;
  gap: 8px;
  align-items: center;
  text-align: center;
  color: $secondary-strong-accent;
  border-radius: 30px;
  background: $brand-operational-accent;
  width: fit-content;
  padding: 8px 16px 8px 16px;
  border: 0;
}

.scroll {
  padding: 0 24px;
  ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &Full {
    overflow-y: scroll;
    height: 100%;
  }
}
