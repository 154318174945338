@import "../../../styles/colors";

.main{
  background: $brand-background-accent;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main > div {

}
.wrapper{
  //margin-top: 40px;
  margin: auto;
  padding: 56px;
  background: white;
  height:fit-content;
  border-radius: 16px;
  z-index: 5;
  width: 543px;
  height: 543px;

  @media screen and (max-width: 600px) {
    width: 90vw;
  }
}

.graphics > img:first-child{
  z-index: 4;
  position: fixed;
  left: calc(50vw - 390px);
  top: calc(25vh);
}
.graphics > img:last-child{
  z-index: 4;
  position: fixed;
  left: calc(50vw + 245px);
  top: calc(52vh);
}



.header, .form {
  display: flex;
  flex-direction: column;
  //text-align: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  > div {
    display: flex;
    flex-direction: column;
  }

  .success {
    color: #281B24
  }

  .error {
    color: $error-state;
  }

  p {
    color: $secondary-strong-accent;
  }

  input{
    width: 431px;
    @media screen and (max-width: 600px) {
      width: 58vw;
    }
  }

  p {
    margin-bottom: 32px;
  }
  button {
    margin-bottom: 16px;
  }
}

.form {
  align-items: center;
}

.link {
  color: #2C5252;
}

.graphics > img:first-child{
  z-index: 4;
  position: fixed;
  left: calc(50vw - 390px);
  top: calc(25vh);
}
.graphics > img:last-child{
  z-index: 4;
  position: fixed;
  left: calc(50vw + 245px);
  top: calc(52vh);
}
