@import "../../../styles/colors";
@import "../../../styles/typography";

a {
    color: $secondary-strong-accent;
    text-decoration: none;
    text-underline-offset: 4px;
}

a:hover{
    color: $secondary-hover-accent;
}



.signIn {
    padding: 10% 72px;
    min-height: 100vh;
    max-width: 600px;
    margin: 0 auto;
}
.signIn-logo {
    width: 100%;
    text-align: center;
    margin-bottom: 72px;
}
.signIn-logo > img {
    width: 100%;
    max-width: 320px;
}
.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.form > input {
    padding: 16px;
    border: 1px solid #DAD8D9;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;

    &:active {
        border: 1px solid #63435B !important;
    }
}
.form-control {
    padding: 16px !important;
    border: 1px solid #DAD8D9 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    width: 100% !important;
    height: inherit !important;
    padding-left: 48px !important;
    font-size: 16px !important;
    line-height: 24px !important;

    &:active {
        border: 1px solid #63435B !important;
    }
}


.signInBtn{
    width: 100% !important;
    height: 56px !important;
    border-radius: 8px !important;
    font-size: 21.33px !important;
}

.signInBtn:disabled{
    cursor: not-allowed;
}

.react-tel-input {
    margin-bottom: 24px !important;
}
.react-tel-input .flag-dropdown {
    padding: 0 !important;
    background-color: #f5f5f5 !important;
    border: none !important;
}
.react-tel-input .selected-flag {
    outline: none;
    position: relative;
    left: 16px;
    width: 0px !important;
    height: 100%;
    padding: 0 !important;
    border: none;
}
input[type="checkbox" i] {
    border-radius: 4px;
    cursor: pointer;
}
.form > label > input {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    vertical-align: middle;
}
.form > p {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.47px;
    margin-bottom: 16px;
}
.form > label {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.47px;
    margin-bottom: 16px;
}
.form > a {
    text-align: center;
    background: #3F3D56;
    color: white;
    border-radius: 12px;
    font-size: 24px;
    padding: 20px;
    margin: 24px 0;
    cursor: pointer;
    text-decoration: none;
}
.footerImg {
    position: fixed;
    margin: 0;
    left: 0;
    bottom: 0;
    z-index: -1 !important;
    display: flex;
}

@media screen and (max-width: 1024px) {
    .signIn {
        padding: 20% 72px;
    }
}

@media screen and (max-width:450px) {
    .signIn {
        padding: 30% 32px;
    }
    .signIn-logo {
        margin-bottom: 72px;
    }
    .signIn-logo > img {
        width: 232px;
    }

    .form > input {
        margin-bottom: 8px;
    }
    .form > a {
        font-size: 20px;
        padding: 16px;
        margin: 16px 0;
    }
    .react-tel-input {
        margin-bottom: 24px !important;
    }
}
/* Media After Tablet ============================================ */
@media screen and (min-width:1101px) {
    .signIn-logo {
        margin-bottom: 72px;
    }
    .signIn-logo > img {
        max-width: 320px;
    }
    .footerImg img {
        max-width: 400px;
    }
}
