//DESKTOP
.ItsAllThanksToComponent {
  width: 100%;
  padding: 91px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

// TITLE
.ItsAllThanksToComponent div > h2 {
  font-family: Syne !important;
  color: #333;
  font-size: 50px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -0.63px;
  align-self: flex-start;
}

@media only screen and (max-width: 1200px) {
  .ItsAllThanksToComponent {
    padding: 91px;
  }
}

@media only screen and (max-width: 768px) {
  .ItsAllThanksToComponent {
    padding: 91px 16px;
  }
}

.ImageContentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  & > img {
    max-width: 20%;
  }
}

.ItsAllThanksToComponent > div:last-child {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center; 
}

//LAYOUT 2_images

.layout_2_images {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.layout_2_images > img {
  max-width: 581px !important;
  max-height: 744px !important;
  width: 40% !important;
  height: 300px !important;
  object-fit: cover;
  padding: 0;
  background-color: black;
}

//MOBILE
@media only screen and (max-width: 768px) {
  .layout_2_images > img {
    width: 100% !important;
    height: auto;
  }
}

//SMALL MOBILE
@media only screen and (max-width: 526px) {
  .layout_2_images > img {
    width: 100% !important;
    height: auto;
  }
}

//-----------------------
// LAYOUT 2_cols_3_images
//-----------------------

//DESKTOP
.layout_2_cols_3_images {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
}

.layout_2_cols_3_images > img {
  max-width: 581px !important;
  max-height: 791px !important;
  width: 33% !important;
  height: calc(25vh + 32px) !important;
  object-fit: cover;
  padding: 0;
  
  background-color: black;
}
.layout_2_cols_3_images > div {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  max-height: 791px !important;
}
.layout_2_cols_3_images > div > img {
  max-width: 581px !important;
  object-fit: cover;
  width: 100%;
  height: calc(30vh) !important;
  padding: 0px;
  margin: 0px;
}

//MOBILE
@media only screen and (max-width: 768px) {
  .layout_2_cols_3_images {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
  }
  .layout_2_cols_3_images > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50%;
  }
  .layout_2_cols_3_images > img {
    width: 33% !important;
    height: 25vh;
  }

  .layout_2_cols_3_images > div > img {
    width: 100% !important;
    height: auto;
  }
}

//LAYOUT 2_cols_4_images

.layout_2_cols_4_images {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
}
.layout_2_cols_4_images > img {
  max-width: 581px !important;
  width: 25% !important;
  height: calc(25vh + 16px) !important;
  object-fit: cover;
  padding: 0;
  background-color: black;
}
.layout_2_cols_4_images > div {
  width: 581px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}
.layout_2_cols_4_images > div > img {
  max-width: 581px !important;
  max-height: 744px !important;
  object-fit: cover;
  width: 100%;
  height: 30vh !important;
  padding: 0;
}
.layout_2_cols_4_images > div > div {
  max-width: 581px !important;
  width: calc(100% - 16px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}
.layout_2_cols_4_images > div > div > img {
  max-width: 581px !important;
  height: 100%;
  object-fit: cover;
  width: 50%;
}

@media only screen and (max-width: 880px) {
  .layout_2_cols_4_images {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .layout_2_cols_4_images > img {
    width: 100% !important;
    height: auto;
  }

  .layout_2_cols_4_images > div {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  .layout_2_cols_4_images > div > img {
    width: 100% !important;
    height: auto;
    min-height: 229px !important;
  }
  .layout_2_cols_4_images > div > div {
    flex-wrap: nowrap;
    width: calc(100% - 16px) !important;
    padding: 0;
    margin: 0;
  }
  .layout_2_cols_4_images > div > div > img {
    width: calc(50%) !important;
    height: auto;
    max-height: 229px !important;
  }
}

//--------
//FIELDS
//--------

//DESKTOP
.fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  > div {
    display: flex;
    gap: 40px;
  }

  > div > div > h5 {
    font-family: Syne;
    font-size: 28.43px;
    font-weight: 400 !important;
    line-height: 40px;
    letter-spacing: -0.853px;
    margin-bottom: 10px;
  }

  > div > div > p {
    color: #333;
    font-family: CenturyGothic;
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: -0.54px;
  }

  > img {
    width: 100%;
    max-width: 600px;

    @media only screen and (max-width: 420px) {
      width: auto;
      margin-left: -70%;
      overflow: hidden;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    gap: 0;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
}