.finish {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20vw;
    //height: calc(100vh - 72px - 110px);
}
.finishProfile {
    display: flex;
    margin-bottom: 32px;
    align-items: center;
    grid-gap: 2vw;
}
.finishProfile p {
    font-size: 24px;
}
.finishProfile button {
    white-space: normal;
    font-size: 24px;
    padding: 12px 24px;
    appearance: none;
    background: none;
    border: none;
    align-items: center;
    display: flex;
    cursor: pointer;
}
.addImage {
    position: relative;
    width: 112px;
    height: 112px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.addImage > img {
    width: 76px;
    height: 76px;
}
.addImage > .addedImg {
    opacity: 0;
}
.buttonAddImg {
    width: 16px;
    margin-right: 8px;
    margin-bottom: 2px;
}
.finishBtn {
    width: 100%;
    text-align: center;
    background: #3F3D56;
    color: white;
    border-radius: 12px;
    font-size: 24px;
    padding: 20px;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    border: none;
}
.error {
    border: 1px solid red;
}

@media screen and (max-width: 450px){
    .finish {
        padding: 0 0px;
    }
    .finishProfile button {
        font-size: 20px;
        padding: 12px 24px;
    }
    .addImage {
        width: 72px;
        height: 72px;
    }
    .addImage > img {
        width: 48px;
        height: 48px;
    }
    .finishProfile {
        margin-bottom: 24px;
        grid-gap: 16px;
    }
    .finishBtn {
        font-size: 20px;
        padding: 16px;
    }
}
@media screen and (max-width:405px){
    .finish {
        padding: 0 32px;
    }
}
@media screen and (min-width:1101px) {
    .finish {
        padding: 4vw 0 0 0;
        max-width: 572px;
        height: calc(100vh - 4vw);
        margin: 0 auto;
    }
}

.addImageButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.addImageButton > p {
    font-size: 12px;
    padding-bottom: 12px;
}

.loadingFinishProfileImage{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}