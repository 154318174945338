@import "../TheArtist.module.scss";

.TheArtistQNAComponent {
  background: #E9E9E9;
  display: flex;
  justify-content: start;
  gap: 40px;
  align-items: center;
  width: 100%;
  padding: 91px 32px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;

  > img {
    align-self: flex-start;
  }

  @media only screen and (max-width: 640px) {
    flex-direction: column;
    padding: 91px 0px;
  }
}

.panel > div {
  color: #000;
  font-family: CenturyGothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 40px;
}

.panel > * > * {
  font-family: CenturyGothic !important;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.6299999952316284px;
  text-align: left;
  color: black;
}

.TheArtistQNAComponent > div {
  flex-grow: 1;
}

.TheArtistQNAComponent > div > h2 {
  color: #333 !important;
  width: 100%;
  max-width: 880px;
  padding: 0px; 
  font-family: Syne !important;
  font-size: 40px;
  font-weight: 400;
  line-height: 54px; /* 106.888% */
  letter-spacing: -0.63px;
  text-align: left;
  margin-bottom: 40px;
}

.TheArtistQNAComponent div > div {
  width: 100%;
  max-width: 880px;
}

.TheArtistQNAComponent > div > div > button {
  border: none;
  background: transparent;
  justify-self: flex-start;
  align-self: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 200px;
  color: #333 !important;
  font-family: CenturyGothic !important;
  font-size: 18px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.54px;
  text-align: start;
  margin-bottom: 35px;
}
 
.TheArtistQNAComponent > div > div > div {
  width: 100%;
}

.TheArtistQNAComponent > div > div > div > p {
  padding-top: 24px;
  width: 100%;
  color: #000000 !important;
  font-size: 16px !important;
  font-family: Cera !important;
  text-align: start;
}

