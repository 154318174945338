@import "../../../../styles/colors";

.wrapper {
  width: 25%;
  height: 70vh;
  padding: 88px 48px;
  border: 1px solid #DAD8D9;
  border-radius: 8px;
}

.header {
  display: flex;
  align-items: center;
  background: #F4F3F4;
  border-radius: 4px;
  padding: 0 8px 0 0;
  justify-content: space-between;
  > :first-child {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #835978;
    border-radius: 4px;
    padding: 8px;
    color: #ffffff;
  }
  > :last-child {
    color: #A9A4A7;
  }
}

.buttonsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  max-width: 800px;
  margin: 28px auto;
}

.iconButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 112px;
  width: 112px;
  border: 1px solid #835978;
  border-radius: 8px;
  padding: 16px;
  margin: 0 auto;
  background: none;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* subtle shadow on hover */
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.text {
  margin-top: 8px;
  text-align: center;
}