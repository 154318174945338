.SepiaQNAComponent {
  background: #fdf6eb;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 91px 32px;
  gap: 40px;
  color: #281b24 !important;
}
.SepiaQNAComponent > h2 {
  width: 100%;
  max-width: 880px;
  padding: 0px;
  color: #281b24 !important;
}

.SepiaQNAComponent > div {
  width: 100%;
  max-width: 880px;
}

.SepiaQNAComponent > div > button {
  border: none;
  background: transparent;
  justify-self: flex-start;
  align-self: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 200px;
  color: #281b24 !important;
  font-family: "DidactGothic-Regular";
  font-size: 21.33px !important;
  text-align: start;
}
.SepiaQNAComponent > div > div {
  width: 100%;
}

.SepiaQNAComponent > div > div > p {
  padding-top: 24px;
  width: 100%;
  color: #281b24 !important;
  font-size: 16px !important;
  font-family: "Friday-Regular" !important;
  text-align: start;
}

@media only screen and (max-width: 660px) {
  .SepiaQNAComponent {
    padding-top: 44px !important;
    background: #fdf6eb;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 91px 32px;
  }
}
