@import "../../../../styles/_colors";

.withBorder {
  border: 1px solid #a9a4a7 !important;
  border-radius: 8px;
}

.autocomplete {
  border: none !important;
  border-radius: 8px;
  color: #281b24;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
}

.autocomplete > input {
  color: #281b24;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
}

.autocomplete > * {
  border: none !important;
}

.autocomplete:focus > * {
  width: 332px;
  padding: 16px;
  border: 1px solid #a9a4a7 !important;
  border-radius: 8px;
  color: #281b24;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
}
.autocomplete:focus {
  width: 332px;
  padding: 16px;
  border: 1px solid #a9a4a7 !important;
  border-radius: 8px;
  color: #281b24;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
}

.CancelButton {
  display: flex !important;
  justify-content: center !important;
  border: none !important;
  border-radius: 8px !important;
  background: $error-state !important;
  color: $brand-background-accent !important;
}

.CancelButton:hover {
  background: $error-state !important;
  color: $brand-background-accent !important;
}
.AddButton {
  display: flex !important;
  justify-content: center !important;
  border: none !important;
  border-radius: 8px !important;
  background: $brand-strong-accent !important;
  color: $secondary-strong-accent !important;
}

.AddButton:hover {
  background-color: $brand-hover-accent !important;
  color: $secondary-strong-accent !important;
}
