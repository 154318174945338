.OtpInput > input {
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #63435b;
  width: 32px;
  height: 40px;
  display: flex;
  text-align: center;
}

.OtpInput {
  display: flex;
  justify-content: center;
  gap: 8px;
}
