@import "../../../styles/colors";

.weddingCardListWrapper {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  flex-wrap: wrap;
}
.weddingCardListWrapper > .weddingCard {
  margin-right: 20px;
  cursor: pointer;
}

 .loadingBox{
    height: fit-content;
    width: 100%;
    backdrop-filter: blur(0.5px);
    padding-bottom: 0px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: hidden;
    z-index: 100;
 }

// iPad devices horizontal alignment
@media only screen and (max-width: 1023px) {
  .weddingCardListWrapper {
    display: flex;
    justify-content: space-evenly;
    gap: 8px;
    flex-wrap: wrap;
  }
  .weddingCardListWrapper > .weddingCard {
    margin-right: 20px;
    cursor: pointer;
  }
}

// small screen laptops
@media only screen and (max-width: 820px) {
  .weddingCardListWrapper {
    padding: 0px;
    display: flex;
    justify-content: space-around;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 80px;
  }
  .weddingCardListWrapper > .weddingCard {
    cursor: pointer;
  }
}
