.Introduction{
  background : #63435B;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden !important;
}

.buttonIntro{
  border: none;
  background: #F9AC80;
  border-radius: 8px;
  width: 90%;
  padding-left: 10%;
  padding-right:10%;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  cursor: pointer;
  animation: fadein 2s;
}
.buttonIntro:hover{
  color:white;
}


.carousel{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}
.step{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 16px;
  padding-left: 16px;
}

.stepHidden{
  opacity: 60%;

}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.step > img {
  border: 16px solid #FDF6EB;;
  border-radius: 8px;
  background:  #FDF6EB;
  align-self: center;
  justify-self: center;
  width: 80%;
  height:40%;
  min-height:40vh;
  max-height:40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
.step > * {
  color : white;
}

.intro-description{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.63px;
  color: #FDF6EB;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-right: 10vw ;
  margin-left:10vw ;
}
.intro-title{
  font-family: 'fonde';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #FDF6EB;
}

.hide {
  display: none;
}
