@import "../../styles/colors";

.floatingInputContainer {
  position: relative;
  //max-width: 431px !important;
  padding: 16px;
  border: 1px solid $secondary-strong-accent;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 60px;
  display: flex;
  background: $white;
  &Disabled {
    border: 1px solid $main-operational-accent !important;
    * {
      color: $main-operational-accent !important;
    }
  }
  span {
    color: #FFFFFF;
  }
  &Focused {
    border-color: $brand-operational-accent;
    //label {
    //  color: $brand-operational-accent;
    //}
  }
  button {
    margin: 0;
  }
  &Error {
    border: 1px solid $error-state;
  }
}

.floatingInputEdit{
  display: flex;
  gap: 8px;
  > button {
    background: transparent;
    border: 0;

    &:disabled {
      border: 1px solid $main-operational-accent;
      cursor: default;
    }
  }
  > .bordered {
    border: 1px solid $main-strong-accent;
    border-radius: 4px;
    padding: 4px;
  }
  > .clicked {
    background-color: $secondary-strong-accent;
  }
}

.floatingPhoneInputEdit{
  display: flex;
  position: absolute;
  right: 16px;
  gap: 8px;
  > button {
    background: transparent;
    border: 0;

    &:disabled {
      border: 1px solid $main-operational-accent;
      cursor: default;
    }
  }
  > .bordered {
    border: 1px solid $main-strong-accent;
    border-radius: 4px;
    padding: 4px;
  }
  > .clicked {
    background-color: $secondary-strong-accent;
  }
}

.floatingInputPlaceholder {
  position: absolute;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  transform: translate(5px, 0%);
  font-size: 16px;
  &Focused {
    font-size: 12px;
  }
}

.floatingInputContainer input[type=text].fieldInput {
  border: none;
  font-size: 16px !important;
  font-family: 'Didact Gothic', sans-serif;
  display: block;
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
}

.floatingInputContainer input[type=text].fieldInput.c-fix, input[type=text].fieldInput:focus, input[type=text].fieldInput:not(:placeholder-shown) {
  background-color: transparent;
}

.floatingInputContainer input[type=text].fieldInput.c-fix ~ label, input[type=text].fieldInput:focus ~ label, input[type=text].fieldInput:not(:placeholder-shown) ~ label {
  font-size: 12px;
  top:-10px;
  background: $white;
  transform: translate(5px, 0%);
}

.floatingInputContainer input[type=text].fieldInput::-webkit-input-placeholder {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
}

.floatingInputContainer input[type=text].fieldInput::-moz-placeholder {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
}

.floatingInputContainer input[type=text].fieldInput:-ms-input-placeholder {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
}