@import '../../../../styles/colors.scss';

.modal {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;

  &Active {
    display: flex;
    transform: translate(-50%, -50%);
    animation: all 0.5s ease;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 512px;
    max-width: 100vw;
    max-height: 100vh;
    overflow-y: auto;
    background-color: $white;
    border: 1px solid $secondary-strong-accent;
    border-radius: 8px;
    padding: 38px;
    margin: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      outline: 1px solid $brand-hover-accent;
      background-color: $brand-hover-accent;
    }
  }

  &SearchResults {
    position: absolute;
    width: 434px;
    max-height: 100px;
    background-color: $white;
    border: 1px solid $secondary-strong-accent;
    padding: 8px;
    overflow-y: scroll;
    z-index: 1;
    border: 1px solid $accent;
    border-radius: 8px;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      outline: 1px solid $brand-hover-accent;
      background-color: $brand-hover-accent;
    }

    &Button {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      padding: 8px;
      background: none;
      border: none;
      border-radius: 4px;
      width: 100%;

      &:hover {
        background-color: $brand-operational-accent;
      }
    }
  }
}

.searchBar {
  height: 56px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 724px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.63px;
  flex-direction: row;
  padding: 16px;
  gap: 8px;
  border: 1px solid #dad8d9;
  border-radius: 8px;

  &Active {
    border: 1px solid $brand-operational-accent;
  }

  > input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 728px;
    border: 0;
    font-size: 16px;
  }
}

.selected {
  display: flex;
  gap: 16px;
  width: 432px;
  //height: 56px;
  overflow-y: hidden;
  overflow-x: scroll;
  margin: 8px 0;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }

  &Guest {
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: flex-start;
    align-items: center;
    color: $brand-strong-accent;

    > img,
    div {
      border: 2px solid $brand-strong-accent;
    }

    &Tick {
      margin-left: 24px;
      margin-top: -12px;
      border: 0 !important;
      position: sticky;
    }
  }
}

.event {
  display: flex;
  width: 100%;
  margin-top: 16px;
  padding: 16px 32px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid $accent;
  background: $brand-background-accent;
  text-align: left;
  &Selected {
    border: 1px solid #F78C50
  }
}

.smallText {
  color: $main-operational-accent;
  font-size: 12px;
}

.sendButton {
  width: 100% !important;
  margin: 0 !important;
  &:disabled {
    cursor: not-allowed;
  }

  svg {
    fill: #63435b !important;
  }

  &:hover:enabled {
    svg {
      fill: $brand-operational-accent !important;
    }
  }
}
