@font-face {
  font-family: "Catamount";
  src: url("../../../fonts/CatamountSignature.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Noto";
  src: url("../../../fonts/NotoSerifDisplay.ttf") format("opentype");
}


//DESKTOP
.ItsAllThanksToComponent {
  width: 100%;
  height: 100vh;
  padding: 91px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fdf6eb;
  gap: 40px;
}

// TITLE
.ItsAllThanksToComponent > h2 {
  font-family: Catamount !important;
  color: #FFF097;
  font-size: 128px;
  font-weight: 400;
  line-height: 130px;
  letter-spacing: 0.01em;
  text-align: left;
}

@media only screen and (max-width: 1200px) {
  .ItsAllThanksToComponent {
    padding: 91px;
  }
}

@media only screen and (max-width: 768px) {
  .ItsAllThanksToComponent {
    padding: 91px 16px;
  }
}

.ItsAllThanksToComponent > div:last-child {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center; 
}

//LAYOUT 2_images

.layout_2_images {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.layout_2_images > img {
  max-width: 581px !important;
  max-height: 744px !important;
  width: 40% !important;
  height: 300px !important;
  object-fit: cover;
  padding: 0;
  border: 1px solid black;
  background-color: black;
}

//MOBILE
@media only screen and (max-width: 768px) {
  .layout_2_images > img {
    width: 100% !important;
    height: auto;
  }
}

//SMALL MOBILE
@media only screen and (max-width: 526px) {
  .layout_2_images > img {
    width: 100% !important;
    height: auto;
  }
}

//-----------------------
// LAYOUT 2_cols_3_images
//-----------------------

//DESKTOP
.layout_2_cols_3_images {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
}

.layout_2_cols_3_images > img {
  max-width: 581px !important;
  max-height: 791px !important;
  width: 33% !important;
  height: calc(25vh + 32px) !important;
  border: 1px solid #1C2840;
  object-fit: cover;
  padding: 0;
  
  background-color: black;
}
.layout_2_cols_3_images > div {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  max-height: 791px !important;
}
.layout_2_cols_3_images > div > img {
  max-width: 581px !important;
  object-fit: cover;
  width: 100%;
  height: calc(30vh) !important;
  padding: 0px;
  margin: 0px;
  border: 1px solid black;
}

//MOBILE
@media only screen and (max-width: 768px) {
  .layout_2_cols_3_images {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
  }
  .layout_2_cols_3_images > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50%;
  }
  .layout_2_cols_3_images > img {
    width: 33% !important;
    height: 25vh;
  }

  .layout_2_cols_3_images > div > img {
    width: 100% !important;
    height: auto;
  }
}

//LAYOUT 2_cols_4_images

.layout_2_cols_4_images {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
}
.layout_2_cols_4_images > img {
  max-width: 581px !important;
  width: 25% !important;
  height: calc(25vh + 16px) !important;
  object-fit: cover;
  padding: 0;
  border: 1px solid #1C2840;
  background-color: black;
}
.layout_2_cols_4_images > div {
  width: 581px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}
.layout_2_cols_4_images > div > img {
  max-width: 581px !important;
  max-height: 744px !important;
  object-fit: cover;
  width: 100%;
  height: 30vh !important;
  padding: 0;
  border: 1px solid black;
}
.layout_2_cols_4_images > div > div {
  max-width: 581px !important;
  width: calc(100% - 16px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}
.layout_2_cols_4_images > div > div > img {
  max-width: 581px !important;
  height: 100%;
  object-fit: cover;
  width: 50%;
  border: 1px solid black;
}

@media only screen and (max-width: 880px) {
  .layout_2_cols_4_images {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .layout_2_cols_4_images > img {
    width: 100% !important;
    height: auto;
  }

  .layout_2_cols_4_images > div {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  .layout_2_cols_4_images > div > img {
    width: 100% !important;
    height: auto;
    min-height: 229px !important;
  }
  .layout_2_cols_4_images > div > div {
    flex-wrap: nowrap;
    width: calc(100% - 16px) !important;
    padding: 0;
    margin: 0;
  }
  .layout_2_cols_4_images > div > div > img {
    width: calc(50%) !important;
    height: auto;
    max-height: 229px !important;
  }
}

//--------
//FIELDS
//--------

//DESKTOP
.fields {
  text-align: center;
}

.fields > p:first-child {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #281b24;
 background: 'transparent' !important;
}
.fields > p:last-child {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #281b24;
}

.ItsAllThanksToComponent {
  > h2 {
    text-align: center;
  }
}
