@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/components";
@import "../../styles/spacing";
.dragNDrop1 .dropzone1 {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 72px;
  padding: 16px 24px;
  border: 1px solid $main-operational-accent;
  box-sizing: border-box;
  border-radius: 8px;
  color: $main-strong-accent;
  margin-bottom: 24px;
  margin-top: 4px;
  cursor: pointer;
}

.dragNDrop .dropzone {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 346px;
  height: 72px;
  padding: 16px 24px;
  border: 1px solid $main-operational-accent;
  box-sizing: border-box;
  border-radius: 8px;
  color: $main-strong-accent;
  margin-bottom: 24px;
  margin-top: 4px;
  cursor: pointer;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Gifts {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  &Detail {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
