.card {
  display: flex;
  width: 340px;
  height: 104px;
  gap: 32px;
  align-items: center;
  padding: 24px 32px;
  border-radius: 8px;
  border: 1px solid;
  font-size: 16px;
  text-align: left;
  &:hover {
    border-color: #F78C50 !important;
  }
}