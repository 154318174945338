@import "../../../styles/colors";
.profileWrapper {
  padding: 40px 72px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;
  > p {
    margin-top: -16px;
  }
  > button {
    margin-top: -8px;
  }
}

.flexRow {
  display: flex;
  gap: 24px;
  align-items: center;
  max-width: 50vw;
  justify-content: space-between;
  > * {
    flex: 2;
    word-break: break-all;
  }
  >:first-child {
    flex: unset;
  }
  >:last-child {
    flex: unset;
  }
  > img {
    height: 32px !important;
    width: 32px !important;
  }
}

.pending {
  color: $warning-strong-accent;
}

.confirmed {
  color: $success-strong-accent;
}

.changeImage {
  &Container {
    display: flex;
    align-items: center;
    gap: 40px;

    img {
      width: 120px !important;
      height: 120px !important;
    }
  }
  &Button {
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    p {
      color: $secondary-strong-accent;
      margin: 0;
    }
  }
}

.avatar {
  height: 120px !important;
  width: 120px !important;
  background-color: $secondary-hover-accent !important;
  &Small {
    height: 32px !important;
    width: 32px !important;
  }
}

.inputWrapper {
  position: relative;
  width: fit-content;
  button {
    position: absolute;
    top: 19px;
    right: 16px;
  }
}

.phoneInput {
  width:100%;
  max-width: 431px !important;
  border: 1px solid $secondary-strong-accent;
  box-sizing: border-box;
  width: fit-content;
  &Error {
    border-color: red;
  }
}

.floatingInputContainer {
  position: relative; 
  max-width: 431px !important;
  padding: 16px;
  border: 1px solid $secondary-strong-accent;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 60px;
  display: flex;
  &Disabled {
    border: 1px solid $main-operational-accent !important;
    * {
      color: $main-operational-accent !important;
    }
  }
  span {
    color: #FFFFFF;
  }
  &:focus-within {
    border-color: $brand-operational-accent;
    label {
      color: $brand-operational-accent;
    }
  }
  button {
    margin: 0;
  }
}

.floatingInputEdit{
  display: flex;
  gap: 8px;
  > button {
    background: transparent;
    border: 0;

    &:disabled {
      border: 1px solid $main-operational-accent;
      cursor: default;
    }
  }
  > .bordered {
    border: 1px solid $main-strong-accent;
    border-radius: 4px;
    padding: 4px;
  }
  > .clicked {
    background-color: $secondary-strong-accent;
  }
}

.floatingPhoneInputEdit{
  display: flex;
  position: absolute;
  right: 16px;
  gap: 8px;
  > button {
    background: transparent;
    border: 0;

    &:disabled {
      border: 1px solid $main-operational-accent;
      cursor: default;
    }
  }
  > .bordered {
    border: 1px solid $main-strong-accent;
    border-radius: 4px;
    padding: 4px;
  }
  > .clicked {
    background-color: $secondary-strong-accent;
  }
}

.floatingInputPlaceholder {
  position: absolute;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  transform: translate(5px, 0%);
  font-size: 16px;
}

.floatingInputContainer input[type=text].fieldInput {
  border: none;
  font-size: 16px !important;
  font-family: 'Didact Gothic', sans-serif;
  display: block;
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
}

.floatingInputContainer input[type=text].fieldInput.c-fix, input[type=text].fieldInput:focus, input[type=text].fieldInput:not(:placeholder-shown) {
  background-color: transparent;
}

.floatingInputContainer input[type=text].fieldInput.c-fix ~ label, input[type=text].fieldInput:focus ~ label, input[type=text].fieldInput:not(:placeholder-shown) ~ label {
  font-size: 12px;
  top:-10px;
  background: $white;
  transform: translate(5px, 0%);
}

.floatingInputContainer input[type=text].fieldInput::-webkit-input-placeholder {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
}

.floatingInputContainer input[type=text].fieldInput::-moz-placeholder {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
}

.floatingInputContainer input[type=text].fieldInput:-ms-input-placeholder {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
}

.tooltip {
  width: 221px;
  padding: 16px !important;
  font-size: 12px !important;
  font-family: 'Didact Gothic', sans-serif !important;
  border-radius: 8px!important;
  &::after {
    bottom: -7px !important;
    left: 10% !important;
  }
}

@media screen and (max-width: 1024px) {
  .profileWrapper {
    height:100%;
    min-height:100vh;
    padding: 80px 8px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
  
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }
  
    &::-webkit-scrollbar-thumb {
      outline: 1px solid $brand-hover-accent;
      background-color: $brand-hover-accent;
    }
  }

.floatingInputContainer {
  position: relative;
  min-width: 100%;
  max-width: 431px !important;
  padding: 16px;
  border: 1px solid $secondary-strong-accent;
  box-sizing: border-box;
  border-radius: 8px;
  width: fit-content;
  height: 60px;
  display: flex;
  &Disabled {
    border: 1px solid $main-operational-accent !important;
    * {
      color: $main-operational-accent !important;
    }
  }
  span {
    color: #FFFFFF;
  }
  &:focus-within {
    border-color: $brand-operational-accent;
    label {
      color: $brand-operational-accent;
    }
  }
  button {
    margin: 0;
  }
}
}