//DESKTOP
.RecessesOfTheSoulMainComponent {
  position: relative;
  background-position: center;
  background-size: cover; 
  background-repeat: no-repeat;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  min-height: 500px;
}

.typographyLogo {
  position: absolute;
  bottom: 15%;
  left: 20%;
  font-family: Catamount;
  font-size: 200px;
  font-style: italic;
  font-weight: 500;
  line-height: 126px;
  color: #F7EEE8;
}

//MOBILE
@media screen and (max-width: 768px) {
  .typographyLogo {
    left: 5%;
    font-size: 150px;
  }
}

//SMALL MOBILE
@media screen and (max-width: 525px) {
  .typographyLogo {
    left: 5%;
    font-size: 100px;
    line-height: 76px;
  }
}
