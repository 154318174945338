.CallMeByYourNameAppPromoteComponent {
  width: 100%;
  height: 100vh !important;
  max-height: 1099px;
  min-height: 500px !important;
  background: #BCC2B4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 91px 84px;
  h2 {
    font-family: Noto;
font-size: 28px;
font-weight: 300;
line-height: 40px;
letter-spacing: -0.03em;
text-align: left;

  }
   p {
    font-family: Noto !important;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: -0.6299999952316284px;
    text-align: left;
   }

   h3 {
    font-family: Catamount !important;
font-size: 96px;
font-weight: 400;
line-height: 98px;
letter-spacing: 0.01em;
text-align: left;
color : #FFF097;

   }

   a {
    background-color: #FFF097;
    border: none;
    font-family: Noto !important;
   }
   a:hover {
    background-color: #3a5943; 
      border: none;
   }
  > div {
    display: flex;
    max-width: 1220px;
    position: relative;
    justify-content: center;
    //width: 100%;
    gap: 40px;
    padding: 40px; 
    border-radius: 56px;
    > div {
      display: flex;
      width: 100%;
      max-width: 400px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
      padding: 16px;

      &:first-child {
        width: 50%;
      }
      > a {
        max-width: 300px;
      }

      > h4 {
        width: 100%;
        text-align: center;
      }

      > img {
        //position: absolute;
        //left: -5%;
        width: 100%;
        max-width: 582px;
      }
    }
  }

  h4 {
    font-size: 1.333em;
    letter-spacing: -3%;
    font-weight: 400;
    font-family: "DidactGothic-Regular";
    line-height: 32px;
  }
}

.CallMeByYourNameAppPromoteComponentImage {
  background-size: 450px;
  background-position: center;
  background-repeat: no-repeat;
}

//mobile
@media only screen and (max-width: 1600px) {
  .CallMeByYourNameAppPromoteComponent {
    height: unset !important;
    max-height: unset !important;
    padding: 91px 16px;
    > div {
      align-items: center;
      flex-direction: column;
      border: 0;
      padding: 0;
      > div {
        padding: 0;
        &:first-child {
          width: 100%;
        }
        > img {
          position: relative;
          max-height: 200px;
          width: initial;
          left: 0;
          display : none;
        }
      }
    }
  }
  .CallMeByYourNameAppPromoteComponentImage {
    background-position: center;
    background-size: 400px !important;
    background-repeat: no-repeat;
  }
}
