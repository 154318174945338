$error-state: #D54F68;

$main-strong-accent: #281B24;
$main-operational-accent: #A9A4A7;
$accent: #DAD8D9;
$main-background-accent: #F4F3F4;

$secondary-strong-accent: #63435B;
$secondary-operational-accent: #835978;
$secondary-hover-accent: #C7ADC1;

$brand-strong-accent: #F78C50;
$brand-operational-accent: #F9AC80;
$brand-hover-accent: #FBCCB1;

$white: #FFFFFF;
$black: #000000;

$brand-background-accent: #FDF6EB;

$green-light: #c1c6a8;

$success-strong-accent: #006150;
$success-state: #00C9A5;
$success-placeholder: #A3F5E6;

$warning-strong-accent: #B48F08;
$warning-state: #F5C92E;
$warning-placeholder: #FAE28F;

$error-strong-accent: #98243A;
$error-state: #D54F68;
$error-placeholder: #F8AAB8;