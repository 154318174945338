//DESKTOP
.ItsAllThanksToComponent {
  width: 100%;
  padding: 91px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fdf6eb;
  gap: 32px;
}

// TITLE
.ItsAllThanksToComponent > h1 {
  font-family: "Friday" !important;
  font-size: 38px !important;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #281b24;
}

@media only screen and (max-width: 1200px) {
  .ItsAllThanksToComponent {
    padding: 91px;
  }
}

@media only screen and (max-width: 768px) {
  .ItsAllThanksToComponent {
    padding: 91px 16px;
  }
}

.ItsAllThanksToComponent > div:last-child {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  background: #fdf6eb;
}

//LAYOUT 2_images

.layout_2_images {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.layout_2_images > img {
  max-width: 581px !important;
  max-height: 744px !important;
  width: 40% !important;
  height: 300px !important;
  object-fit: cover;
  padding: 0;
  border-radius: 8px !important;
  background-color: black;
}

//MOBILE
@media only screen and (max-width: 768px) {
  .layout_2_images > img {
    width: 100% !important;
    height: auto;
  }
}

//SMALL MOBILE
@media only screen and (max-width: 526px) {
  .layout_2_images > img {
    width: 100% !important;
    height: auto;
  }
}

//-----------------------
// LAYOUT 2_cols_3_images
//-----------------------

//DESKTOP
.layout_2_cols_3_images {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
}

.layout_2_cols_3_images > img {
  max-width: 581px !important;
  max-height: 791px !important;
  width: 50% !important;
  height: calc(60vh + 32px) !important;
  object-fit: cover;
  padding: 0;
  border-radius: 8px !important;
  background-color: black;
}
.layout_2_cols_3_images > div {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  max-height: 791px !important;
}
.layout_2_cols_3_images > div > img {
  max-width: 581px !important;
  object-fit: cover;
  width: 100%;
  height: calc(30vh) !important;
  padding: 0px;
  margin: 0px;
  border-radius: 8px;
}

//MOBILE
@media only screen and (max-width: 768px) {
  .layout_2_cols_3_images {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .layout_2_cols_3_images > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .layout_2_cols_3_images > img {
    width: 100% !important;
    height: auto;
  }

  .layout_2_cols_3_images > div > img {
    width: 100% !important;
    height: auto;
  }
}

//LAYOUT 2_cols_4_images

.layout_2_cols_4_images {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
}
.layout_2_cols_4_images > img {
  max-width: 581px !important;
  width: 50% !important;
  height: calc(60vh + 16px) !important;
  object-fit: cover;
  padding: 0;
  border-radius: 8px !important;
  background-color: black;
}
.layout_2_cols_4_images > div {
  width: 581px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}
.layout_2_cols_4_images > div > img {
  max-width: 581px !important;
  max-height: 744px !important;
  object-fit: cover;
  width: 100%;
  height: 30vh !important;
  padding: 0;
  border-radius: 8px;
}
.layout_2_cols_4_images > div > div {
  max-width: 581px !important;
  width: calc(100% - 16px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}
.layout_2_cols_4_images > div > div > img {
  max-width: 581px !important;
  height: 30vh;
  object-fit: cover;
  width: 50%;
  border-radius: 8px;
}

@media only screen and (max-width: 880px) {
  .layout_2_cols_4_images {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .layout_2_cols_4_images > img {
    width: 100% !important;
    height: auto;
  }

  .layout_2_cols_4_images > div {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  .layout_2_cols_4_images > div > img {
    width: 100% !important;
    height: auto;
    min-height: 229px !important;
  }
  .layout_2_cols_4_images > div > div {
    flex-wrap: nowrap;
    width: calc(100% - 16px) !important;
    padding: 0;
    margin: 0;
  }
  .layout_2_cols_4_images > div > div > img {
    width: calc(50%) !important;
    height: auto;
    max-height: 229px !important;
  }
}

//--------
//FIELDS
//--------

//DESKTOP
.fields {
  text-align: center;
}

.fields > p:first-child {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #281b24;
}
.fields > p:last-child {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #281b24;
}

.ItsAllThanksToComponent {
  > h2 {
    text-align: center;
  }
}
