@import "../../style";

* {
  outline: none;
}

/* HEADER ========================================== */
.header {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  background-color: #ffffff;
  border-radius: 0 0 12px 12px;
  position: fixed;
  max-height: 72px !important;
  box-shadow: 0px 2px 4px rgba(40, 27, 36, 0.08);
}

.header-md::after {
  background-image: none;
}

.headerLogo {
  width: 20vw;
  max-height: 32px;
}

.header button {
  background: none;
  border: none;
  height: 32px;
}

.notification button {
  cursor: pointer;
  background-color: unset;
  border: none;
}

.notification img {
  width: 32px;
}

.beep {
  width: 20px !important;
  position: relative;
  top: -13px;
  right: -35px;
}

.exit-notification-modal {
  position: absolute;
  left: 0;
  top: 0;
  height: 65%;
  width: 100vw;
  z-index: 100;
  background: transparent;
}

.editDeletePostModal {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100vw;
  z-index: 100;
  background: #281b24cc;
}

.editDeletePostModal > div {
  animation: slideUp 0.5s ease-in-out;
}

@keyframes slideUp {
  from {
    opacity: 0.5;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.editDeletePostModal > div:last-child {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: 0;
  height: 20%;
  width: 100vw;
  z-index: 100;
  border-radius: 8px 8px 0px 0px;
  background: white;
  padding-left: 16px;
  padding-right: 16px;
}

.editDeletePostModal > div:last-child > button {
  background: #fdf6eb;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  border: none;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 8px;
}

.editDeletePostModal > div:last-child > button > * {
  padding-left: 16px;
}

.editDeletePostModal > button:first-child {
  position: absolute;
  left: 0;
  top: 0;
  height: 80%;
  width: 100vw;
  z-index: 100;
  background: transparent;
  border: none;
}

.notification-modal {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100vw;
  z-index: 100;
  background: #281b24cc;

  > button {
    cursor: default;
  }
}

.notification-modal > div {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 45%;
  width: 100vw;
  z-index: 100;
  background: white;
  border-radius: 8px 8px 0px 0px;
  animation: slideUp 0.5s ease-in-out;
}

@keyframes slideUp {
  from {
    opacity: 0.5;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.notifications-list {
  position: absolute;
  right: 9vw;
  top: 6vw;
  background: #ffffff;
  border-radius: 12px;
  text-align: left;
  overflow-y: scroll;
  max-height: 324px;
  z-index: 5;
}

.notifications-list-hidden {
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 24px;
}

.notifications-list button {
  width: 352px;
  padding: 12px 24px;
  margin: 1px 0;
  background-color: #ecdfd39e;
  white-space: normal;
  font-size: 16px;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  text-align: start;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
}

@media screen and (max-width: 450px) {
  .header {
    padding: 16px;
    width: 100vw;
  }
  .headerLogo {
    width: 166px;
  }
  .header button {
    height: 24px;
  }
  .notifications-list {
    right: 24px;
    top: 48px;
  }
  .notifications-list button {
    width: 332px;
  }
  .beep {
    width: 16px !important;
    position: relative;
    top: -9px;
    right: -28px;
  }
}

@media screen and (max-width: 372px) {
  .notifications-list {
    right: 14px;
  }
  .notifications-list button {
    width: 242px;
  }
}

@media screen and (min-width: 1101px) {
  .header {
    padding: 16px 6vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    background-color: #ffffff;
    border-radius: 0 0 12px 12px;
    position: fixed;
  }
  .headerLogo {
    max-width: 156px;
  }
}

/* Main Feed ========================================== */
.divWrapperAboveInfiniteScroll {
  margin-bottom: 56px;
}

.mainWrapper {
  padding: 15vw 72px;
  //width: calc(100% - 144px);
  width: 100%;

  background: #f4f3f4;
  min-height: 100%;
  min-height: 100vh;
}

.more {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.more > span {
  margin-right: 8px;
}

.more > img {
  align-self: center;
}

.icon {
  width: 32px;
  height: 32px;
}

/* Footer Section ========================================== */
.footerSection {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 72px;
  box-shadow: 0px -2px 4px rgba(40, 27, 36, 0.08);
}

.footerSection::before {
  box-shadow: 0px -2px 4px rgba(40, 27, 36, 0.08);
  position: absolute;
  display: block;
  border-radius: 12px 12px 0 0;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  clip-path: polygon(
    0 0,
    calc(50% - 38px) 0,
    calc(50% - 37.487665px) 0.628287px,
    calc(50% - 36.975331px) 1.256382px,
    calc(50% - 36.462997px) 1.884094px,
    calc(50% - 35.912306px) 2.511233px,
    calc(50% - 35.863009px) 3.137607px,
    calc(50% - 35.802788px) 3.763025px,
    calc(50% - 35.731661px) 4.387296px,
    calc(50% - 35.64965px) 5.010232px,
    calc(50% - 35.55678px) 5.631641px,
    calc(50% - 35.453079px) 6.251334px,
    calc(50% - 35.338579px) 6.869124px,
    calc(50% - 35.213314px) 7.484821px,
    calc(50% - 35.077322px) 8.098238px,
    calc(50% - 34.930646px) 8.709188px,
    calc(50% - 34.77333px) 9.317486px,
    calc(50% - 34.605421px) 9.922945px,
    calc(50% - 34.426971px) 10.525381px,
    calc(50% - 34.238035px) 11.124612px,
    calc(50% - 34.038669px) 11.720454px,
    calc(50% - 33.828934px) 12.312725px,
    calc(50% - 33.608895px) 12.901246px,
    calc(50% - 33.378619px) 13.485837px,
    calc(50% - 33.138175px) 14.066321px,
    calc(50% - 32.887636px) 14.642519px,
    calc(50% - 32.62708px) 15.214257px,
    calc(50% - 32.356586px) 15.781361px,
    calc(50% - 32.076235px) 16.343658px,
    calc(50% - 31.786113px) 16.900976px,
    calc(50% - 31.486309px) 17.453146px,
    calc(50% - 31.176915px) 18px,
    calc(50% - 30.858023px) 18.541371px,
    calc(50% - 30.529731px) 19.077094px,
    calc(50% - 30.19214px) 19.607005px,
    calc(50% - 29.845353px) 20.130945px,
    calc(50% - 29.489474px) 20.648752px,
    calc(50% - 29.124612px) 21.160269px,
    calc(50% - 28.750878px) 21.665341px,
    calc(50% - 28.368387px) 22.163813px,
    calc(50% - 27.977255px) 22.655534px,
    calc(50% - 27.5776px) 23.140354px,
    calc(50% - 27.169545px) 23.618125px,
    calc(50% - 26.753214px) 24.088702px,
    calc(50% - 26.328733px) 24.551941px,
    calc(50% - 25.896233px) 25.007701px,
    calc(50% - 25.455844px) 25.455844px,
    calc(50% - 25.007701px) 25.896233px,
    calc(50% - 24.551941px) 26.328733px,
    calc(50% - 24.088702px) 26.753214px,
    calc(50% - 23.618125px) 27.169545px,
    calc(50% - 23.140354px) 27.5776px,
    calc(50% - 22.655534px) 27.977255px,
    calc(50% - 22.163813px) 28.368387px,
    calc(50% - 21.665341px) 28.750878px,
    calc(50% - 21.160269px) 29.124612px,
    calc(50% - 20.648752px) 29.489474px,
    calc(50% - 20.130945px) 29.845353px,
    calc(50% - 19.607005px) 30.19214px,
    calc(50% - 19.077094px) 30.529731px,
    calc(50% - 18.541371px) 30.858023px,
    calc(50% - 18px) 31.176915px,
    calc(50% - 17.453146px) 31.486309px,
    calc(50% - 16.900976px) 31.786113px,
    calc(50% - 16.343658px) 32.076235px,
    calc(50% - 15.781361px) 32.356586px,
    calc(50% - 15.214257px) 32.62708px,
    calc(50% - 14.642519px) 32.887636px,
    calc(50% - 14.066321px) 33.138175px,
    calc(50% - 13.485837px) 33.378619px,
    calc(50% - 12.901246px) 33.608895px,
    calc(50% - 12.312725px) 33.828934px,
    calc(50% - 11.720454px) 34.038669px,
    calc(50% - 11.124612px) 34.238035px,
    calc(50% - 10.525381px) 34.426971px,
    calc(50% - 9.922945px) 34.605421px,
    calc(50% - 9.317486px) 34.77333px,
    calc(50% - 8.709188px) 34.930646px,
    calc(50% - 8.098238px) 35.077322px,
    calc(50% - 7.484821px) 35.213314px,
    calc(50% - 6.869124px) 35.338579px,
    calc(50% - 6.251334px) 35.453079px,
    calc(50% - 5.631641px) 35.55678px,
    calc(50% - 5.010232px) 35.64965px,
    calc(50% - 4.387296px) 35.731661px,
    calc(50% - 3.763025px) 35.802788px,
    calc(50% - 3.137607px) 35.863009px,
    calc(50% - 2.511233px) 35.912306px,
    calc(50% - 1.884094px) 35.950663px,
    calc(50% - 1.256382px) 35.97807px,
    calc(50% - 0.628287px) 35.994517px,
    50% 36px,
    calc(50% + 0.628287px) 35.994517px,
    calc(50% + 1.256382px) 35.97807px,
    calc(50% + 1.884094px) 35.950663px,
    calc(50% + 2.511233px) 35.912306px,
    calc(50% + 3.137607px) 35.863009px,
    calc(50% + 3.763025px) 35.802788px,
    calc(50% + 4.387296px) 35.731661px,
    calc(50% + 5.010232px) 35.64965px,
    calc(50% + 5.631641px) 35.55678px,
    calc(50% + 6.251334px) 35.453079px,
    calc(50% + 6.869124px) 35.338579px,
    calc(50% + 7.484821px) 35.213314px,
    calc(50% + 8.098238px) 35.077322px,
    calc(50% + 8.709188px) 34.930646px,
    calc(50% + 9.317486px) 34.77333px,
    calc(50% + 9.922945px) 34.605421px,
    calc(50% + 10.525381px) 34.426971px,
    calc(50% + 11.124612px) 34.238035px,
    calc(50% + 11.720454px) 34.038669px,
    calc(50% + 12.312725px) 33.828934px,
    calc(50% + 12.901246px) 33.608895px,
    calc(50% + 13.485837px) 33.378619px,
    calc(50% + 14.066321px) 33.138175px,
    calc(50% + 14.642519px) 32.887636px,
    calc(50% + 15.214257px) 32.62708px,
    calc(50% + 15.781361px) 32.356586px,
    calc(50% + 16.343658px) 32.076235px,
    calc(50% + 16.900976px) 31.786113px,
    calc(50% + 17.453146px) 31.486309px,
    calc(50% + 18px) 31.176915px,
    calc(50% + 18.541371px) 30.858023px,
    calc(50% + 19.077094px) 30.529731px,
    calc(50% + 19.607005px) 30.19214px,
    calc(50% + 20.130945px) 29.845353px,
    calc(50% + 20.648752px) 29.489474px,
    calc(50% + 21.160269px) 29.124612px,
    calc(50% + 21.665341px) 28.750878px,
    calc(50% + 22.163813px) 28.368387px,
    calc(50% + 22.655534px) 27.977255px,
    calc(50% + 23.140354px) 27.5776px,
    calc(50% + 23.618125px) 27.169545px,
    calc(50% + 24.088702px) 26.753214px,
    calc(50% + 24.551941px) 26.328733px,
    calc(50% + 25.007701px) 25.896233px,
    calc(50% + 25.455844px) 25.455844px,
    calc(50% + 25.896233px) 25.007701px,
    calc(50% + 26.328733px) 24.551941px,
    calc(50% + 26.753214px) 24.088702px,
    calc(50% + 27.169545px) 23.618125px,
    calc(50% + 27.5776px) 23.140354px,
    calc(50% + 27.977255px) 22.655534px,
    calc(50% + 28.368387px) 22.163813px,
    calc(50% + 28.750878px) 21.665341px,
    calc(50% + 29.124612px) 21.160269px,
    calc(50% + 29.489474px) 20.648752px,
    calc(50% + 29.845353px) 20.130945px,
    calc(50% + 30.19214px) 19.607005px,
    calc(50% + 30.529731px) 19.077094px,
    calc(50% + 30.858023px) 18.541371px,
    calc(50% + 31.176915px) 18px,
    calc(50% + 31.486309px) 17.453146px,
    calc(50% + 31.786113px) 16.900976px,
    calc(50% + 32.076235px) 16.343658px,
    calc(50% + 32.356586px) 15.781361px,
    calc(50% + 32.62708px) 15.214257px,
    calc(50% + 32.887636px) 14.642519px,
    calc(50% + 33.138175px) 14.066321px,
    calc(50% + 33.378619px) 13.485837px,
    calc(50% + 33.608895px) 12.901246px,
    calc(50% + 33.828934px) 12.312725px,
    calc(50% + 34.038669px) 11.720454px,
    calc(50% + 34.238035px) 11.124612px,
    calc(50% + 34.426971px) 10.525381px,
    calc(50% + 34.605421px) 9.922945px,
    calc(50% + 34.77333px) 9.317486px,
    calc(50% + 34.930646px) 8.709188px,
    calc(50% + 35.077322px) 8.098238px,
    calc(50% + 35.213314px) 7.484821px,
    calc(50% + 35.338579px) 6.869124px,
    calc(50% + 35.453079px) 6.251334px,
    calc(50% + 35.55678px) 5.631641px,
    calc(50% + 35.64965px) 5.010232px,
    calc(50% + 35.731661px) 4.387296px,
    calc(50% + 35.802788px) 3.763025px,
    calc(50% + 35.863009px) 3.137607px,
    calc(50% + 35.912306px) 2.511233px,
    calc(50% + 36.462997px) 1.884094px,
    calc(50% + 36.975331px) 1.256382px,
    calc(50% + 37.487665px) 0.628287px,
    calc(50% + 38px) 0,
    100% 0,
    100% 100%,
    0 100%
  );
}

.notifications-clear {
  align-self: center;
  border-radius: 8px;
  background: transparent;
  border: none;
}

.notifications-clear > * {
  align-self: center;
  border-radius: 8px;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  height: 48px;
  background: #f9ac80;
  margin-bottom: 8px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notifications {
  background: transparent;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  overflow-y: scroll;
  margin-top: 20px;
  margin-bottom: 0px;
}

.notifications > button {
  background: transparent;
  border: none;
}

.singe-notification > p {
  background: $brand-background-accent;
  margin-left: 8px;
  margin-right: 10px;
  margin-bottom: 8px;
  height: 56px;
  display: flex;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
}

.capture {
  box-shadow: 0px 2px 14px #fbccb1;
  position: absolute;
  width: 56px;
  height: 56px;
  left: 50%;
  top: -50%;
  transform: translateX(-50%);
  background-color: $brand-operational-accent;
  border-radius: 50%;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.capture > img {
  width: 24px;
  //filter: invert(1);
}

.profileImg,
.feedImg,
.homeImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 23%;
  cursor: pointer;

  //p {
  //  font-family: 'Didact Gothic';
  //  font-size: 12px;
  //  font-weight: 400;
  //  line-height: 16px;
  //  letter-spacing: -0.0047em;
  //  text-align: center;
  //}
}

.profileImg {
  left: calc(25% - 16px);
}

.feedImg {
  left: calc(75% - 16px);
}

.footerSectionPlain {
  box-shadow: 0px -2px 4px rgba(40, 27, 36, 0.08);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  border-radius: 8px 8px 0 0;
}

.underShadow {
  box-shadow: 0px 2px 4px rgba(40, 27, 36, 0.08);
  border-radius: 0px 0px 8px 8px;
}

.footerSectionMainPlain {
  border: 1px solid rgba(40, 27, 36, 0.08);
  position: fixed;
  display: flex;
  gap: 7vw;
  justify-content: center;
  padding: 0 12px;
  align-items: center;
  background: $white;
  bottom: 0;
  left: 0;
  right: 0;
  height: 72px;

  @media screen and (max-width: 420px) {
    gap: 12px;
    justify-content: space-between;
  }

  .website {
    background: transparent;
    border-radius: 8px;
  }

  > div, button {
    padding: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border: none;
    > p {
      font-size: 16px;
    }
  }
}

.footerSectionPlain::before {
  position: absolute;
  display: block;
  border-radius: 12px 12px 0 0;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
}

.homeImg {
  left: calc(50% - 16px);
}

.plainImg {
  width: 24px;
  cursor: pointer;
  &Default {
    cursor: default;
  }
}

@media screen and (max-width: 768px) {
  .mainWrapper {
    padding: 88px 0px;
    width: 100%;
  }
  .profileImg {
    left: 18%;
  }
  .feedImg {
    left: 75%;
  }
  .notification img {
    width: 24px;
  }
}

.loadingView {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1101px) {
  .infinite-scroll-content-ios {
    max-width: 572px;
    margin: 0 auto;
  }
  .infinite-scroll-content-md {
    max-width: 572px;
    margin: 0 auto;
  }
  .mainWrapper {
    width: 100%;
    padding: 0;
    padding-top: 8vw;
  }
}
