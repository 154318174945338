@font-face {
  font-family: "Yiggivoo";
  src: url("../../../fonts/Yiggivoo-UC.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Cyntho";
  src: url("../../../fonts/Cyntho-Regular.otf") format("opentype");
}


.OurStoryComponent{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  padding: 188px 24px; 
  background-color: #F1EDE2; 
  gap: 15%;
  > :first-child > *:first-child {  
  font-family: Yiggivoo !important;
  font-weight: 400;
  font-size: 38px;
  width: 100%;
  max-width: 540px;
  padding-bottom: 70px;
  }
} 
.OurStoryComponent > h3 {  
  font-family: Yiggivoo !important;
  font-weight: 400;
  font-size: 37.8px;
  width: 100%;
  max-width: 540px;
}

.OurStoryComponent > div {
  width:  540px;
  max-width: 540px !important; 
  margin: 16px 0;
  font-size: 1em;
  letter-spacing: -0.40px;
  font-weight: 400;
  line-height: 24px;
  text-wrap: wrap;
  
  p {
    max-width: 540px;
    word-wrap: break-word; 
  }
  * {
    max-width: 540px;
    text-wrap: wrap;
   
    font-family: Cyntho !important;
  }
}

@media only screen and (max-width: 768px) {
  .OurStoryComponent {
    padding: 88px 24px;

    > :nth-child(2) { 
      display: none;
    }
  }
}