//DESKTOP
.ItsAllThanksToComponent {
  position: relative;
  width: 100%;
  padding: 120px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background-color: #FFF9F6;
}

.topElement {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 40px 120px;
  border-radius: 0px 0px 160px 160px;
  border-right: 2px solid #FFF9F6;
  border-bottom: 2px solid #FFF9F6;
  border-left: 2px solid #FFF9F6;
  background: #C1A088;
}

// TITLE
.ItsAllThanksToComponent div > h2 {
  color: #584139;
  text-align: center;
  font-family: Arima;
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: 52px; /* 108.333% */
  letter-spacing: -0.63px;
}

@media only screen and (max-width: 1200px) {
  .ItsAllThanksToComponent {
    padding: 91px;
  }
}

@media only screen and (max-width: 768px) {
  .ItsAllThanksToComponent {
    padding: 91px 16px;
  }
}

.ImageContentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  // flex-wrap: wrap;

  & > img {
    max-width: 62px;
  }
}

.ItsAllThanksToComponent > div:last-child {
  display: flex;
  justify-content: space-evenly;
  // flex-wrap: wrap;
  align-items: center; 
}

//LAYOUT 2_images

.layout_2_images {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.layout_2_images > img {
  max-width: 300px !important;
  max-height: 440px !important;
  object-fit: cover;
  padding: 0;

  aspect-ratio: 0.68 / 1;
  
  border-radius: 80px;
  border: 2px solid #C1A088;
}

//MOBILE
@media only screen and (max-width: 768px) {
  .layout_2_images > img {
    width: 100% !important;
    height: auto;
  }
}

//SMALL MOBILE
@media only screen and (max-width: 526px) {
  .layout_2_images > img {
    width: 100% !important;
    height: auto;
  }
}

//-----------------------
// LAYOUT 2_cols_3_images
//-----------------------

//DESKTOP
.layout_2_cols_3_images {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
}

.layout_2_cols_3_images > img {
  max-width: 300px !important;
  max-height: 440px !important;
  width: 33% !important;
  object-fit: cover;
  padding: 0;

  aspect-ratio: 0.68 / 1;

  border-radius: 80px;
  border: 2px solid #C1A088;
}

.layout_2_cols_3_images > div {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  max-height: 440px !important;
}

.layout_2_cols_3_images > div > img {
  max-width: 300px !important;
  object-fit: cover;
  width: 100%;
  height: calc(30vh) !important;
  padding: 0px;
  margin: 0px;
}

//MOBILE
@media only screen and (max-width: 768px) {
  .layout_2_cols_3_images {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
  }
  .layout_2_cols_3_images > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50%;
  }
  .layout_2_cols_3_images > img {
    width: 33% !important;
    height: 25vh;
  }

  .layout_2_cols_3_images > div > img {
    width: 100% !important;
    height: auto;
  }
}

//LAYOUT 2_cols_4_images

.layout_2_cols_4_images {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
}

.layout_2_cols_4_images > img {
  max-width: 300px !important;
  width: 25% !important;
  object-fit: cover;
  padding: 0;

  aspect-ratio: 0.68 / 1;

  border-radius: 80px;
  border: 2px solid #C1A088;
}

.layout_2_cols_4_images > div {
  width: 581px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.layout_2_cols_4_images > div > img {
  max-width: 300px !important;
  max-height: 440px !important;
  object-fit: cover;
  width: 100%;
  height: 30vh !important;
  padding: 0;
}

.layout_2_cols_4_images > div > div {
  max-width: 300px !important;
  width: calc(100% - 16px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}

.layout_2_cols_4_images > div > div > img {
  max-width: 300px !important;
  height: 100%;
  object-fit: cover;
  width: 50%;
}

@media only screen and (max-width: 880px) {
  .layout_2_cols_4_images {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .layout_2_cols_4_images > img {
    width: 100% !important;
    height: auto;
  }

  .layout_2_cols_4_images > div {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  .layout_2_cols_4_images > div > img {
    width: 100% !important;
    height: auto;
    min-height: 229px !important;
  }
  .layout_2_cols_4_images > div > div {
    flex-wrap: nowrap;
    width: calc(100% - 16px) !important;
    padding: 0;
    margin: 0;
  }
  .layout_2_cols_4_images > div > div > img {
    width: calc(50%) !important;
    height: auto;
    max-height: 229px !important;
  }
}

//--------
//FIELDS
//--------

//DESKTOP
.fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  
  > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  > div > div > h5 {
    color: #584139;
    font-family: Arima;
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 39px; /* 111.429% */
    letter-spacing: -1.05px;
    text-align: center;
    margin-bottom: 8px;
  }

  > div > div > p {
    color: #584139;
    font-family: Barnebok;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.54px;
    text-align: center;
  }

  > div > div > span {
    display: block;
    width: 30%;
    height: 1px;
    background-color: #584139;
    margin: 10px auto;
  }

  > img {
    width: 100%;
    max-width: 600px;

    @media only screen and (max-width: 420px) {
      width: auto;
      margin-left: -70%;
      overflow: hidden;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    gap: 0;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
}