a {
  color: #281b24;
}
.otp {
  grid-gap: 72px;
  padding: 10% 72px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100vw;
}
.otp-logo {
  width: 100%;
}
.otp-logo img {
  width: 100%;
  max-width: 372px;
}
.otp-text {
  width: 100%;
}
.otp-text > p {
  font-size: 24px;
}

.otp-code {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.otp-input {
  padding-right: 16px;

  &:nth-child(3) {
    padding-right: 32px;
  }

  &:last-child {
    padding-right: 0;
  }
}

/* Chrome, Safari, Edge, Opera */
.otp-code > input::-webkit-outer-spin-button,
.otp-code > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.otp-code > input[type="number"] {
  -moz-appearance: textfield;
}
.otp-code > input:first-child {
  border: solid 1px #63435b;
  border-radius: 8px;
  width: 130px;
  height: 40px;
  padding-left: 0.6ch;
  background: repeating-linear-gradient(90deg, transparent 0px, transparent 18px, #63435b 20px);
  color: black;
  font-family: monospace;
  letter-spacing: 1.25ch;
  font-size: 16px;
}

/*styling to create single digits otp style code input*/
#partitioned {
  padding-left: 3.2vw;
  letter-spacing: 8.6vw;
  border: 0;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 11vw 1px;
  background-repeat: repeat-x;
  background-position-x: 8vw;
  width: 65vw;
  font-size: 5vw;
}
#divInner {
  left: 0;
  position: sticky;
}
#divOuter {
  margin-top: 72px;
}

@media screen and (max-width: 450px) {
  .otp {
    grid-gap: 40px;
    padding: 30% 32px;
    min-height: 100vh;
  }
  .otp-logo img {
    width: 232px;
  }
  .otp-text {
    width: 90%;
  }
  .otp-text > p {
    font-size: 21.33px;
    line-height: 32px;
    letter-spacing: -0.84px;
  }
}

@media screen and (max-width: 360px) {
  .otp-input {
    padding-right: 8px;

    &:nth-child(3) {
      padding-right: 16px;
    }
  }
}
