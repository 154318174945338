@import "../../../../styles/colors";

.questions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  h3 {
    margin-bottom: 8px;
  }
}

.btns {
  display: flex;
  gap: 8px;
  img {
    cursor: pointer;
  }
}

.question {
  &Wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    .checkbox {
      display: flex;
      gap: 16px;
      margin-top: 16px;
    }
    input[type="number"] {
      margin-top: 16px;
    }
  }
}

.addQuestion {
  margin-top: 24px;

  .questionType {
    display: flex;
    gap: 16px;
    margin-top: 16px;
    label {
      margin-right: 16px;
    }
  }

  .singleAnswers {
    display: flex;
    flex-direction: column;
    .answer {
      display: flex;
      align-items: center;
    }
  }

}
