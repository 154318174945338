.Baptism2GiftComponent {
  background-color: #f1ede2; 
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 91px 16px;

  .Inside {
    max-width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 72px;
    gap: 72px;
    flex-wrap: wrap;

    &Graphic {
      width: 100%;
      max-width: 250px;
      height: 100%;
      max-height: 250px;
    }

    &Info {
      display: flex;
      flex-direction: column;
      gap: 16px;
      h2 {
        font-family: Yiggivoo;
font-size: 38px;
font-weight: 400;
line-height: 130px;
letter-spacing: 0.01em;
text-align: left; 

      }
      p {
        max-width: 401px;
        font-family: Cyntho;
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: -0.6299999952316284px;
        text-align: left;
        
       
      }
    }
  }
  .giftModal {
    display: flex;
    position: absolute;
    min-width: 467px;
    top: 0;
    left: 60px;
    flex-direction: column;
    gap: 16px;
    padding: 32px; 
    box-shadow: 0 3px 20px rgba(144, 154, 139, 0.05);
    border-radius: 16px;
    @media screen and (max-width: 900px) {
      max-width: 366px;
      left: 0;
      top: 63px;
    }
   

    .highlighted {
      color: #FFF097 !important;
      margin-right: 8px;
    }

    &Details {
      display: flex;
      gap: 16px;
      width: 100%;
      > button {
        margin-left: auto;
      }
    }
  }
}

.center {
  * {
    margin: auto;
  }
}
