@import "../../../../styles/colors";

input[type="time"]::-moz-selection {
  background-color: #d12727;
}
input[type="time"]::selection {
  background-color: #e90909;
}

.event {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.locationWithOptions {
  display: flex;
  gap: 16px;
  align-items: center;
  max-width: 532px;
  width: 100%;
  p {
    color: $secondary-operational-accent;
  }
}

.hr {
  max-width: 532px;
  width: 100%;
  margin-top: 0;
}

.modalWrapper {
  position: absolute;
  display: flex;
  background-color: #281B24CC;
  width: 100%;
  height: 100%;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 11;
  align-items: center;
}

.form {
  background: $white;
  padding: 40px;
  border: 1px solid #63435B;
  border-radius: 8px;
  max-height: 80%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }

  .timeDiv {
    flex-direction: row;
    align-items: center;
    gap: 42px;

    .timePickerDiv {
      max-width: 116px; 
      background: #FFFFFF;
      border: 1px solid #A9A4A7;
      border-radius: 8px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 100%;
    max-height: unset;
    width: 100%;
    border-radius: 0;

    input {
      max-width: 75vw !important;
    }

    .timeDiv > div:nth-child(2),
    .timeDiv > div:nth-child(3) {
      margin-top: 24px;
    }
  }
}

.btns {
  display: flex;
  gap: 48px;
  margin-top: 72px;
  button {
    width: fit-content;
    padding: 12px 32px;
  }
}

.popUpGlobalMobileModal {
  background-color: transparent;
  position: relative;
  z-index: 1000;
  height: 24px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 

  .warning {
    display: flex;
    flex-direction: row;
    align-items: center;
 
    background: #FAE28F;
    border-radius: 16px;
   
    height: fit-content;
   
 > * {
  font-size: 16px;
 }
    button {
      border: 0;
      background: transparent;
    }
  }
}
.warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  background: #FAE28F;
  border-radius: 16px;
  max-width: 487px;
  height: fit-content; 

  button {
    border: 0;
    background: transparent;
  }
}
.popUpGlobalModal > div > div > * {
  width: 100%;
  margin-bottom: 8px;
}

.popUpGlobalModal > * > * {
  margin-top: 8px;
  margin-bottom: 8px;
}
.popUpGlobalModal > p {
  padding-top: 32px;
  font-size: 21.33px;
}

.popUpGlobalModalHeading {
  font-size: 37.9px;
  padding-bottom: 32px;
}

.popUpGlobalModal > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //background: $white;
  box-shadow: 0 4px 24px #63435b4d;
  border-radius: 8px 0 0 8px;
  width: 100%;
  height: 100%;
  padding: 20px 20px 20px 20px;
  z-index: 2000000002;
  opacity: 1;
  animation-name: showGlobalModal;
  animation-duration: 0.5s;
}

.popUpGlobalModal > div > button {
  background: $brand-operational-accent;
  border: 1px solid $brand-strong-accent;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 60px;
  margin-right: 20px;
  color: $black;
  font-size: large;

  animation-name: showGlobalModalOpacity;
  animation-duration: 0.5s;
}
