@import '../../../../styles/colors.scss';

.sendModal {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2000000002;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &Active {
    display: flex;
  }
  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 512px;
    background-color: $white;
    border: 1px solid $secondary-strong-accent;
    border-radius: 8px;
    padding: 38px;
  }
  &SearchResults {
    position: absolute;
    width: 434px;
    max-height: 100px;
    background-color: $white;
    border: 1px solid $secondary-strong-accent;
    padding: 8px;
    overflow-y: scroll;
    z-index: 1;
    border: 1px solid $accent;
    border-radius: 8px;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      outline: 1px solid $brand-hover-accent;
      background-color: $brand-hover-accent;
    }

    &Button {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      padding: 8px;
      background: none;
      border: none;
      border-radius: 4px;
      width: 100%;

      &:hover {
        background-color: $brand-operational-accent;
      }
    }
  }
}

.selected {
  display: flex;
  gap: 16px;
  width: 432px;
  //height: 56px;
  overflow-y: hidden;
  overflow-x: scroll;
  margin: 8px 0;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }

  &Guest {
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: flex-start;
    align-items: center;
    color: $brand-strong-accent;

    > img,
    div {
      border: 2px solid #f78c50;
    }

    &Tick {
      margin-left: 24px;
      margin-top: -12px;
      border: 0 !important;
      position: sticky;
    }
  }
}

.types {
  display: flex;
  gap: 16px;
  @media screen and (max-width: 1200px) {
    overflow: scroll;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
  }

  .type {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 133.33px;
    height: 96px;

    border: 1px solid $brand-hover-accent;
    border-radius: 8px;
    cursor: pointer;
    background: 0;

    &:disabled {
      opacity: 0.4;
    }

    &Selected {
      path {
        fill: $brand-hover-accent;
      }
    }
  }
}

.sendButton {
  width: 100% !important;
  margin: 0 !important;

  svg {
    fill: #63435b !important;
  }

  &:hover {
    svg {
      fill: $brand-operational-accent !important;
    }
  }
}

.sendDoneButton {
  //width: 100% !important;
  margin: 0 !important;
  background-color: $brand-hover-accent !important;

  &:hover {
    background-color: #63435b !important;
  }
}

.outlinedButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #63435b;
  color: #63435b;
  background-color: transparent;
  border-radius: 8px;
  width: 195px;
  height: 56px;

  *:first-child {
    padding-right: 8px;
  }

  *:last-child {
    margin-left: 8px;
  }

  &Active {
    background-color: $secondary-strong-accent;

    * {
      color: $white;
    }

    *:last-child {
      //margin-right: 8px;
    }
  }
}

.searchBar {
  height: 56px;
  display: flex;
  //justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 724px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.63px;
  flex-direction: row;
  padding: 16px;
  gap: 8px;
  border: 1px solid #dad8d9;
  border-radius: 8px;

  &Active {
    border: 1px solid $brand-operational-accent;
  }
}

.searchBar > input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 728px;
  border: 0;
  font-size: 16px;
}
