@font-face {
  font-family: "Yiggivoo";
  src: url("../../../fonts/Yiggivoo-UC.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Cyntho";
  src: url("../../../fonts/Cyntho-Regular.otf") format("opentype");
}


.Baptism2ContactInfoComponent {
  background-color: #F1EDE2;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 91px 16px;
  gap: 64px;
  
   > h1 {
    font-family: 'Yiggivoo';
    font-size: 38px;
    font-weight: 400;
    line-height: 130px;
    letter-spacing: 0.01em;
    text-align: left;
    

  }
  > div:last-child {
    max-width: 896px;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: white;
  }
}

.Card { 
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-direction: column;
  border-radius: 135px;
  min-width: 350px;
  height: 200px;

  > div:first-child {
    display: flex;
    justify-content: center;
    gap: 16px;
    align-items: center;
    h4 {
      font-family: Cyntho;
      font-size: 18px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: -0.6299999952316284px;
      text-align: left;
      color : white;
      
    }
    h3 {
      font-family: Cyntho; 
      font-size: 28px;
      font-weight: 300;
      line-height: 48px;
      letter-spacing: -0.03em;
      text-align: left;
      color: white;
      
    }
  }
}

//mobile
@media only screen and (max-width: 768px) {
  .Baptism2ContactInfoComponent {
    height: 60vh !important;
  }
  .Baptism2ContactInfoComponent {
    background-position: center;
    background-size: 400px !important;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 568px) {
  .Baptism2ContactInfoComponent {
    height: 50vh !important;
  }
  .Baptism2ContactInfoComponent {
    background-position: center;
    background-size: 350px !important;
    background-repeat: no-repeat;
  }
}
