.overlay{
  position: absolute;
  left: 0;
  top:0;
  z-index: 101;
  background: #281B24CC;
  height: 100%;
  width: 100%;
}
.MobileBottomModal{
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 232px;
  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
  z-index: 105;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: slideUp 0.5s;
}

@keyframes slideUp {
  from {
    opacity: 0.5;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity : 1;
    transform: translate3d(0, 0, 0);
  }
}

.MobileBottomModal > h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 21.328px;
  line-height: 32px;
  /* or 150% */
  padding-right: 32px;
  padding-left: 32px;
  letter-spacing: -0.84px;

  color: #281B24;
}
.MobileBottomModal > button {
  margin-right: 16px;
  margin-left: 16px;
  height: 40px;
  border: none;
  color: #FFFFFF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 18px;
}
.MobileBottomModal > .yes {
  background: #f9ac80;
  margin-bottom: 8px;
  color: black;

}
.MobileBottomModal > .no {
  background: transparent;
  color: black;
  margin-bottom: 16px;
}