@import "../ImYours.module.scss";

.ImYoursContactInfoComponent {
  background-color: #C1A088;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 91px 16px;
  gap: 32px;

  .contentWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: center;
    align-items: center;

    > img {
      &:last-child {
        transform: scaleX(-1);

        @media only screen and (max-width: 576px) {
          display: none;
        }
      }
    }
  }

  > h1 {
    color: #FFF9F6;
    text-align: center;
    font-family: Arima;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 52px; /* 108.333% */
    letter-spacing: -0.63px;
  }

  > div > div {
    max-width: 896px;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    border-radius: 100px;
    border: 2px solid #FFF9F6;
  }
}

.Card {
  background-color: transparent;
  padding: 40px 72px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex-direction: column;

  > div:first-child {
    display: flex;
    justify-content: center;
    gap: 16px;
    align-items: center;
    h4 {
      color: #FFF9F6;
      font-family: Barnebok;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px; /* 133.333% */
      letter-spacing: -0.54px;
    }
    h3 {
      color: #FFF9F6;
      font-family: Arima;
      font-size: 30px;
      font-style: normal;
      font-weight: 300;
      line-height: 39px; /* 111.429% */
      letter-spacing: -1.05px;
    }
  }
}

//mobile
@media only screen and (max-width: 768px) {
  .ImYoursContactInfoComponent {
    height: 60vh !important;
  }
  .ImYoursContactInfoComponent {
    background-position: center;
    background-size: 400px !important;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 568px) {
  .ImYoursContactInfoComponent {
    height: 50vh !important;
  }
  .ImYoursContactInfoComponent {
    background-position: center;
    background-size: 350px !important;
    background-repeat: no-repeat;
  }
}
