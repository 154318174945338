@font-face {
  font-family: "Yiggivoo";
  src: url("../../../fonts/Yiggivoo-UC.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Cyntho";
  src: url("../../../fonts/Cyntho-Regular.otf") format("opentype");
}

.urlFilled {
  background-image: var(--dynamic-bg-image) !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  overflow: hidden !important;
  position: relative !important;
}

//DESKTOP
.Baptism2MainComponent {
  justify-self: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  min-height: 500px;
  padding-top: 0;
  > * {
    font-family: Yiggivoo !important;
  }
}

.Baptism2MainComponent > *:last-child {
  color: #fdf6eb !important;
  font-size:  81px !important;
  letter-spacing: -0.84px !important;
  font-weight: 400 !important;
  word-break: break-word;
  text-align: center;
  font-family: Yiggivoo !important;
}

.typographyLogoWrapper  {
 
    height: '100%';
    width: '100%';
    display: 'flex';
    justify-content: 'flex-start';
    position: 'absolute';
    align-items: 'center';
    top: '0%';
    left: '160px';
    z-index: 100;
   
}

.typographyLogo > * {
  font-weight: 400 !important; 
  font-size: 81px !important;
  font-family: Yiggivoo !important;
}


//MOBILE
@media screen and (max-width: 768px) {
  .Baptism2MainComponent > svg {
    display: none;
  }
  .Baptism2MainComponent > *:last-child {
    color: #fdf6eb !important;
    font-size:  45px !important;
    letter-spacing: -0.84px !important;
    font-weight: 400 !important;
    word-break: break-word;
    text-align: center;
    font-family: Yiggivoo !important;
  }

  .typographyLogoWrapper  {
 
    height: '100%';
    width: '100%';
    display: 'flex';
    justify-content: 'flex-start';
    position: 'absolute';
    align-items: 'center';
    top: '0%';
    left: '0%';
    z-index: 100;
   
}

  .flowers {
    opacity: 0;
  }
}

//SMALL MOBILE
@media screen and (max-width: 525px) {
  .Baptism2MainComponent > *:last-child {
    color: #fdf6eb !important;
    font-size:  37px !important;
    letter-spacing: -0.84px !important;
    font-weight: 400 !important;
    word-break: break-word;
    text-align: center;
    font-family: Yiggivoo !important;
  }
}
