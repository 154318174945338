@import "../../../../styles/_colors";
.status {
  width: fit-content;
  position: relative;
  padding: 8px 16px;
  border-radius: 30px;
  &::before {
    content: '\2022';
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 4px;
  }
}

.rsvpResults {
  //max-height: 300px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    //margin-left: 10px;
    margin-top: 10px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
    margin-left: 10px;
    margin-top: 10px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }
}