@import "../../../styles/colors";

:root {
  --dark: $main-strong-accent;
  --primaryDark: $secondary-strong-accent;
  --primary: $secondary-operational-accent;
  --primaryLight: #c7adc1;
  --accent: $brand-strong-accent;
  --accentLight: $brand-operational-accent;
  --greenLight: $brand-hover-accent;
}

@keyframes slide {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(200vw, 0) scale(1);
    display: none;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    display: inline;
  }
  100% {
    transform: translate(200vw, 0) scale(1);
  }
}

.head {
  height: 60px;
  text-align: left;
}

.table > tbody > tr > th:first-child {
  padding-left: 8px;
}

.table > tbody > tr > td:first-child {
  padding-left: 8px;
}

.table {
  height: 100%;
  border-collapse: collapse;
  //border-top: 1px solid $main-operational-accent;
  //margin-top: 24px;
  //padding-top: 24px;
  margin-right: 8px;
}

.pagination {
  //border-top: 1px solid $main-operational-accent;
  flex: 4 0 1px;
  display: flex;
  flex-direction: column;
  //align-items: center;

  p {
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.isSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: #f9ac80;
  border-radius: 80px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: 8px;
}

.toolbox {
  //padding-top: 24px;
  width: 100% !important;
  display: flex; 
  justify-content: flex-start;
  height: 64px;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $main-operational-accent;

  padding: 8px 0px;
  &Mobile , &Tablet {
    width: 100% !important;
    display: flex; 
    justify-content: center;
    gap:16px;
    height: 64px;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $main-operational-accent;
  
    padding: 8px 0px;
  }
}

.addToolbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.openAddTag {
  // animation-name: slide;
  // animation-duration: 1s;
  // animation-iteration-count: 1;
  // animation-direction: normal;
  // animation-fill-mode: forwards;
  display: none;
}

.emptySpace {
  padding-right: 88px;
}

.padding {
  padding: 8px 16px;
}

.highlighted {
  background: #63435b;
  border-radius: 50px;
  padding: 8px 16px;
  button {
    span {
      color: white;
      padding: 0;
    }
  }
}

.flatButton > *:first-child {
  padding-right: 4px;
}

.flatButton > *:last-child {
  padding-top: 4px;
}

.flatButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0px;
  font-family: "Didact Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.63px;
  color: $secondary-operational-accent;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
  cursor: pointer;
  > input[type="file"] {
    display: none;
  }
  &:disabled {
    span {
      color: #c7adc1 !important;
    }
    cursor: not-allowed;
  }
}

.selectedRow {
  height: 60px;
  background: #fdf6eb;
  border-radius: 8px; 
  width: 100%;
}
.nonSelectedRow {
  height: 60px; 
  width: 100%;
}

.selectedRow > *:first-child {
  border-radius: 8px 0px 0px 8px;
}
.selectedRow > *:last-child {
  border-radius: 0px 8px 8px 0px;
}

.tableContainer {
  //flex: 80 0 1px;
  overflow-x: scroll;
  overflow-y: scroll;
  width: calc(100vw - 132px);
  height: 500px;
  &Laptop {
    width: calc(100vw - 88px);
  }
  &Tablet, &Mobile{
    width: calc(100vw);
  }
}

.tableContainer::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

.tableContainer::-webkit-scrollbar-thumb,
.tableContainer::-webkit-scrollbar-track {
  border-radius: 20px;
}

.tableContainer::-webkit-scrollbar-thumb {
  outline: 1px solid $brand-hover-accent;
  background-color: $brand-hover-accent;
}

.table > tr {
  text-align: left;
  height: 78px;
}

.table > tr:nth-child(1) {
  text-align: left;
  height: 40px;
}
.table > tr:nth-child(4) {
  text-align: left;
  min-width: 150px !important;
}

.table > td {
  display: flex;
  align-items: center;
}

.table > tbody > tr > th:nth-child(1) {
  width: 32px;
}

.table > tbody > tr > th:nth-child(2) {
  text-align: center;
  padding-right: 14px;
  width: 72px;
}

.table > tbody > tr > th:nth-child(3) {
  width: 168px;
}
.table > tbody > tr > th:nth-child(4) {
  width: 72px;
}
.table > tbody > tr > th:nth-child(5) {
  width: 100%;
}
.table > tbody > tr > th:nth-child(6),
.table > tbody > tr > th:nth-child(7),
.table > tbody > tr > th:nth-child(8),
.table > tbody > tr > th:nth-child(9) {
  width: 136px;
}

.table > tbody > tr > th:nth-child(10) {
  width: 272px;
}
.table > tbody > tr > th:nth-child(11) {
  width: 48px;
}

.table > tr > td:nth-child(2) {
  text-align: left;
}

@keyframes showModal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.exitButton {
  position: relative;
  left: 520px;
  bottom: 5px;
}

.doneToolboxButton {
  position: absolute;
  right: 56px;
  top: 128px;
  margin-bottom: 50px;
  cursor: pointer;
  align-self: center;
  justify-self: center;
}

.inputForm {
  margin-bottom: 32px;
  min-width: 235px;
  width: 90%;
  padding: 16px 24px;
  border: 1px solid #d0d0d0;
  border-radius: 8px;
  color: $main-strong-accent;
  font-size: 16px;
  font-weight: 400;
}

.emptyTable {
  position: fixed;
  left: 40%;
  top: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.63px;
}

.emptyTable > h3 {
  padding-top: 8px;
  font-size: 37.8975px;
  line-height: 40px;
  letter-spacing: -1.5px;
  padding-bottom: 8px;
}

.emptyTable > a {
  padding-top: 16px;
}

.hidden {
  display: none;
}

.hidden * {
  display: none;
}

.hidden > * {
  display: none;
}

.pointer {
  cursor: pointer;
}

.active {
  background: #f8eae9;
}

.exitToolboxButton {
  cursor: pointer;
  border: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 4px;
  align-items: center;
}

.shareListWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 8px;
  width: 464px;
  
  &Mobile {
    width: 90vw;
    top: 55px;
    left: 0px;
    right: 0px;
    margin-left: -70px;
  }
  position: absolute;
  top: 55px;
  right: -208px;
  > * {
    z-index: 6;
  }
  /* Brand Color / Purple 1 */

  background: #63435b;
  border-radius: 8px;
  z-index: 1;

  * {
    color: #fdf6eb;
  }
}

.outlinedButton,
.shareButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $secondary-strong-accent;
  background-color: $brand-background-accent;
  border-radius: 8px;
  width: 100%;
  height: 56px;
  padding: 16px;

  * {
    color: $secondary-strong-accent !important;
  }
}

.shareButton {
  justify-content: flex-start;
  gap: 8px;
  > h4 {
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > :last-child {
    margin-left: auto;
    border: 1px solid $secondary-strong-accent;
    border-radius: 4px;
    padding: 4px;
  }
}

.dropdown {
  position: absolute;
  display: none;
  flex-direction: column;
  gap: 12px;
  background-color: $brand-background-accent;
  border-radius: 8px;
  border: 1px solid $secondary-strong-accent;
  width: 100%;
  padding: 8px 12px;
  z-index: 1000;

  * {
    color: $secondary-strong-accent !important;
  }

  &Active {
    display: flex;
  }

  > &Button {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border: 0;
    padding: 4px 8px;
    background: 0;
    font-size: 16px;
    z-index: 1000;
    &:disabled {
      opacity: 0.4;
    }

    &Active {
      background-color: $brand-operational-accent;
      border-radius: 8px;
    }
  }
}
