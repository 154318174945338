.OurStoryComponent{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FDF6EB;
  padding: 188px 24px;
}

.OurStoryComponent > div {
  width: 100%;
  max-width: 798px;
  margin: 16px 0;
  font-size: 1em;
  letter-spacing: -0.40px;
  font-weight: 400;
  line-height: 24px;
  font-family: 'DidactGothic-Regular';
}

@media only screen and (max-width: 768px) {
  .OurStoryComponent {
    padding: 88px 24px;
  }
}