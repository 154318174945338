@import "../../../styles/colors";
.Main {
  background: #FBF9F8;
  display: flex;
  flex-direction: column;
  padding: 82px 0;
  margin-left: calc(50% - 400px);
  margin-right: calc(50% - 400px);
  overflow-y: scroll;


  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }
}
.Main > p {
  color : black;

}

.imgWrapper {
  text-align: center;
  > img {
    width: 50%;
    height: auto;
    max-height: 1200px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}

.eventsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  .event {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    min-width: 366px;
    max-width: 366px;
  }
  button {
    max-width: 332px;
  }
}

.highlighted {
  color: $secondary-operational-accent;
}