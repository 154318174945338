.Icon {
  color: inherit;
  display: block;

  // Sizes
  &.xs {
    height: 12px;
    width: 12px;
  }
  &.s {
    height: 16px;
    width: 16px;
  }

  &.sPlus {
    height: 24px;
    width: 24px;
  }

  &.m {
    height: 32px;
    width: 32px;
  }

  &.mPlus {
    height: 48px;
    width: 48px;
  }

  &.l {
    height: 56px;
    width: 56px;
  }
}
