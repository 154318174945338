@import "../TheArtist.module.scss";

//DESKTOP
.TheArtistMainComponent {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.typographyLogo {
  max-width: 409px;
  padding-left: 40px;
  font-family: CenturyGothic;
  font-size: 96px;
  font-weight: 400;
  line-height: 100px;
  letter-spacing: -0.63px;
  text-transform: uppercase;
  color: #333;
  word-spacing: 9999px; /* Hack to break after every whitespace */
}

@media screen and (max-width: 1536px) {
  .typographyLogo {
    max-width: 255px;
    padding-left: 20px;
    font-size: 60px;
    line-height: 90px;
  }
}

@media screen and (max-width: 1024px) {
  .TheArtistMainComponent {
    flex-wrap: wrap;

    & > img:first-child {
      width: 100%;
    }
  }

  .typographyLogo {
    font-size: 80px;
    line-height: 90px;
  }
}

@media screen and (max-width: 680px) {

  .TheArtistMainComponent {
    & > img:first-child {
      position: relative;
    }
  }

  .typographyLogo {
    position: absolute;
    color: #FBFBFB;
    font-size: 50px;
    line-height: 70px;
    padding: 10px 20px;
  }
}
