ion-toast.custom-toast {
  --background: #f9ad80;
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: #63435b;
}

ion-toast.custom-toast::part(message) {
  //font-style: italic;
  color: #281B24;
  font-size: 15.33px;
  font-weight: 400;
  font-family: "DidactGothic-Regular";
}

ion-toast.custom-toast::part(button) {
  border-left: 0px solid #d2d2d2;
  color: #281B24;
  font-size: 15.33px;
  font-weight: 600;
  font-family: "DidactGothic-Regular";
  border-radius: 10px;

  &:hover {
    background: #63435b;
    color: #f2f2f2;
  }
}