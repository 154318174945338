@import "../../style";

/* Post - Container ===================================== */
.postContainer {
    background-color: #FFFFFF;
    padding: 24px 24px 0;
    border-radius: 8px;
    margin-bottom: 44px;

}

/* Post - Profile Container ===================================== */
.profileContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.innerProfileContainer {
    display: inline-flex;
    grid-gap: 8px;
    align-items: center;
}
.profileContainerImg {
    width: 44px;
    height: 44px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}
.profileNameContainer {
    text-align: start;
}

.profileNameContainer h4 {

}
.profileNameContainer p{
    color: #A1A1A1 !important;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.47px;
}
.post-edit {
    cursor: pointer;
}



.comment-edit-modal {
    background: #281B24CC;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    bottom:0;
    z-index: 150 !important;

}
.comment-edit-modal > div {
    background: white;
    position: absolute;
    height: 25vh;
    width: 100vw;
    border-radius: 8px;
    left: 0;
    bottom:0;
    background: red;
    z-index: 160 !important;

}

.comment-edit-dropdown {
    display: flex;
    position: absolute;
    flex-direction: column;
    background: #F7F2ED;
    border-radius: 12px;
    padding: 16px;
}
.comment-edit-dropdown > button{
    appearance: none;
    background: none;
    border: none;
    align-items: center;
    display: flex;
    cursor: pointer;
    padding: 16px;
    font-size: 20px;
}
.post-caption {
    text-align: start;
    line-height: 24px;
    //max-height: 72px;
    //overflow-y: auto;
}
.textAreaContainer {
    display: flex;
    align-items: center;
    border-radius: 16px;
    margin-bottom: 16px;
}
.captionArea {
    margin: 8px 0;
}
.captionArea > .textAreaContainer > .mentions--multiLine .mentions__input {
    margin: 8px 0;
}
.textAreaContainer p {
    padding-right: 16px;
}
.textArea {
    width: calc(100% - 32px) !important;
    background-color: #f5f5f5;
    border: none;
    font-size: 16px;
    padding: 16px;
    border-radius: 12px;
    resize: none;
}
.textAreaContainer button {
    width: 32px;
    height: 32px;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0 16px;
}
/* Post - Image ===================================== */
.content {
    display: flex;
}

.content > img {
    width: 100%;
    height: auto;
    max-height: 1200px;
    object-fit: cover;
    border-radius: 8px;
    object-position: center;
}

.content-css {
    word-break: break-all;
}

/* Post - Interaction ===================================== */
.interaction {
    display: flex;
    margin: 16px 0;
    grid-gap: 32px;
}
.interaction img {
    width: 24px;
}

.post-edit > ion-img {
    padding: 0 8px;
}

.interaction > div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.interaction  button {
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}
.interaction  button {
   display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.interaction p {
    margin-left: 4px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.63px;
}

.loves{
    color: $secondary-strong-accent;
}

.loves-loved{
    color: $error-state;
}
.comments{
    color: $secondary-strong-accent;
}

/* Post - Comments ===================================== */
.commentsContainer {
    border-top: 1px  solid #DAD8D9;
}

.inputCommentContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 16px;
}
.inputComment {
    background: #F5F5F5;
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 16px;
    width: 90%;
    resize: none;
}
.inputCommentArea {
    max-height: 312px;
    overflow: auto;
    width: 100%;
    overflow-x: hidden;
    font-size: 16px;
    line-height: 24px;
}
.inputCommentContainer button {
    width: 32px;
    height: 32px;
    display: contents;
    background: transparent;
    border: none;
    padding: 9;
}
/* Comment - Interaction ===================================== */
.commentInteraction {
    display: flex;
    justify-content: center;
    align-items: center;
}
.commentInteraction  button {
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}
.commentInteraction p {
    // font-size: 12px;
    padding:0 4px;
}
.commentProfile span {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.47px;
}
@media screen and (max-width:450px) {
    .postContainer {
        padding: 16px;
        padding-bottom: 0;
        margin-bottom: 32px;
    }
    .profileContainerImg {
        width: 44px;
        height: 44px;
    }
    .profileNameContainer p {
        font-size: 12px;
    }
    .interaction img {
        width: 24px;
    }
    .interaction p {
        font-size: 16px;
    }
    .commentProfile img {
        width: 24px;
        height: 24px;
    }
    .inputComment {
        padding: 16px;
        width: calc(90% - 20px);
        font-size: 16px;
    }
    .inputCommentContainer button {
        width: 24px;
        height: 24px;
        margin: 0 12px;
    }
    .comment-edit-dropdown {
        padding: 8px;
    }
    .comment-edit-dropdown > button{
        font-size: 16px;
    }
}

.loadingPostImage{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (min-width:1101px) {
    .postContainer {
        background-color: #FFFFFF;
        padding: 24px;
        padding-bottom: 0;
        border-radius: 12px;
        margin-bottom: 44px;
        margin-left: calc(50% - 400px);
        margin-right: calc(50% - 400px);
    }
}

.imgIcon {
    height: 24px;
    margin-right: 8px;
}

