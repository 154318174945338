@import "../TheArtist.module.scss";

.OurStoryComponent{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  padding: 188px 100px; 
  background-color: #FFF; 
  gap: 40px;
  
  @media only screen and (max-width: 1366px) {
    gap: 0;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 91px 16px;
  }

  @media screen and (max-width: 525px) {
    padding: 44px 24px;
  
    > img {
      width: auto;
      overflow: hidden;
    }
  }
} 

.OurStoryComponent > div {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 16px 0;
  text-wrap: wrap;
  color: #333;
  font-style: normal;

  h3 {
    font-family: Syne;
    font-size: 50.52px;
    font-weight: 400 !important;
    line-height: 54px;
    letter-spacing: -0.63px;
  }

  p {
    font-family: CenturyGothic;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.54px;
    word-wrap: break-word; 
    text-align: justify;
  }
}
