@import "../ImYours.module.scss";

.OurStoryComponent{
  position: relative;
  width: 100%;
  padding: 160px 55px 40px 55px;
  background: #C1A088;
  gap: 40px;
  border-width: 0px 2px 0px 2px;
  border-style: solid;
  border-color: #FFF9F6;

  > img:first-child {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  > img:first-child,
  > img:last-child {
    position: absolute;
    bottom: 0;
  }

  > img:first-child {
    left: 5%;
  }

  > img:last-child {
    right: 5%;
  }

  @media (max-width: 1024px) {
    padding: 100px 55px 40px 55px;
  }

  @media (max-width: 768px) {
    padding: 40px 55px 40px 55px;

    > img:first-child,
    > img:last-child {
      display: none;
    }
  }
} 

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 16px 0;

  > div {
    display: flex;
    justify-content: center;
    gap: 80px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 20px;

      > img {
        width: auto;
        margin: 0 auto;
      }
    }
  }

  h3 {
    color: #FFF9F6;
    font-family: Arima;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 52px; /* 108.333% */
    letter-spacing: -0.63px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 41.05px;
    }
  }

  p {
    max-width: 550px;

    color: #FFF9F6;
    text-align: justify;
    font-family: Barnebok;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.54px;

    @media (max-width: 768px) {
      font-size: 15px;
    }
  }
}
