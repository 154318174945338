@import "../../../styles/colors";

.overviewWrapper {  
  background-color: white;
  padding: 40px 0; 
  border-radius: 0;
  z-index: 2;
  position: absolute;
  overflow-y: scroll !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  .header {
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-right: 40px;
    margin-bottom: 16px;

    .btns {
      position: relative;
      display: flex;
      gap: 16px;
      z-index: 10;

      > button {
        width: fit-content;
      }
    }
  }
}

.addGuestDropdown {
  position: absolute; 
  flex-direction: column;
  gap: 12px;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $secondary-strong-accent;
  width: 309px;
  margin-left: -120px;
  margin-top: 60px;
  padding: 8px 12px;
  z-index: 10;
  display: none;

  &Mobile {
    position: absolute; 
    flex-direction: column;
    gap: 12px;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $secondary-strong-accent;
    width: 309px;
    margin-left: -202px;
    margin-top: 60px;
    padding: 8px 12px;
    z-index: 10;
    &Active {
      position: absolute;
      display: flex;
      z-index: 10;
    }
    &Inactive{
      display: none;
    }
    
  
    > &Button {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 10;
      cursor: pointer;
      border: 0;
      padding: 4px 8px;
      background: 0;
      font-size: 16px;
      &:disabled {
        opacity: 0.4;
      }
  
      &Active {
        z-index: 10;
        position: absolute;
        background-color: $brand-operational-accent;
      }
    }
  }

 &Tablet, &Laptop {
  
  position: absolute; 
  flex-direction: column;
  gap: 12px;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $secondary-strong-accent;
  width: 309px;
  margin-left: -228px;
  margin-top: 60px;
  padding: 8px 12px;
  z-index: 10;
  &Active {
    position: absolute;
    display: flex;
    z-index: 10;
  }
  &Inactive{
    display: none;
  }
  

  > &Button {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    cursor: pointer;
    border: 0;
    padding: 4px 8px;
    background: 0;
    font-size: 16px;
    &:disabled {
      opacity: 0.4;
    }

    &Active {
      z-index: 10;
      position: absolute;
      background-color: $brand-operational-accent;
    }
  }
 }
  

  &Active {
    position: absolute;
    display: flex;
    z-index: 10;
  }
  &Inactive{
    display: none;
  }
  > &Button {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    cursor: pointer;
    border: 0;
    padding: 4px 8px;
    background: 0;
    font-size: 16px;
    &:disabled {
      opacity: 0.4;
    }

    &Active {
      z-index: 10;
      position: absolute;
      background-color: $brand-operational-accent;
    }
  }
}

.dropdown {
  position: absolute;
  display: none;
  flex-direction: column;
  gap: 12px;
  background-color: $secondary-strong-accent;
  border-radius: 8px;
  border: 1px solid $brand-background-accent;
  padding: 8px 12px;
  z-index: 1000;
  left: -104px;
  width: 464px !important;
  * {
    width: 100%;
    color: $brand-background-accent !important;
  }

  &Active {
    display: flex;
    top: 0px;
    position: absolute;
    width: 100% !important;
    margin-top: 60px;
    width: 464px !important;
    left: -274px;
    padding: 28px;

    &Mobile {
      display: flex;
      top: 0px;
      position: absolute;
      width: 100% !important;
      margin-top: 60px;
      margin-right: 160px;
      width: 304px !important;
      left: -198px !important;
      padding: 28px; 
    }
    &Laptop, &Tablet {
      display: flex;
      top: 0px;
      position: absolute;
      width: 100% !important;
      margin-top: 60px;
      margin-right: 160px;
      width: 464px !important;
      left: -384px !important;
      padding: 28px; 
    }
  }

  > button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    border: 0;
    background: 0;
    font-size: 16px;
    width: 100%;
    height: 30px;
    z-index: 1000;
    background-color: #fdf6eb;
    color: black;
    &:disabled {
      opacity: 0.4;
    }
    h4 {
      text-overflow: ellipsis;
      width: max-content !important;
      color: black !important;
      padding: 0 !important; 
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &Active {
      background-color: $brand-operational-accent;
      border-radius: 8px;
      margin-right: -20px;
    }
  }
}

.events {
  position: relative;
  background-color: $brand-background-accent;
  width: 100%;
  margin-bottom: 40px;
  max-height: 352px;
  z-index: 1;

  > div {
    background-color: #FDF6EB;
    position: relative;
    width: 100%;
    display: flex;
    gap: 32px;
    padding: 64px 40px 24px ;
    border-radius: 32px 0px 0px 32px;
    overflow-x: scroll; 

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      border-radius: 20px;
      padding: 0 40px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $brand-hover-accent;
      outline: 1px solid $brand-hover-accent;
      border-radius: 20px;
      width: 1px;
      height: 1px;
    }

    .event {
      position: relative;
      z-index: 1;
      min-height: 248px;
      word-break: break-all;
      background-color: white;
      padding: 32px;
      border-radius: 16px;
      min-width: 304px;
      max-width: 304px;
      cursor: pointer;

      filter: drop-shadow(0px 2px 4px rgb(40 27 36 / 37%));

      &:hover {
        //box-shadow: 0 0 4px #00000063;
        //-moz-box-shadow: 0 0 4px #00000063;
        //-webkit-box-shadow: 0 0 4px #00000063;
        //filter: drop-shadow(2px 2px #00000063);
        border: solid 1px #00000063;
        //filter: drop-shadow(0px 0px 0px transparent);
      }

      &Icon {
        width: 48px;
        height: 48px;
        position: relative;
        border-radius: 8px;
        margin-top: -56px;

        img {
          padding: 8px;
        }
      }

      &Add {
        background-color: transparent;
        border: 1px solid $secondary-hover-accent;
        cursor: pointer;

        .addBtn {
          height: 100%;
          display: flex;

          a {
            text-align: center;
            margin: auto;
            color: $secondary-strong-accent;
            text-decoration: none;
          }
        }
      }

      > p {
        margin-top: 16px;
      }

      .invited {
        z-index: 1;
        display: flex;
        align-items: center;
        margin-top: 8px;

        > div,
        img {
          //padding: 8px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-right: -8px;
          //border: 0.1em $main-strong-accent solid;

          p {
            font-size: 12px;
            color: $main-strong-accent;
            justify-self: center;
            align-self: center;
          }
        }
      }
    }
  }
}

.bottomSection {
  display: grid;
  grid-template-columns: 680px 680px 288px;
  gap: 40px;
  width: 100%;
  padding: 40px 0;

  @media only screen and (max-width: 1440px) {
    display: flex;
    flex-wrap: wrap;
  }

  .profileProgress {
    max-height: 440px;
    max-width: 680px;
    min-height: 440px;
    min-width: 440px;
    width: 80vw;
    box-shadow: 0px 5px 30px rgba(40, 27, 36, 0.05);
    border-radius: 8px;
    padding: 24px;
    overflow-y: scroll;

    &Tablet, &Laptop, &Mobile {
      max-height: 440px;
      max-width: 100%;
      min-height: 440px;
      min-width: 100%;
      width: 80vw;
      box-shadow: 0px 5px 30px rgba(40, 27, 36, 0.05);
      border-radius: 8px;
      padding: 24px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
        border-radius: 20px;
        padding: 0 40px;
      }
  
      &::-webkit-scrollbar-track {
        border-radius: 20px;
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: $brand-hover-accent;
        outline: 1px solid $brand-hover-accent;
        border-radius: 20px;
        width: 1px;
        height: 1px;
      }
  
      &Header {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 16px;
  
        img,
        svg {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
  
      .progressBar {
        display: flex;
        align-items: center;
        gap: 32px;
        margin-bottom: 24px;
  
        &Wrapper {
          width: 100%;
          height: 16px;
          background: #f4f3f4;
          border-radius: 30px;
        }
  
        .bar {
          height: 16px;
          background: $secondary-operational-accent;
          border-radius: 30px;
        }
      }
  
      .progressTasks {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-height: 440px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          height: 4px;
          width: 4px;
          border-radius: 20px;
          padding: 0 40px;
        }
  
        &::-webkit-scrollbar-track {
          border-radius: 20px;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: $brand-hover-accent;
          outline: 1px solid $brand-hover-accent;
          border-radius: 20px;
          width: 1px;
          height: 1px;
        }
  
        .progressTask {
          display: flex;
          justify-content: space-between;
  
          > div {
            display: flex;
            gap: 16px;
  
            
          }
  
        }
      }
    }


    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      border-radius: 20px;
      padding: 0 40px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $brand-hover-accent;
      outline: 1px solid $brand-hover-accent;
      border-radius: 20px;
      width: 1px;
      height: 1px;
    }

    &Header {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 16px;

      img,
      svg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .progressBar {
      display: flex;
      align-items: center;
      gap: 32px;
      margin-bottom: 24px;

      &Wrapper {
        width: 100%;
        height: 16px;
        background: #f4f3f4;
        border-radius: 30px;
      }

      .bar {
        height: 16px;
        background: $secondary-operational-accent;
        border-radius: 30px;
      }
    }

    .progressTasks {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-height: 440px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
        border-radius: 20px;
        padding: 0 40px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $brand-hover-accent;
        outline: 1px solid $brand-hover-accent;
        border-radius: 20px;
        width: 1px;
        height: 1px;
      }

      .progressTask {
        display: flex;
        justify-content: space-between;

        > div {
          display: flex;
          gap: 16px;

          
        }

      }
    }
  }

  .rsvpResults {
    max-height: 440px;
    max-width: 680px;
    min-height: 440px;
    min-width: 440px;
    width: 80vw;
    overflow-y: scroll;
    box-shadow: 0px 5px 30px rgba(40, 27, 36, 0.05);
    border-radius: 8px;
    padding: 24px;

    &Tablet, &Mobile, &Laptop {
      max-height: 440px;
      max-width:100%;
      min-height: 440px;
      min-width: 100%;
      width: 80vw;
      overflow-y: scroll;
      box-shadow: 0px 5px 30px rgba(40, 27, 36, 0.05);
      border-radius: 8px;
      padding: 24px;
      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
        border-radius: 20px;
        padding: 0 40px;
      }
  
      &::-webkit-scrollbar-track {
        border-radius: 20px;
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: $brand-hover-accent;
        outline: 1px solid $brand-hover-accent;
        border-radius: 20px;
        width: 1px;
        height: 1px;
      }
  
      &Header {
        display: flex;
        justify-content: space-between;
  
        > div {
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }
      
      .eventQuestions {
        .questions {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-top: 24px;
          margin-bottom: 48px;
  
    
  
          &Tablet, &Mobile, &Laptop {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 24px;
            margin-bottom: 48px;
            max-width: 100%;
            min-width: 100%;
         
          }
  
        }
      }
    }
    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      border-radius: 20px;
      padding: 0 40px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $brand-hover-accent;
      outline: 1px solid $brand-hover-accent;
      border-radius: 20px;
      width: 1px;
      height: 1px;
    }

    &Header {
      display: flex;
      justify-content: space-between;

      > div {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }

    .eventQuestions {
      .questions {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 24px;
        margin-bottom: 48px;

      

        &Tablet, &Mobile, &Laptop {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-top: 24px;
          margin-bottom: 48px;
          max-width: 100%;
          min-width: 100%;
          
        }

      }
    }
  }
  .flexRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    width: 100%; 
  }
  .restData {
    display: flex;
    //flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;

    &Invited {
      max-height: 200px;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 44px;
      box-shadow: 0px 5px 30px rgba(40, 27, 36, 0.05);
      border-radius: 8px;
      background-color: $brand-hover-accent;

      img {
        margin-bottom: 16px;
      }

      p {
        margin-bottom: 8px;
      }
    }

    &Rsvp {
      max-height: 200px;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 44px;
      box-shadow: 0px 5px 30px rgba(40, 27, 36, 0.05);
      border-radius: 8px;
      background-color: $secondary-hover-accent;

      img {
        margin-bottom: 16px;
      }

      p {
        margin-bottom: 8px;
      }
    }
  }
}

.taskbar {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  width: 22vw;
  height: 100%;
  background-color: #fbccb1;
  padding: 40px 5vw;

  h4 {
    margin-top: 24px;
  }

  .tasks {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px 0;

    .task {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 8px;

      &Checkbox {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 2px;
        input {
          //flex: 40%;
          background-color: transparent;
          width: 24px;
          height: 24px;
        }
        
        label {
          flex: 0% 1;
          word-break: break-word;
        }
      }
    }
  }

  .newTaskBtns {
    display: flex;
    flex-direction: column;

    input {
      background-color: #fbccb1;
      border: 1px solid $secondary-strong-accent;
      margin-bottom: 16px;
    }

    &Actions {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}

.overviewIconSecondary {
  background-color: $secondary-strong-accent;
  border: 1px solid $secondary-strong-accent;
  &:hover,
  &:active {
    background-color: white;
    border: 1px solid $secondary-strong-accent;
    color: $secondary-strong-accent;
  }
}

.taskbarSecondaryBtn {
  background-color: #fff !important;
  border: 1px solid transparent !important;
  padding: 8px 16px !important;
  border-radius: 80px !important;
  &:hover,
  &:active {
    background-color: $secondary-strong-accent !important;
    border: 1px solid $secondary-strong-accent !important;
    color: $brand-background-accent !important;
  }
}


// @media (max-width: 1024px) {
//   .overviewWrapper {
//     height: 100vh;
//     background-color: white;
//     padding: 40px 0;
//     border-radius: 0;
//     width: 100%;
//     z-index: 3;
//     position: absolute;
//   }
//   .addGuestDropdown {
//     position: absolute;
//     display: none;
//     flex-direction: column;
//     gap: 12px;
//     background-color: $white;
//     border-radius: 8px;
//     border: 1px solid $secondary-strong-accent;
//     width: 309px;
//     margin-left: -140px;
//     margin-top: 60px;
//     padding: 8px 12px;
//     z-index: 10;
//   }
// }

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid gray;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbonTopRight {
  top: -10px;
  right: -10px;
}
.ribbonTopRight::before,
.ribbonTopRight::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbonTopRight::before {
  top: 0;
  left: 0;
}
.ribbonTopRight::after {
  bottom: 0;
  right: 0;
}
.ribbonTopRight span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}
