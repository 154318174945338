@import "../ImYours.module.scss";

.ScheduleComponentWrapper {
  position: relative;
  background-color: #FFF9F6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 66px;
  width: 100%;
  height: fit-content;
  padding: 91px 16px;

  .title {
    color: #584139;
    text-align: center;
    font-family: Arima;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 52px; /* 108.333% */
    letter-spacing: -0.63px;
  }

  .graphicsWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > img {
      max-height: 100%;
      object-fit: contain;

      &:last-child{
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        transform: rotate(180deg);
      }
    }
  }

  > div > div > p {
    color: #584139;
    font-family: Arima;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; /* 115.385% */
    letter-spacing: -0.78px;
  }
}

.ScheduleComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  div > h2 {
    color: #B26336;
    text-align: center;
    font-family: Arima;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 52px; /* 108.333% */
    letter-spacing: -0.63px;
  }

  div > div > div {
    color: #584139;
    font-family: Barnebok;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; /* 115.385% */
    letter-spacing: -0.78px;
  }

  div > div > a > p {
    color: #584139;
    font-family: Arima;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.54px;
  }
}

.schedule  {
  text-align: center;
  padding: 16px;

  * {
    color: #333;
    font-family: Arima;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.54px;
  }
}

@media only screen and (max-width: 660px) {
  .ScheduleComponentWrapper {
    padding-top: 44px;
    padding-bottom: 44px;
  }

  .ScheduleComponent > div:first-child {
    display: none;
  }

  .ScheduleComponent > div:nth-child(2) {
    width: 100%;
    justify-content: center;
    align-items: flex-end;
  }
  //EVENT NAME
  .ScheduleComponent > div:nth-child(2) > div:nth-child(1) {
    display: none;
  }

  .ScheduleComponent > div:nth-child(2) > div:nth-child(3) {
    > h2 {
      text-align: left;
    }
  }

  .ScheduleComponent > div:nth-child(2) > div:nth-child(3) > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div:first-child {
      font-size: 16px;
      font-family: "DidactGothic-Regular";
      font-weight: 400;
      letter-spacing: -0.03em;
      text-align: left;
      width: 112px;
      margin-right: 0px;
      margin-left: 0px;
      padding: 0;
      display: flex;
      height: 38px;
      text-align: left;
    }
    > div:nth-child(2) {
      height: 40px;
    }
  }
  .ScheduleComponent > div:nth-child(2) > div > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div:first-child {
      font-size: 16px;
      font-family: "DidactGothic-Regular";
      font-weight: 400;
      letter-spacing: -0.03em;
      text-align: left;
      width: 112px;
      margin-right: 0px;
      margin-left: 0px;
      padding: 0;
      display: flex;
      height: 38px;
      text-align: left;
    }
    > div:nth-child(2) {
      height: 40px;
    }
  }

  .ScheduleComponent > div:nth-child(2) > div > a {
    margin-left: 126px;
  }
  .ScheduleComponent > div:nth-child(2) > div:nth-child(3) > div:first-child {
    justify-content: center;
    display: flex;
    width: 100%;
    position: relative;
    margin: 0;
  }

  .ScheduleComponent > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div:first-child {
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;
  }
  .ScheduleComponent > div:nth-child(2) > h2 {
    font-size: 37.48px !important;
  }
  .ScheduleComponent > div:nth-child(2) > div > h2 {
    font-size: 28.43px !important;
  }
  .ScheduleComponent > div:nth-child(2) > div > h2 {
    font-size: 28.43px !important;
  }
  .ScheduleComponent > div:nth-child(2) > div > a {
    font-size: 16px !important;
  }
  .ScheduleComponent > div:nth-child(2) > div > div > div {
    font-size: 21.33px !important;
  }
  .ScheduleComponent > div:last-child {
    display: none;
  }
}
