@import "../../../styles/colors";

.chooseWeddingGreetings {
  margin-bottom: 32px;
}

.chooseWeddingToolbar {
  position: sticky;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.chooseWeddingToolbar > a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  padding: 12px 32px;
  font-size: 21.33px;
  line-height: 32px;
  color: #63435b;
}

.chooseWeddingToolbar > a > img {
  width: 16px;
  height: 16px;
}

.chooseWeddingCarousel {
  width: 100%;
  padding: 32px;
  margin-top: 16px;
  background: #fdf6eb;
  border-radius: 32px;
}

.mainClass {
  display: flex;
  flex-direction: column;
  padding: 0 72px;
  padding-bottom: 150px;
  //padding-top: 128px;
  width: 100%;
  height: 100vh;
  overflow-y: scroll !important;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }
}

 //Works for small devices
 @media only screen and (max-width: 1024px) {
  .mainClass {
    padding: 0 32px;
    
  }
}

 //Works for small devices
@media only screen and (max-width: 900px) {
  .mainClass {
    padding: 12px 2px;
    padding-bottom: 40px;
    padding-top: 32px;
  }

  .chooseWeddingCarousel {
    padding: 8px 8px;
    border-radius: 8px;
  }
}
