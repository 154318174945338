@import "../../../styles/colors";

.logo {
  width: 220px !important;
  height: 40px !important;
  position: relative !important;
  margin: 0 auto !important;
  margin-top: 40px !important;
  //left: calc(50vw - 110px) !important;
}

.sticky {
  position: sticky !important;
  top: -40px !important;
  background-color: white;
  z-index: 2;
  margin-right: -10px;
  margin-left: 10px;
}
.tableHeader {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  gap: 32px;
  margin-bottom: -1px;
}
hr {
  background: #281b24;
  margin: 0 0 16px 0 !important;
  border: 1px solid #dad8d9;
}

.selected {
  color: #f78c50;
  border-bottom: 1px solid #f78c50;
}

.buttonSort {
  background-color: transparent;
  border: 1px solid black;
  padding: 12px 8px;
  border-radius: 8px;
}

.wrapper {
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //width: 100vw;
  //height: 100vh;
  //justify-content: center;
  background-color: $brand-background-accent;
  z-index: 1;
  overflow: hidden;

  .bgColor {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: $brand-background-accent;
  }

  .board {
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 60px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    //margin-top: 15%;
    padding: 40px 80px;
    max-width: 1200px;
    //height: 80%;
    width: 90%;
    overflow: auto;

    background: $white;
    border-radius: 16px;
    z-index: 1;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      outline: 1px solid $brand-hover-accent;
      background-color: $brand-hover-accent;
    }

    .header {
      position: relative;
      margin-bottom: 24px;

      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        background: #fdf6eb;
        border-radius: 100px;
        margin-bottom: 16px;
      }
      h2 {
        margin-bottom: 8px;
      }
    }

    .searchBar {
      height: 75px;
      display: flex;
      position: relative;
      //top: 200px;
      //justify-content: center;
      //align-items: center;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.63px;
      flex-direction: row;
      padding: 26px;
      gap: 8px;
      border: 1px solid #dad8d9;
      border-radius: 8px;

      &Active {
        border: 1px solid $brand-operational-accent;
      }

      > input {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 728px;
        border: 0;
        font-size: 16px;
      }
    }

    .tableWrapper {
      width: 100%;
      //overflow-y: scroll;
      //height: 800px;
      //overflow-y: scroll;
      //min-height: 800px;

      @media screen and (max-width: 850px) {
        margin-bottom: 80px;
      }

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }

      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-track {
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb {
        outline: 1px solid $brand-hover-accent;
        background-color: $brand-hover-accent;
      }

      > table {
        table-layout: fixed;
        width: 100%;

        tr {
          height: 60px;
        }

        .trRow {
          z-index: -3;
          padding-right: 16px;
          padding-left: 16px;
          border-radius: 25px;
          @media screen and (max-width: 850px) {
            padding-right: 0px;
            padding-left: 0px;
          }

          &:hover {
            position: inherit;
            background-color: #fdf6eb;
            z-index: -2;
          }
        }

        td {
          &:first-child {
            display: flex;
            align-items: center;
            gap: 16px;
          }
        }

        th {
          &:first-child {
            text-align: left;
          }

          &:last-child {
            text-align: right;
          }
          //width: 25%;
        }

        td {
          &:first-child {
            text-align: left;
          }

          &:last-child {
            text-align: right;
          }
          //width: 25%;
        }

        .empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin-top: 72px;
          left: 0;
          svg {
            margin-bottom: 16px;
          }
          width: calc(280px) !important;
        }
      }
    }
  }
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 5px;
  width: fit-content;
  margin: auto;
  //z-index: -2;
  position: relative;
  background-color: white;
  /* Accents/Gray */

  border: 1px solid #dad8d9;
  border-radius: 50px;

  &Table {
    width: fit-content;
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 850px) {
  .wrapper {
    height: fit-content !important;
    padding: 40px 0px 0px 0px;
    max-width: 100% !important;
    width: 100% !important;
    overflow: unset;
  }
  .board {
    margin-top: 60px !important;
    padding: 0px 0px !important;
    width: 100% !important;
    height: 100% !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .tableWrapper {
    width: 100% !important;
    height: fit-content !important;
    overflow: hidden !important;
    padding: 10px 24px !important;
  }
  tbody {
    width: 100% !important;
  }
  .sticky {
    padding-top: 12px !important;
    width: 100% !important;
    padding: 0px 24px;
    margin: 0 !important;
    z-index: 20;

    .tableHeader {
      flex-direction: row !important;
      width: 100% !important;
      justify-content: start !important;
      h4 {
        width: fit-content !important;
      }
    }
  }
  .empty {
    display: flex;
    width: 100% !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 72px;
    position: relative;
    left: 150%;
    svg {
      margin-bottom: 16px;
    }
  }
  .header > img {
    width: 100px !important;
    height: 100px !important;
  }
  .header {
    padding-top: 46px !important;
  }
}
