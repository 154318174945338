@import "../../../../styles/colors";

.userProfileContainer {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.userImageContainer {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-color: $brand-hover-accent; // default background color
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  overflow: hidden;
  &Bigger {
    width: 56px;
    height: 56px;
  }
  &Smaller {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    object-fit: cover;
  }
}

.userImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.userInitials {
  font-size: 24px;
  font-weight: bold;
}

.userInfo {
  display: flex;
  flex-direction: column;

  .userName {
    display: flex;
    align-items: center;
    gap: 4px;
    text-align: left;
  }

  .userEmail {
    color: $main-operational-accent;
    width: fit-content;
  }
}
