.default-modal {
    display: none;
}
.show-modal {
    background-color: #281B24CC;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 101;
}
.create-post {
    position: absolute;
    bottom: 0;
    width: 100vw;
    z-index: 10;
    background-color: #ffffff;
    box-shadow: 0 6px 24px #0000002e;
    border-radius:8px 8px 0px 0px;
    padding: 24px;
}
.modalProfileContainer {
    display: flex;
    grid-gap: 16px;
    justify-content: space-between;
    align-items: center;
}
.modalProfileContainer p {
    font-size: 16px;
}
.modalCaption {
    padding: 16px 0;
}
.modalCaption > .mentions--multiLine .mentions__input {
    padding: 0;
}
.create-post-profile {
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;

}
.image-post-img{

}

.image-post-div> img{
    height: 300px !important;
    object-fit: contain !important;
}

.image-post-div> p{
    align-self: center;
    justify-self: center;
    z-index: 200;
    color: red;
}
.caption {
    color: #626262;
    border: none;
    overflow: auto hidden;
    resize: none !important;
    width: 100%;
    margin: 16px 0px;
    font-size: 16px;
}
.post-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    border-radius: 8px;
    color: #63435B;
    padding: 12px 32px;
    font-size: 16px;
    font-weight: 400;
    float: right;
    cursor: pointer;
}
.post-btn > img:first-child {
   padding-left: 8px;
    width: 24px;
    height: 24px;
}
.cancel-btn {
    border: none;
    background: #ffffff;
    border-radius: 8px;
    color: #281B24;
    font-family: 'DidactGothic-Regular';
    padding: 8px 0;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}
.image-post-div {
    width: 100%;
    height: auto;
    margin-bottom: 24px;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    text-align: center;
}
.image-post-div > img {
    height: auto;
    max-height: 60%;
    max-width: 100%;
    width: revert;
    object-fit: cover;
}
@media screen and (max-width:450px) {
    .create-post {
        width: calc(100%);
        padding: 8px;
    }
    .modalProfileContainer p {
        font-size: 16px;
    }
}
@media screen and (min-width:1101px) {
    .create-post {
        max-width: 802px;
    }
}
