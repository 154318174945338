@import "../../../../styles/_colors";
@import "../../../../styles/_typography";

.taxonomyWrapper {
  margin: 0 16px 0 0;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 4px;
  //max-width: 200px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }
  div:nth-child(2) {
    position: fixed !important;
  }
}

.muiSelect {
  border: none !important;
}

.label {
  color: $secondary-operational-accent !important;
  font-size: 1em !important;
  letter-spacing: -0.63px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  font-family: "DidactGothic-Regular" !important;
}

.label:focus > * {
  color: $brand-strong-accent !important;
}

.muiSelect > * {
  border: none !important;
}

.muiSelect > svg {
  display: none !important;
}

.menuItem:checked {
  background-color: $brand-operational-accent !important;
}

.menuItem,
.menuItem > * > .Mui-selected {
  background: white !important;
}

.menuItem:hover {
  background-color: $brand-hover-accent !important;
  width: 100% !important;
}

.addTagInput {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  min-width: 32px;
  border: 1px solid $main-operational-accent;
  border-radius: 80px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: 8px;
  cursor: pointer;
}

.addTaxonomy {
  border: 1px solid $main-operational-accent;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 100%;
  width: 32px !important;
  height: 32px !important;
  align-items: center;
}

.addTaxonomy:focus {
  border: none;
  outline: none;
  background: transparent;
}
