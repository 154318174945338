@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/components";

.SelectTemplate {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.SelectTemplate > * {
}

.SelectTemplate > ul {
  margin-top: 32px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
}

.SelectTemplate > ul > li {
  cursor: pointer;
  width: 114px;
  height: 200px;
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  text-align: center;
}

.SelectTemplate > ul > li:hover {
  background: #e5e5e5;
  box-shadow: 0 2px 4px #e5e5e5;
}
.SelectTemplate > ul > li:active {
  background: $brand-background-accent;

  div {
    p {
      font-weight: bolder;
    }
  }
}
.SelectTemplate > ul > li > div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  text-align: center;
}

.SelectTemplate > ul > li > div > img {
  height: 144px;
  width: 114px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SelectTemplate > ul > li > div > p {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12.003px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #281b24;
  padding-top: 16px;
  width: 100%;
}

.Selected {
  border: 1px solid #f9ac80;
}

.SelectedTemplate {
  display: flex;
  padding: 8px;
  justify-content: left;
  align-items: center;
  border: 1px solid #f78c50;
  border-radius: 8px;
  width: 100%;
  max-width: 376px;
}

.SelectedTemplate > div:first-child {
  width: 50%;
  height: 100%;
}

.SelectedTemplate > div:first-child > img {
  width: 100%;
  height: 100%;
  max-width: 114px;
  border-radius: 8px;
  object-fit: fill;
}

.SelectedTemplate > div:nth-child(2) > p:first-child {
  font-style: normal;
  font-weight: 400;
  font-size: 21.328px;
  line-height: 24px;
  letter-spacing: -0.84px;
  color: #63435b;
}

.SelectedTemplate > div:nth-child(2) > p:nth-child(2) {
  font-style: normal;
  font-weight: 400;
  font-size: 21.328px;
  line-height: 32px;
  letter-spacing: -0.84px;
  color: #281b24;
}
