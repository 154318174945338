@import "styles/colors";
@import "styles/typography";
@import "styles/components";

@keyframes showGlobalModal {
  0% {
    transform: translate(492px, 0);
    opacity: 0.7;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes showGlobalModalOpacity {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}


.popUpGlobalModal {
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(70px);
}
.popUpGlobalMobileModal {
  background-color: transparent;
  position: fixed;
  z-index: 1000;
  height: 96px;
  left: 5%;
  top: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(70px);

  .warning {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    background: #FAE28F;
    border-radius: 16px;
    max-width: 487px;
    height: fit-content;
    padding: 24px;

    button {
      border: 0;
      background: transparent;
    }
  }
}
.popUpGlobalModal > div > div > * {
  width: 100%;
  margin-bottom: 8px;
}

.popUpGlobalModal > * > * {
  margin-top: 8px;
  margin-bottom: 8px;
}
.popUpGlobalModal > p {
  padding-top: 32px;
  font-size: 21.33px;
}

.popUpGlobalModalHeading {
  font-size: 37.9px;
  padding-bottom: 32px;
}

.popUpGlobalModal > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //background: $white;
  box-shadow: 0 4px 24px #63435b4d;
  border-radius: 8px 0 0 8px;
  width: 100%;
  height: 100%;
  padding: 20px 20px 20px 20px;
  z-index: 2000000002;
  opacity: 1;
  animation-name: showGlobalModal;
  animation-duration: 0.5s;
}

.popUpGlobalModal > div > button {
  background: $brand-operational-accent;
  border: 1px solid $brand-strong-accent;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 60px;
  margin-right: 20px;
  color: $black;
  font-size: large;

  animation-name: showGlobalModalOpacity;
  animation-duration: 0.5s;
}

.hiddenGlobalModal {
  display: none;
}


.urlPathCode {
  font-family: "Fira Mono", "Andale Mono", "Consolas", monospace;
  letter-spacing: 0px;
  line-height: 24px;
  font-size: 16px;
  //font-feature-settings: none;
  font-variant-ligatures: none;
  position: relative;
  border-style: solid;
  /* border: 1px; */
  border-color: #c1c6a8;
  border-width: 1px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  background-color: #c1c6a829;
}

.pointerClick {
  cursor: pointer;
}