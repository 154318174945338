@import "../../../styles/colors";

.main {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: fit-content;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    border-radius: 20px;
    padding: 0 40px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $brand-hover-accent;
    outline: 1px solid $brand-hover-accent;
    border-radius: 20px;
    width: 1px;
    height: 1px;
  }
}

.header {
  text-align: center;
  margin-bottom: 32px;
  color: $main-strong-accent;
}

.header > p {
  color: $main-strong-accent;
}

.header > h2 {
  font-size: 32px;
}

.logout {
  position: absolute;
  right: 5vw;
  top: 5vh;
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 72px;

  > div {
    width: 100%;
    text-align: left;
    margin-bottom: 32px;

    p {
      margin-bottom: 8px;
    }
  }
}

.form input[type="checkbox"] {
  margin-right: 12px;
  vertical-align: middle;
  background: #ffffff;
  border: 1px solid $secondary-operational-accent;
  border-radius: 4px;
  width: 24px;
  height: 24px;

  :checked {
    background-color: transparent;
  }
}

.form .datePicker {
  min-width: 350px;
  padding: 16px 24px;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 8px;
  text-align: left;

  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 16px 18px;
  background-position: 98% 50%;
  cursor: default;
}

.form .datePicker::placeholder {
  font-family: "DidactGothic-Regular";
  color: $main-operational-accent;
}

.selectSocialEvent {
  flex-direction: row;
}
@media only screen and (max-width: 1030px) {

  .selectSocialEvent {
    flex-direction: column;
    width: 100%;
  }

}
