@import "../../../styles/colors";

.main {
  background: $brand-background-accent;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  //margin-top: 40px;
  margin: auto;
  padding: 56px;
  background: white;
  height: fit-content;
  width: 543px;
  border-radius: 16px;
  z-index: 5;

  @media screen and (max-width: 600px) {
    width: 90vw;
  }
}

.graphics > img:first-child {
  z-index: 4;
  position: fixed;
  left: calc(50vw - 390px);
  top: calc(25vh);
}
.graphics > img:last-child {
  z-index: 4;
  position: fixed;
  left: calc(50vw + 245px);
  top: calc(52vh);
}

.header,
.form {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  //margin-bottom: 72px;

  p {
    color: $secondary-strong-accent;
  }

  input {
    margin-bottom: 32px;
    width: 431px;
    @media screen and (max-width: 600px) {
      width: 58vw;
    }
  }

  p {
    margin-bottom: 32px;
  }
}

//.form > div {
//  margin: 0 0 48px;
//}

.link {
  color: #2c5252;
}

.smallPValues {
  display: flex;
  gap: 24px;
  flex-direction: row;
}

//Tablet Devices
@media only screen and (max-width: 910px) {
  .form {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    //margin-bottom: 72px;

    p {
      color: $secondary-strong-accent;
    }

    input {
      margin-bottom: 32px;
      width: 431px;
      @media screen and (max-width: 600px) {
        width: 58vw;
      }
    }

    p {
      margin-bottom: 32px;
    }
  }
}

//Mobile Devices
@media only screen and (max-width: 600px) {
  .main {
    overflow-y: scroll;
    padding-bottom: 40px;
  }
  .wrapper {
    padding: 48px 24px;
    > form {
      width: 100%;
    }
    > form input {
      width: 100%;
    }
  }
}
