.emailModal {
    padding: 16px;
    padding-bottom: 32px;
    background:white;
    border-radius: 8px 8px 0px 0px;
    animation: slideUp 0.5s ease-in-out;
    position: absolute;
    bottom: 0;
    z-index: 10;
}

.emailModal > div > input {
    border-radius: 4px;
    color:white;
    width: 100%;
    border: none;
}
.emailModal > div > input::placeholder {
    color: white;
}

.buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
}

.buttons > button {
    height: 40px;
    border: none;
    color: #FFFFFF;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 16px;
}
.buttons > .yes {
    background: #f9ac80;
    color: black;

}
.buttons > .no {
    background: transparent;
    color: black;
}