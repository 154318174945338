@import "../ImYours.module.scss";

// DESKTOP
.ImYoursMainComponent {
  position: relative;
  width: 100%;

  .img {
    width: 100%;
    height: 100vh;
    background-size: cover; /* Ensure the image covers the container */
    background-position: center; /* Keep the center of the image in view */
    background-repeat: no-repeat;
  }

  .coupleNameWrapper {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 48px;
    padding-block: 10px;
    border-radius: 160px 160px 0px 0px;
    border: 2px solid #FFF9F6;
    border-bottom: none;
    background: #C1A088;

    @media (max-width: 768px) {
      padding-block: 4px;
      gap: 24px;
    }

    .coupleName {
      color: #FFF9F6;
      font-family: Arima;
      font-size: 50px;
      font-style: normal;
      font-weight: 300;
      line-height: 52px; /* 100% */
      letter-spacing: -5px;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 41.05px;
      }
    }
  }
}
