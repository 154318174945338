@import "../../../../styles/colors";

.toolbox {
  //padding-top: 24px;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  height: 64px;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $accent;
  padding: 8px 0;
  > div {
    display: flex;
    gap: 8px;
    height: 40px;
    &:nth-child(1) {
      min-width: 50%;
      flex-grow: 1;
    }
    &:nth-child(2) {
      flex-grow: 1;
      min-width: 314px;
      justify-content: flex-end;
    }
  }
  &Mobile , &Tablet {
    width: 100% !important;
    display: flex;
    justify-content: center;
    gap:16px;
    height: 64px;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $accent;
    padding: 8px;
    > div:first-child {
      width: 100%;
    }
    > div:nth-child(2) {
      min-width: 140px;
    }
  }
}

.addToolbox {
  > div {
    max-width: 33%;
    display: flex;
    align-items: center;
  }
}

.shareListWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: unset !important;
  padding: 32px;
  gap: 8px;
  width: 464px;

  &Mobile {
    width: 90vw;
    top: 55px;
    left: unset;
    right: 0;
    //margin-left: -70px;
  }
  position: absolute;
  top: 55px;
  right: 55px;
  > * {
    z-index: 6;
  }
  /* Brand Color / Purple 1 */

  background: #63435b;
  border-radius: 8px;
  z-index: 1;

  * {
    color: #fdf6eb;
  }
}

.dropdown {
  position: absolute;
  display: none;
  flex-direction: column;
  gap: 12px;
  background-color: $brand-background-accent;
  border-radius: 8px;
  border: 1px solid $secondary-strong-accent;
  width: 100%;
  padding: 8px 12px;
  z-index: 1000;

  * {
    color: $secondary-strong-accent !important;
  }

  &Active {
    display: flex;
  }

  > &Button {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border: 0;
    padding: 4px 8px;
    background: 0;
    font-size: 16px;
    z-index: 1000;
    &:disabled {
      opacity: 0.4;
    }

    &Active {
      background-color: $brand-operational-accent;
      border-radius: 8px;
    }
  }
}

.outlinedButton,
.shareButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $secondary-strong-accent;
  background-color: $brand-background-accent;
  border-radius: 8px;
  width: 100%;
  height: 56px;
  padding: 16px;

  * {
    color: $secondary-strong-accent !important;
  }
}

.shareButton {
  justify-content: flex-start;
  gap: 8px;
  > h4 {
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > :last-child {
    margin-left: auto;
    border: 1px solid $secondary-strong-accent;
    border-radius: 4px;
    padding: 4px;
  }
}

.highlighted {
  background: $secondary-strong-accent;
  border-radius: 50px;
  button {
    span {
      color: white;
      padding: 0;
    }
  }
}

.isSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $secondary-strong-accent;
  border-radius: 80px;
  span {
    color: $white !important;
  }
}