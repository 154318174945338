.notFound {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 44px;
}

.notFoundImage {

}

.notFoundText {
    &Mobile {
        > h1 {
            font-family: 'DidactGothic-Regular';
            font-size: 52px;
            line-height: 200px;
            font-weight: 800;
        
            letter-spacing: -8px;
            color: #835878;
            margin: 0;
        }
    }
}

.notFoundText > h1 {
    font-family: 'DidactGothic-Regular';
    font-size: 202px;
    line-height: 200px;
    font-weight: 800;

    letter-spacing: -8px;
    color: #835878;
    margin: 0;
}

.notFoundText > h2 {
    font-family: 'DidactGothic-Regular';
    font-size: 50px;
    font-weight: 400;
}

.notFoundText > h4 {
    font-family: 'DidactGothic-Regular';
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -1px;
    text-align: left;
    margin-bottom: 44px;
}

.notFoundText > a {
    background: #F9AC80;
    border: 1px solid #F9AC80;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    width: fit-content;
    padding: 1vw 10vw;
}

.notFoundText > a > .backBtn {

    text-decoration: none;
    font-family: 'DidactGothic-Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 32px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.84px;

    color: #63435B;
}
