@import "../../../../styles/colors";
@import "../../../../styles/typography";
@import "../../../../styles/components";

@keyframes showModal {
  0% {
    transform: translate(492px, 0);
    opacity: 0.7;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes showModalOpacity {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.popUp {
  background-color: transparent;
  width: 650px;
  height: 100vh;
  position: absolute;
  z-index: 200000002;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popUp > * > * {
  margin-top: 8px;
  margin-bottom: 8px;
}
.popUp > p {
  padding-top: 32px;
  font-size: 21.33px;
}

.popUpHeading {
  font-size: 37.9px;
  padding-bottom: 32px;
}

.popUp {

  &Mobile {
    width: 100svw;
    height: 100svh;
    overflow-y: scroll;
  }

  > div {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    background: $white;
    box-shadow: 0 4px 24px #63435b4d;
    border-radius: 8px 0 0 8px;
    width: 650px;
    height: 100%;
    padding: 20px 20px 20px 20px;
    z-index: 2000000002;
    opacity: 1;
    animation-name: showModal;
    animation-duration: 0.5s;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
  
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }
  
    &::-webkit-scrollbar-thumb {
      outline: 1px solid $brand-hover-accent;
      background-color: $brand-hover-accent;
    }
  }
}

  > div {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  background: $white;
  box-shadow: 0 4px 24px #63435b4d;
  border-radius: 8px 0 0 8px;
  width: 650px;
  height: 100%;
  padding: 20px 20px 20px 20px;
  z-index: 2000000002;
  opacity: 1;
  animation-name: showModal;
  animation-duration: 0.5s;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }
}

.popUp > div > button {
  background: $brand-operational-accent;
  border: 1px solid $brand-strong-accent;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 60px !important;
  color: $black;
  font-size: large;

  animation-name: showModalOpacity;
  animation-duration: 0.5s;
}

.table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  /* Line 12 */
  border-top: 1px solid $main-operational-accent;
  margin-top: 24px;
  padding-top: 24px;

  font-family: Didact Gothic;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.6299999952316284px;
}

.toolbox {
  padding-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: -12px;
}

.emptySpace {
  padding-right: 88px;
}

.flatButton > *:first-child {
  padding-right: 4px;
}

.flatButton > *:last-child {
  padding-top: 4px;
}

.flatButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0px;
  font-family: "Didact Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.63px;
  color: $secondary-operational-accent;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}

.table > tr {
  text-align: left;
  height: 78px;
}

.table > tr:nth-child(1) {
  text-align: left;
  height: 40px;
}

.table > td {
  display: flex;
  align-items: center;
}

.table > tr > td:nth-child(2) {
  text-align: left;
}

.exitButton {
  position: relative;
  left: 0px;
  bottom: 5px;
}

.inputForm {
  margin-bottom: 32px;
  min-width: 235px;
  width: 90%;
  padding: 16px 24px;
  border: 1px solid #d0d0d0;
  border-radius: 8px;
  color: $main-strong-accent;
  font-size: 16px;
  font-weight: 400;
}

.customCheckbox {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.hidden {
  display: none;
}

.hidden * {
  display: none;
}

.hidden > * {
  display: none;
}

.pointer {
  cursor: pointer;
}

.active {
  background: #f8eae9;
}

.fullWidth {
  width: 100% !important;
}

.disabled > svg {
}

.saveGuestButton {
  font-weight: 400;
  font-size: 21.328px;
  line-height: 32px;
  height: 60px !important;
}

.importedGuests {
  .guest {
    display: flex;
    gap: 8px;
    margin-bottom: 4px;
    padding: 8px;
    &Error {
      background-color: $error-state;
      border-radius: 8px;
    }
  }
}

.dragNDrop {
  width: 472px;
  cursor: copy;

  > .dropzone:active {
    border: dotted 1px #00C9A5;
  }

  .highlighted {
    color: $secondary-hover-accent;
  }

  &PathHighlighted {
    path {
      fill: #00C9A5 !important;
    }
  }
}

.dragNDrop .dropzone {
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 350px;
  padding: 24px;
  border: 1px solid $main-operational-accent;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 8px;
  color: $main-strong-accent;
}
