@import "../../../styles/colors";

.WebsiteDetails {
  padding-top: 20px;
  padding-right: 40px;
  display: flex;
  justify-content: left;
  height: 100vh;
  max-height: 100vh;
  width: 100%;

  .customButton {
    padding: 12px 32px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 56px;
  }
  & ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  & ::-webkit-scrollbar-thumb,
  & ::-webkit-scrollbar-track {
    border-radius: 20px;
  }
}

.WebsiteDetails > div:first-child {
  padding-top: 20px;
  width: 472px;
  overflow-y: scroll;
  padding-right: 40px;
}

.WebsiteDetails > div:last-child {
  padding-top: 12px;
  width: calc(100% - 472px);
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  & ::-webkit-scrollbar-thumb,
  & ::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  // !TODO: Do we actually want this scrollbar to show?
  & ::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }
}

.WebsiteDetails > div:last-child > div:first-child {
  padding-bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.disabled {
  background-color: #eee;
  cursor: not-allowed !important;
}

.published {
  background-color: #c1c7a9 !important;
  width: 220px;

  &:hover {
    background-color: #63435b !important;
    color: #fdf6eb;

    img {
      filter: invert(1) hue-rotate(150deg);
    }
  }
}

.unpublish {
  background-color: #63435b !important;
  color: #fdf6eb;
  width: 220px;
  justify-content: center;
  display: flex;
  align-items: center;
  img {
    filter: invert(1) hue-rotate(-150deg);
  }
  &:hover {
    background-color: #c1c7a9 !important;
    color: black;

    img {
      filter: invert(0) hue-rotate(150deg);
      fill: #63435b;
    }
  }
}

.viewWebsite {
  background-color: transparent !important;
  border: 1px solid #63435b !important;
  width: 203px;
}
