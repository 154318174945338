.OurStoryComponent{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  padding: 188px 24px; 
  background-color: #FFF; 
  gap: 15%;

  @media only screen and (max-width: 1366px) {
    gap: 0;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 91px 16px;
  }

  @media screen and (max-width: 525px) {
    padding: 88px 24px;
  
    > img {
      width: auto;
      margin-left: -30%;
      overflow: hidden;

      @media screen and (max-width: 413px) {
        margin-left: -40%;
     }
    }
  }
} 

.OurStoryComponent > div {
  max-width: 621px; 
  margin: 16px 0;
  text-wrap: wrap;
  color: #000;
  font-style: normal;

  h3 {
    font-family: Savoye;
    font-size: 88px;
    font-weight: 400 !important;
    line-height: normal;
  }

  p {
    font-family: Cera;
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    word-wrap: break-word; 
  }
}
