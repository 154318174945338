@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/components";

.header > img {
  height: 32px;
}

.profile-page {
  width: 100%;
  max-width: 520px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 40px;
  grid-gap: 16px;
  margin: 0 auto;
  margin-bottom: 56px;
  height: auto;
}

.privacy {
  font-size: 21.33px !important;
  line-height: 32px;
  letter-spacing: -0.84px;
  font-weight: 400;
  padding: 0;
}

.breakline {
  border-top: 1px solid #dad8d9;
  padding: 0;
}

.logoutBtn {
  background: #f9ac80;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  width: fit-content;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.63px;
  margin-top: 16px;
  height: auto !important;
  grid-gap: 8px;
}

.title-container {
  background: #e5e5e5;
  width: 100vw;
  padding: 112px 40px 16px;
  text-align: center;
}

.title-container > h2 {
  color: #281b24;
  font-size: 28.43px;
  line-height: 40px;
  letter-spacing: -1.12px;
}

.profile-container {
  margin-bottom: 24px;
  align-self: center;
}

.profile-container p {
  font-size: 24px;
}
.profile-container button {
  white-space: normal;
  font-size: 24px;
  padding: 12px 24px;
  appearance: none;
  background: none;
  border: none;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.profile-container > button > p {
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 0;
}

.floatingInputContainer {
  position: relative;
  padding: 0px 16px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 8px;
}
.floatingInputPlaceholder {
  position: absolute;
  top: 50%;
  transform: translate(5px, -50%);
  pointer-events: none;
  transition: all 0.14s ease-in-out;
  font-size: 24px;
  color: #000000;
}
.floatingInputNewLabel {
  position: absolute;
  top: -10px;
  background: $white;
  font-size: 12px;
  font-weight: 400;
  color: #63435b;
  line-height: 16px;
  /* identical to box height, or 133% */
  letter-spacing: -0.0047px;
}
.floatingInputEdit {
  position: absolute;
  right: 0;
  margin-right: 8px;
  top: 30%;
}
.floatingInputEdit > * {
  background: transparent;
  border: none;
}
.floatingInputEdit > *:nth-child(1) {
  margin-right: 8px;
}
.floatingInputContainer input[type="text"].field-input {
  color: #000000;
  border: none;
  padding: 0;
  margin-top: 0px;
  font-size: 16px !important;
  display: block;
  box-sizing: border-box;
  width: 100%;
  bottom: 0px;
  height: 56px;
}
.floatingInputContainer input[type="text"].field-input.c-fix,
input[type="text"].field-input:focus,
input[type="text"].field-input:not(:placeholder-shown) {
  background-color: transparent;
}
.floatingInputContainer input[type="text"].field-input.c-fix ~ label,
input[type="text"].field-input:focus ~ label,
input[type="text"].field-input:not(:placeholder-shown) ~ label {
  color: #646669;
  font-size: 16px;
  left: 27px;
}
.floatingInputContainer input[type="text"].field-input::-webkit-input-placeholder {
  color: transparent;
}
.floatingInputContainer input[type="text"].field-input::-moz-placeholder {
  color: transparent;
}
.floatingInputContainer input[type="text"].field-input:-ms-input-placeholder {
  color: transparent;
}

.saveBtn {
  width: 100%;
  text-align: center;
  background: #3f3d56;
  color: white;
  border-radius: 12px;
  font-size: 24px;
  padding: 16px;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  border: none;
  margin-bottom: 32px;
}

.saveBtn:focus {
  outline: none;
}

.error {
  border: 1px solid red;
}

.profileSocial {
  display: flex;
  align-items: center;
  justify-content: left;
  grid-gap: 8px;
}

@media screen and (max-width: 450px) {
  .profile-container button {
    font-size: 20px;
    padding: 12px 24px;
  }
  .floatingInputPlaceholder {
    font-size: 20px;
  }
  .floatingInputContainer input[type="text"].field-input {
    font-size: 16px !important;
  }
  .floatingInputContainer input[type="text"].field-input.c-fix ~ label,
  input[type="text"].field-input:focus ~ label,
  input[type="text"].field-input:not(:placeholder-shown) ~ label {
    font-size: 12px;
    left: 18px;
  }
}
@media screen and (max-width: 360px) {
  .profile-page {
    padding: 0 24px;
  }
}
@media screen and (min-width: 1101px) {
  .profile-page {
    margin: 0 auto;
  }
}

.learnMoreLink {
  color: black;
  text-decoration: none;
  text-underline-offset: 4px;
}
