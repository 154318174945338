@import "../ImYours.module.scss";

.ImYoursQNAComponent {
  position: relative;
  background: #FFF9F6;;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;

   > div {
      &:first-child {
        padding: 91px 32px;
      }
   }

   > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin: 0 auto;
    margin-bottom: 40px;
   }

  @media only screen and (max-width: 640px) {
    flex-direction: column;
    padding: 91px 0px;
  }
}

.panel > div {
  color: #584139;
  font-family: Barnebok;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 40px;
}

.panel > * > * {
  font-family: Barnebok !important;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.6299999952316284px;
  text-align: left;
  color: #584139;
}

.ImYoursQNAComponent > div {
  flex-grow: 1;
}

.ImYoursQNAComponent > div > div > h2 {
  color: #584139;
  text-align: center;
  font-family: Arima;
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: 52px; /* 108.333% */
  letter-spacing: -0.63px;
}

.question {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 880px;
}

.ImYoursQNAComponent > div > div > button {
  border: none;
  background: transparent;
  justify-self: flex-start;
  align-self: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 200px;
  color: #584139;
  text-align: justify;
  font-family: Barnebok;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.54px;
  margin-bottom: 35px;
}
 
.ImYoursQNAComponent > div > div > div {
  width: 100%;
}

.ImYoursQNAComponent > div > div > div > p {
  padding-top: 24px;
  width: 100%;
  color: #584139;
  font-size: 16px !important;
  font-family: Barnebok;
  text-align: start;
}

.bottomElement {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 40px 120px;
  border-radius: 160px 160px 0px 0px;
  border-right: 2px solid #FFF9F6;
  border-left: 2px solid #FFF9F6;
  background: #C1A088;
}
