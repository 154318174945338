@font-face {
  font-family: "Catamount";
  src: url("../../../fonts/CatamountSignature.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Noto";
  src: url("../../../fonts/NotoSerifDisplay.ttf") format("opentype");
}

//DESKTOP
.CallMeByYourNameMainComponent {
  justify-self: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 500px;
  padding-top: 0;
  background: #fdf6eb;
  > * {
    font-family: Catamount !important;
  }
}

.CallMeByYourNameMainComponent > *:last-child {
  color: #fdf6eb !important;
  font-size: 70px !important;
  letter-spacing: -0.84px !important;
  font-weight: 400 !important;
  word-break: break-word;
  text-align: center;
  font-family: Catamount !important;
}

.typographyLogo {
  color: #FFF097 !important;
  font-weight: 400 !important;
  //line-height: 40px !important;
  font-size: 200px;
  font-family: Catamount !important;
}

//MOBILE
@media screen and (max-width: 768px) {
  .typographyLogo {
    font-size: 56px !important;
    line-height: 73px;
  }
  .flowers {
    opacity: 0;
  }
}

//SMALL MOBILE
@media screen and (max-width: 525px) {
  .typographyLogo {
    font-size: 0.7em !important;
    line-height: 73px;
  }
}
