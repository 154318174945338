@import "../../../styles/colors";

.main {
  display: flex;
  width: 100%; 
  overflow: hidden;
  hr {
    width: calc(100% - 40px);
  }

  .page {
    display: flex;
    flex-direction: column;
    padding: 32px 0;
    width: 100%;
    gap: 16px;
    overflow-y: scroll;
    height: 100vh; 
     &Tablet {
      height: calc(100vh - 88px); 
      overflow-y: scroll;
     }
     &Mobile {
      height: calc(100vh - 64px); 
      overflow-y: scroll;
     }
    .emailTemplates {
      background-color: $brand-background-accent;
      width: 100%;
      margin-bottom: 40px;
      max-height: 352px;
      border-radius: 32px 0px 0px 32px;

      > div {
        width: 100%;
        display: flex;
        gap: 32px;
        padding: 64px 40px 40px;
        overflow-x: scroll;
        white-space: nowrap;
        &::-webkit-scrollbar {
          height: 4px;
          width: 4px;
          border-radius: 20px;
          padding: 0 40px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $brand-hover-accent;
          outline: 1px solid $brand-hover-accent;
          border-radius: 20px;
          width: 1px;
          height: 1px;
        }

        .template {
          border: 0;
          min-height: 248px;
          word-break: break-all;
          background-color: white;
          padding: 72px 32px;
          border-radius: 16px;
          min-width: 304px;
          max-width: 304px;
          cursor: pointer;
          filter: drop-shadow(0px 2px 4px rgb(40 27 36 / 37%));

          &:hover {
            //box-shadow: 0 0 4px #00000063;
            //-moz-box-shadow: 0 0 4px #00000063;
            //-webkit-box-shadow: 0 0 4px #00000063;
            //filter: drop-shadow(2px 2px #00000063);
            border: solid 1px #00000063;
            //filter: drop-shadow(0px 0px 0px transparent);
          }

          &Icon {
            width: 48px;
            height: 48px;
            position: sticky;
            border-radius: 8px;
            margin-top: -96px;

            img {
              padding: 8px;
            }
          }

          > p {
            margin-top: 16px;
          }
        }

        .pulse {
          animation: pulse 2s infinite;

          &Purple {
            box-shadow: 0 0 0 0 rgb(199, 173, 193);
          }

          &Green {
            box-shadow: 0 0 0 0 rgb(193, 198, 168);
          }
        }

        @keyframes pulse {
          0% {
            // box-shadow: 0 0 0 0 rgb(199, 173, 193);
          }
          5% {
            transform: scale(1.01);
          }
          15% {
            transform: scale(1);
          }
          70% {
            box-shadow: 0 0 0 25px rgb(199, 173, 193, 0);
          }
          100% {
            box-shadow: 0 0 0 100px rgb(199, 173, 193, 0);
          }
        }
      }
    }

    .warning {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 24px;
      gap: 16px;
      background: #FAE28F;
      border-radius: 16px;
      max-width: 487px;
      height: fit-content;

      button {
        border: 0;
        background: transparent;
      }
    }

    .rsvp { 
      display: flex;
      gap: 16px;
      margin-bottom: 40px;
      margin-right: 40px;
      &Visitors {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: calc(100% - 16px);
        max-width: 352px;
        max-height: 720px;
        background-color: $brand-background-accent;
        border-radius: 32px;
        padding: 32px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          height: 4px;
          width: 4px;
          border-radius: 20px;
          padding: 0 40px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $brand-hover-accent;
          outline: 1px solid $brand-hover-accent;
          border-radius: 20px;
          width: 1px;
          height: 1px;
        }

        .searchBar {
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.63px;
          flex-direction: row;
          padding: 16px;
          gap: 8px;
          border: 1px solid #dad8d9;
          border-radius: 8px;
          background-color: white;

          > input {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 728px;
            border: 0;
            font-size: 16px;
          }

          &Active {
            border: 1px solid $brand-operational-accent;
          }
        }

        .guests {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
          }

          .guest {
            display: flex;
            align-items: center;
            gap: 24px;
            padding: 4px;
            cursor: pointer;
            &Active {
              background-color: $brand-hover-accent;
              border-radius: 8px;
            }
          }
        }
      }

      &Results {
        width: 80%;
        background-color: $main-background-accent;
        max-height: 720px;
        min-height: 720px;
        max-width: 1376px;
        border-radius: 32px;
        padding: 32px 24px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          height: 4px;
          width: 4px;
          border-radius: 20px;
          padding: 0 40px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $brand-hover-accent;
          outline: 1px solid $brand-hover-accent;
          border-radius: 20px;
          width: 1px;
          height: 1px;
        }

        &Empty {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          align-items: center;
          text-align: center;
          gap: 16px;
          h4 {
            max-width: 457px;
          }
        }
      }
    }
  }

  .preview {
    background: $main-background-accent;
    padding-top: 100px;
    width: 45%;
    height: 100%;
    .highlighted {
      color: #835878;
    }

    .btns {
      width: 24px;
    }

    .view {
      display: flex;
      flex-direction: column;
      background: $brand-background-accent;
      border-radius: 32px 32px 0 0;
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      height: 92vh;
      overflow-y: scroll;
      overflow-wrap: anywhere;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    width: 55%;
    padding: 32px 40px;
    overflow-y: scroll;
    height: 100vh;

    header {
      h1 {
        margin: 0;
      }

      img {
        width: 24px;
      }
    }

    .step {
      padding-top: 40px;

      .flexRow {
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-bottom: 8px;

        svg {
          margin-top: 8px;
        }
      }

      textarea {
        margin-top: 16px;
        margin-bottom: 8px;
      }

      button {
        width: fit-content;
        margin-top: 32px;
        padding: 12px 32px;
      }
    }
  }
}

hr {
  width: 100%;
  margin: 32px 0;
  color: $main-operational-accent;
}

.dark {
  p {
    color: $secondary-strong-accent;
  }

  path {
    fill: $secondary-strong-accent !important;
  }
}

.white {
  p {
    color: white;
  }

  path {
    fill: white !important;
  }
}

.flex {
  display: flex;
  gap: 16px;
  align-items: center;

  &SpaceBetween {
    justify-content: space-between;
  }
}

.relative {
  position: relative;
}

.absoluteRight {
  position: absolute;
  right: 0;
}

.flatButton {
  align-self: center;
  width: 100%;
  max-width: 1376px;
  > button {
    margin-left: auto;
  }
}
