.btn {
  padding: 12px 32px;
  font-size: 1.333em;
  letter-spacing: -0.84px;
  font-weight: 400;
  font-family: "DidactGothic-Regular";
  line-height: 32px;
  border-radius: 8px;
  color: $secondary-strong-accent;
  width: 100%;
  &Main {
    background-color: $brand-operational-accent;
    border: 1px solid $brand-operational-accent;
    &WithImg {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }
    &:hover,
    &:active {
      background-color: $secondary-strong-accent;
      border: 1px solid $secondary-strong-accent;
      color: $brand-background-accent;
    }
    &:disabled,
    &Disabled {
      background-color: $brand-background-accent !important;
      border: 1px solid $brand-background-accent !important;
      color: $secondary-hover-accent !important;
      > svg {
        background-color: $brand-background-accent !important;
        color: $secondary-hover-accent !important;
        fill: $secondary-hover-accent !important;
      }
    }
  }
  &Main2nd {
    background-color: $brand-hover-accent;
    border: 1px solid $brand-hover-accent;
    &WithImg {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }
    &:hover,
    &:active {
      background-color: transparent;
      border: 1px solid transparent;
      color: $secondary-strong-accent;
    }
    &:disabled {
      background-color: $secondary-hover-accent;
      border: 1px solid $secondary-hover-accent;
      color: $secondary-strong-accent;
    }
  }
  &Main3rd {
    background-color: white;
    border: 1px solid $brand-operational-accent;
    color: $brand-operational-accent;
    &WithImg {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }
    &:hover,
    &:active {
      background-color: $secondary-strong-accent;
      border: 1px solid $secondary-strong-accent;
      color: $brand-background-accent;
    }
    &:disabled {
      background-color: $brand-background-accent;
      border: 1px solid $brand-background-accent;
      color: $secondary-hover-accent;
    }
  }
  &Main4th {
    background-color: white;
    border: 1px solid $secondary-strong-accent;
    color: $secondary-strong-accent;
    &WithImg {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }
    &:hover,
    &:active,
    &Active {
      background-color: $secondary-strong-accent;
      border: 1px solid $secondary-strong-accent;
      color: $brand-background-accent;
    }
    &:disabled {
      background-color: $brand-background-accent;
      border: 1px solid $brand-background-accent;
      color: $secondary-hover-accent;
    }
  }

  &MainTutorial {
    background-color: $brand-operational-accent;
    border: 1px solid $brand-operational-accent;
    width: 150px;
    &WithImg {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }
    &:hover,
    &:active {
      background-color: $secondary-strong-accent;
      border: 1px solid $brand-operational-accent;
      color: $brand-background-accent;
    }
    &:disabled,
    &Disabled {
      background-color: $brand-background-accent !important;
      border: 1px solid $brand-background-accent !important;
      color: $secondary-hover-accent !important;
      > svg {
        background-color: $brand-background-accent !important;
        color: $secondary-hover-accent !important;
        fill: $secondary-hover-accent !important;
      }
    }
  }
  &Rounded {
    border-radius: 30px;
  }
  &IconSecondary {
    background-color: transparent;
    border: 1px solid $secondary-strong-accent;
    &:hover,
    &:active {
      background-color: $secondary-strong-accent;
      border: 1px solid $secondary-strong-accent;
      color: $brand-background-accent;
    }
    &:disabled {
      background-color: $brand-background-accent;
      border: 1px solid $brand-background-accent;
      color: $secondary-hover-accent;
    }
    &Medium {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      max-width: fit-content;
    }
  }
  &Secondary {
    background-color: transparent;
    border: 0;
    &WithImg {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      font-size: 1em;
    }

    &WithHoverEffect {
      &:hover,
      &:active {
        background-color: $secondary-strong-accent;
        border: 1px solid $secondary-strong-accent;
        color: $brand-background-accent;
      }
    }
    &:disabled {
      background-color: $brand-background-accent;
      border: 1px solid $brand-background-accent;
      color: $secondary-hover-accent;
    }
  }
  &SecondaryTutorial {
    background-color: $secondary-strong-accent;
    border: 1px solid $brand-background-accent;
    width: 150px;
    color: $brand-background-accent;
    &WithImg {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      font-size: 1em;
    }

    &WithHoverEffect {
      &:hover,
      &:active {
        background-color: $secondary-strong-accent;
        border: 1px solid $brand-operational-accent;
        color: $brand-background-accent;
      }
    }
    &:disabled {
      background-color: $brand-background-accent;
      border: 1px solid $brand-background-accent;
      color: $secondary-hover-accent;
    }
  }

  &Action {
    width: 64px;
    color: #ffffff;
    background-color: $secondary-strong-accent;
    &:disabled {
      background-color: $secondary-hover-accent;
      border: 1px solid $secondary-hover-accent;
      color: $secondary-strong-accent;
      cursor: not-allowed;
    }
  }

  &Medium {
    gap: 8px;
    min-width: 134px;
    font-size: 16px;
    border-radius: 30px;
    padding: 8px 16px;
    border: 0;
    height: 40px;
    line-height: 24px;
  }

  &Small {
    width: fit-content;
    font-size: 16px;
    border-radius: 30px;
    padding: 8px 16px;
    border: 0;
    height: 40px;
    line-height: 24px;
  }

  &Sm {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    padding: 4px !important;
    background: transparent;
  }

  &XSmall {
    height: 24px;
    width: 24px;
    background-color: transparent;
    padding: 3px !important;;
  }

  &Simple {
    width: fit-content;
    padding: 16px !important;;
    border-radius: 8px;
    &:hover, &:active {
        background-color: $secondary-strong-accent;
        border: 1px solid $secondary-strong-accent;
        color: $brand-background-accent;
      }
    &:disabled {
      opacity: .4;

    }
  }

  &Bordered {
    border: 1px solid $main-strong-accent;
    border-radius: 4px;
    &Round {
      border-radius: 50px;
    }
  }

  &Borderless {
    border: none;
    padding: 0;
  }

  &Link {
    border: 0;
    background-color: transparent;
    text-decoration: underline;
  }

  &Plain {
    background: none;
    border: none;
  }

  &Flat{
    display: flex;
    height: 32px;
    width: fit-content;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0px 16px;
    background-color: transparent;
    border: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.63px;
    color: $secondary-operational-accent;
    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
    > input[type="file"] {
      display: none;
    }
    > span {
      white-space: nowrap;
    }
    &:disabled {
      span {
        color: #c7adc1 !important;
      }
      cursor: not-allowed;
    }
    @media screen and (max-width: 1024px ){
      margin-left: -5px;
    }
  }
}

.input {
  width: 332px;
  padding: 16px;
  border: 1px solid $main-operational-accent;
  border-radius: 8px;
  color: $main-strong-accent;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  &Small {
    width: 204px;
    padding: 8px;
  }


  &Medium {
    width: 416px;
    padding: 8px 16px;
  }

  &Large {
    width: 504px;
  }

  &NoBorder {
    border: 0;
  }

  &::placeholder {
    color: $main-operational-accent;
  }
}

.inputTimePicker {
  width: 120px;
  border: 0;
  padding: 16px 24px;
  min-width: 0;
  color: $main-strong-accent;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  fieldset,
  div {
    border-radius: 8px !important;
    padding: 0 10px 0 0;
  }
  button {
    padding: 0 5px 0 0;
    &:hover,
    &active {
      background: transparent;
    }
  }
  svg {
    width: 16px;
  }

  &::placeholder {
    color: $main-operational-accent;
  }
}

.textarea {
  width: 472px;
  padding: 16px;
  border: 1px solid $main-operational-accent;
  border-radius: 8px;
  color: $main-strong-accent;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  &::placeholder {
    color: $main-operational-accent;
  }
}

.checkbox {
  border: 1px solid #a9a4a7;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  &Smaller {
    width: 16px;
    height: 16px;
  }
}


//mobile
@media only screen and (max-width: 768px) {
  .btn {
    font-size: 1em;
    padding: 8px 20px;
  }
  .btnMain3rd{
    padding: 4px 2px;
   }
}