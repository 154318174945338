.ChooseTemplate {
  display: flex;
  justify-content: left;
  height: 100vh;
  max-height: 100vh;
  width: 100%;

  .customButton {
    padding: 12px 32px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 56px;
    justify-content: center;
  }
}

.ChooseTemplate > div:first-child {
  padding: 32px;
  width: 30%;
  max-width: 496px;
  min-width: 496px;
  max-height: 100vh;
  overflow-y: scroll;
}
.ChooseTemplate > div:first-child > p {
  max-width: 376px;
}

.ChooseTemplate > div:first-child ::-webkit-scrollbar {
  height: 12px;
}

.ChooseTemplate > div:first-child ::-webkit-scrollbar-thumb,
.ChooseTemplate > div:first-child ::-webkit-scrollbar-track {
  border-radius: 92px;
}

.ChooseTemplate > div:first-child ::-webkit-scrollbar-thumb {
  background: var(--accent);
}

.ChooseTemplate > div:first-child ::-webkit-scrollbar-track {
  background: #ffb2862b;
}

.ChooseTemplate > div:first-child > * {
  margin-top: 32px;
}

.ChooseTemplate > div:last-child {
  width: 100%;
  height: 100%;
  background: #f4f3f4;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 100vh;
}
.ChooseTemplate > div:last-child > div:first-child {
  display: flex;
  justify-self: right;
  align-self: flex-end;
  padding: 40px;
}

.ChooseTemplate > div:last-child > div:first-child > button {
  //width: 195px;
  //height: 56px;
  border-radius: 8px;
}

.ChooseTemplate > div:last-child > div:first-child > button:first-child {
  margin-right: 16px;
}
