@import "../ImYours.module.scss";

.ImYoursGiftComponent {
  position: relative;
  background-color: #C1A088;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-left: 2px solid #FFF9F6;
  border-right: 2px solid #FFF9F6;


  > img {
    position: absolute;

    &:first-child {
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      bottom: 0;
      right: 0;
      transform: rotate(180deg);
    }
  }

  .Inside {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 72px;
    gap: 72px;

    &Graphic {
      width: 100%;
      max-width: 250px;
      height: 100%;
      max-height: 250px;
    }

    &Info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      h2 {
        color: #FFF9F6;
        text-align: center;
        font-family: Arima;
        font-size: 48px;
        font-style: normal;
        font-weight: 300;
        line-height: 52px; /* 108.333% */
        letter-spacing: -0.63px;
      }
      p {
        max-width: 400px;
        color: #FFF9F6;
        text-align: justify;
        font-family: Barnebok;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.54px;
      }
    }
  }

  .giftButton {
    width: 100%;
    max-width: 315px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px 32px;
    background: #B26336;
    color: #ffff;
    border: 1px solid;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.3s;;
    margin: 0 auto;
  }

  .giftModal {
    display: flex;
    position: absolute;
    top: 60px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background: #584139;
    border: transparent;
    border-radius: 8px;
    box-shadow: 0 3px 20px rgba(144, 154, 139, 0.05);

    @media screen and (max-width: 900px) {
      max-width: 366px;
      left: 0;
      top: 63px;
    }
    * {
      color: #fff !important;
    }

    &Details {
      display: flex;
      gap: 16px;
      width: 100%;
      > button {
        margin-left: auto;
      }
    }
  }
}

.center {
  * {
    margin: auto;
  }
}

