.input {
  position: relative;
  padding: 0;
  margin: 0;
  width: fit-content;
  margin-bottom: 16px;
}

.map {
  height: 332px;
  max-width: 472px;
  width: 100%;

  div {
    border-radius: 8px !important;

    .marker {
      display: inline-flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      border-radius: 100% !important;
      transform: translate(-50%, -50%);
    }
  }
}