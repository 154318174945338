@import "../../../../styles/_colors";

@keyframes deleteTaxonomyAnimation {
  0% {
    opacity: 0;
    margin-top: 10;
  }

  100% {
    opacity: 1;
    margin-top: -4px;
  }
}

.taxonomyWrapperAnimation:hover > button:last-child {
  display: block;
  animation: 0.2s ease-in deleteTaxonomyAnimation;
}

.taxonomyWrapper:hover > button:last-child {
  display: block;
}
.taxonomyWrapper > button:last-child {
  display: none;
}

.taxonomyWrapper > button:last-child:hover ~ .taxonomyWrapper > button:first-child {
  background: $error-state !important;
  color: $white !important;
}

.taxonomyInput {
  width: 50%;
  justify-self: center;
  align-self: center;
  margin-right: 2px;
}

.taxonomy > li {
  align-self: center;
}

.taxonomyList > li > * {
  list-style: none;
}

.addTaxonomy {
  border: 1px solid $main-operational-accent;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  align-items: center;
}

.addTaxonomy:focus {
  border: none;
  outline: none;
  background: transparent;
}

.isSelectable {
  cursor: pointer;
}

.taxonomy {
  height: 32px;
  white-space: nowrap;
  padding: 8px 16px;
  margin-right: 2px;
  border-radius: 30px;
  border: 1px solid #a9a4a7;
  background: $white;
  width: fit-content;
  font-size: 16px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.isSelected {
  background: #f9ac80;
  color: #835878;
  border: 1px solid #f9ac80;
}

.isNew {
  background: #c1c6a8;
  color: #835878;
  border: 1px solid #c1c6a8;
}

.CancelButton {
  display: flex !important;
  justify-content: center !important;
  border: none !important;
  border-radius: 8px !important;
  background: $error-state !important;
  color: $brand-background-accent !important;
}

.CancelButton:hover {
  background: $error-state !important;
  color: $brand-background-accent !important;
}
.AddButton {
  display: flex !important;
  justify-content: center !important;
  border: none !important;
  border-radius: 8px !important;
  background: $brand-strong-accent !important;
  color: $secondary-strong-accent !important;
}

.AddButton:hover {
  background-color: $brand-hover-accent !important;
  color: $secondary-strong-accent !important;
}
