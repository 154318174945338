/* Likes Modal ===================================== */

.lovesModal{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100vw;
    z-index: 100;
    background: #281B24CC;
}
.lovesModal > div:last-child {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40%;
    width: 100%;
    z-index: 100;
    background: white;
    border-radius: 8px 8px 0px 0px;
    animation: slideUp 0.5s;
}
.lovesModal > div {
    animation: slideUp 0.5s;
}

@keyframes slideUp {
    from {
        opacity: 0.5;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity : 1;
        transform: translate3d(0, 0, 0);
    }
}

.likesModal p{
    margin: 16px 0;
}
.likesModalHead {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 12px;
    padding: 12px;
    margin: 0 12px;
    border-bottom: 1px solid;
}
.likesModalHead img {
    width: 32px;
    filter: brightness(0);
}
.likesModalPeople {
    text-align: start;
    width: 100vw;
    overflow-y: scroll;
    padding-left: 16px;
}
.likesProfile {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 16px;
    padding-right: 32px;
}
.likesProfile img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}