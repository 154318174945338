@import "../../styles/colors";
.wrapper {
    > div {
        position: relative;
    }
}
input::placeholder {
    font-family: 'DidactGothic-Regular';
}

.datePicker {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 16px 18px;
    background-position: 95% 50%;
    cursor: pointer;
    &:disabled {
        background-color: $brand-background-accent;
    }
}

.calendar {
    &Selected {
        color: $main-strong-accent!important;
        background-color: $brand-hover-accent!important;
        border-radius: 0!important;
        &::after {
            visibility: hidden; /* hide small border under the text */
        }
    }
    &Today {
        color: $main-strong-accent!important;
        background-color: $brand-strong-accent!important;
        border-radius: 0!important;
        &::after {
            visibility: hidden; /* hide small border under the text */
        }
    }
}
