@import "../ImYours.module.scss";

.ImYoursAppPromoteComponent {
  width: 100%;
  height: 100vh !important;
  max-height: 1099px;
  min-height: 500px !important;
  background-color: #FFF9F6;;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 91px 84px;

  @media screen and (max-width: 1024px) {
    justify-content: center;
  }

  h2,h3,p {
    color: #584139;
  }

  h2 {
    font-family: Barnebok;
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 39px; /* 111.429% */
    letter-spacing: -1.05px;
  }

  h3 {
    text-align: center;
    font-family: Arima;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 52px; /* 108.333% */
    letter-spacing: -0.63px;
  }

  p {
    text-align: justify;
    font-family: Barnebok;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.54px;
  }

  > img {
    &:first-child {
      transform: scaleX(-1);
      transform: rotate(180deg);
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  > div {
    display: flex;
    max-width: 1220px;
    position: relative;
    justify-content: center;
    gap: 40px;
    padding: 40px; 
    border-radius: 56px;
    > div {
      display: flex;
      width: 100%;
      max-width: 400px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
      padding: 16px;

      &:first-child {
        width: 50%;
      }

      > a {
        max-width: 300px;
        background-color: #B26336;
        border-color: transparent;
        border-radius: 16px;

        &:hover {
          background-color: #FFF;
          color: #B26336;
          border-color: #B26336;
        }
      }

      > img {
        width: 100%;
        max-width: 582px;
      }
    }
  }
}

.ImYoursAppPromoteComponentImage {
  background-size: 450px;
  background-position: center;
  background-repeat: no-repeat;
}

.btn {
  background-color: #333;
  color: #FFF;
  border: 1px solid #333;
  &:hover {
    background-color: #FFF;
    color: #333;
  }
}

//mobile
@media only screen and (max-width: 1600px) {
  .ImYoursAppPromoteComponent {
    height: unset !important;
    max-height: unset !important;
    padding: 91px 16px;
    > div {
      align-items: center;
      flex-direction: column;
      border: 0;
      padding: 0;
      > div {
        padding: 0;
        &:first-child {
          width: 100%;
        }
        > img {
          position: relative;
          max-height: 200px;
          width: initial;
          left: 0;
          display : none;
        }
      }
    }
  }
  .ImYoursAppPromoteComponentImage {
    background-position: center;
    background-size: 400px !important;
    background-repeat: no-repeat;
  }
}
