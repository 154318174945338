.RecessesOfTheSoulGiftComponent {
  background-color: #fff; 
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 91px 16px;

  .Inside {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 72px;
    gap: 72px;

    &Graphic {
      width: 100%;
      max-width: 250px;
      height: 100%;
      max-height: 250px;
    }

    &Info {
      display: flex;
      flex-direction: column;
      gap: 16px;
      h2 {
        font-family: Savoye;
        font-size: 72px;
        font-weight: 400;
        text-align: left;
        color: #000;
      }
      p {
        max-width: 401px;
        text-align: left;
        color: #000;
        font-family: Cera;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
      }
    }
  }

  .giftModal {
    display: flex;
    position: absolute;
    top: 60px;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background: #000;
    box-shadow: 0 3px 20px rgba(144, 154, 139, 0.05);

    @media screen and (max-width: 900px) {
      max-width: 366px;
      left: 0;
      top: 63px;
    }
    * {
      color: #ffff !important;
    }

    &Details {
      display: flex;
      gap: 16px;
      width: 100%;
      > button {
        margin-left: auto;
      }
    }
  }
}

.center {
  * {
    margin: auto;
  }
}
