@import "../../../styles/colors";
@import "../../../styles/typography";

.downloadICSPageWrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  //   background: red;
  background: $brand-background-accent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card {
  background: $white;
  padding-top: 80px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 44px;
  min-width: 536px;
  height: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;

  > div:last-child {
    max-width: 416px;
    color: $main-operational-accent;
    // font-family: "DidactGothic-Regular";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.03em;
    text-align: center;
  }
}
.dateTitles {
  font-size: 51px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: -0.04em;
  text-align: left;
}

.card > div:first-child {
  display: flex;
  gap: 24px;
  > div:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  > div {
    // font-family: "Friday-Regular";
    font-size: 51px !important;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: -0.04em;
    text-align: left;
  }
  > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 558px) {
  .downloadICSPageWrapper {
    padding: 16px 16px;
  }
  .card {
    background: $white;
    padding-top: 40px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 40px;
    min-width: 100%;
    height: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;

    > div:first-child > * > * {
      font-size: 36px !important;
    }
    > div:nth-child(2) > * {
      font-size: 21px !important;
    }
    > div:nth-child(3) > * {
      font-size: 14px !important;
      text-align: center;
    }

    > div:nth-child(4) > * > a {
      padding: 8px !important;
    }

    > div:last-child {
      font-size: 14px !important;
    }
  }
}
