.CallMeByYourNameContactInfoComponent {
  background-color: #F1EDE2;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 91px 16px;
  gap: 32px;
  > h1 {
    font-family: Catamount; 
    font-size: 128px;
    font-weight: 400;
    line-height: 130px;
    letter-spacing: 0.01em;
    text-align: left;
    color : #1C3924;

  }
  > div:last-child {
    max-width: 896px;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.Card {
  background-color: transparent;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex-direction: column;
  border-radius: 24px;
  width: 350px;
  height: 200px;

  > div:first-child {
    display: flex;
    justify-content: center;
    gap: 16px;
    align-items: center;
    h4 {
      font-family: Noto;
      font-size: 18px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: -0.6299999952316284px;
      text-align: left;
      color : #1C3924;
      
    }
    h3 {
      font-family: Noto; 
      font-size: 28px;
      font-weight: 300;
      line-height: 48px;
      letter-spacing: -0.03em;
      text-align: left;
      color: #1C3924;
      
    }
  }
}

//mobile
@media only screen and (max-width: 768px) {
  .CallMeByYourNameContactInfoComponent {
    height: 60vh !important;
  }
  .CallMeByYourNameContactInfoComponent {
    background-position: center;
    background-size: 400px !important;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 568px) {
  .CallMeByYourNameContactInfoComponent {
    height: 50vh !important;
  }
  .CallMeByYourNameContactInfoComponent {
    background-position: center;
    background-size: 350px !important;
    background-repeat: no-repeat;
  }
}
