@import "../../../styles/colors";

.headBtns {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  padding: 0 40px;
}

.button {
  border: 1px solid #63435b;
  border-radius: 8px;
}

.dropdown {
  position: absolute;
  display: none;
  flex-direction: column;
  gap: 12px;

  background-color: $secondary-strong-accent;
  border-radius: 8px;
  border: 1px solid $brand-background-accent;
  padding: 8px 12px;
  z-index: 1000;
  width: 464px !important;

  * {
    width: 100%;
    color: $brand-background-accent !important;
  }

  &Active {
    display: flex;
    top: 0px;
    position: absolute;
    width: 100% !important;
    margin-top: 60px;
    width: 464px !important;
    padding: 28px;

    &Tablet {
      display: flex;
      top: 0px;
      position: absolute;
      width: 100% !important;
      margin-top: 70px;
      width: 464px !important;
      padding: 28px;
    }

    &Mobile {
      top: 80px;
      position: fixed;
      margin-top: 90px;
      left: 0px;
      width: 100%;
      max-width: 100vw;
    }
  }

  > button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    border: 0;
    background: 0;
    font-size: 16px;
    width: 100%;
    height: 30px;
    z-index: 1000;
    background-color: #fdf6eb;
    color: black;

    &:disabled {
      opacity: 0.4;
    }

    h4 {
      text-overflow: ellipsis;
      width: max-content !important;
      color: black !important;
      padding: 0 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &Active {
      background-color: $brand-operational-accent;
      border-radius: 8px;
      margin-right: -20px;
    }
  }
}

.guestsTable {
  overflow: hidden;
}

.guestsPage {
  display: flex;
  gap: 28px;
  flex-direction: column;
  width: calc(100vw - 132px);
  height: 100vh;
  padding: 40px 40px 40px 0;
  overflow-y: scroll !important;

  &Laptop {
    width: calc(100vw - 72px);
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }

  @media screen and (max-width: 850px){
    width: 100%;
    padding: 0;
    height: 90vh;
  }
}

.guestsEvents {
  display: flex;
  gap: 24px;
  justify-content: left;
  overflow-x: scroll;
  overflow-y: hidden;
  //height: 100%;
  padding: 0 8px;
  min-height: 114px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }
}

.filledButton {
  display: flex;
  justify-content: center;
  background-color: $brand-operational-accent;
  align-items: center;
  color: #63435b;
  border: 1px solid #f9ac80;
  border-radius: 8px;
  width: 195px;
  height: 56px;
}

.filledButton:hover:enabled {
  display: flex;
  justify-content: center;
  background-color: $secondary-strong-accent;
  align-items: center;
  color: $white;
  border: 1px solid $secondary-strong-accent;
  border-radius: 8px;
  width: 195px;
  height: 56px;
  > * {
    color: $white;
  }
}

.filledButton > *:first-child {
  padding-right: 8px;
}

.excelInput::-webkit-file-upload-button {
  visibility: hidden;
}

.excelInput {
  color: transparent;
}

.excelInput::before {
  content: "Select some files";
  color: black;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.excelInput:hover::before {
  border-color: black;
}

.excelInput:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.countersPanel {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.countersPanel > p {
  font-size: 21.33px;
}

.countersPanel > p > span {
  font-size: 28.43px;
  padding-right: 12px;
}

/* START data-tooltip STYLES */
[data-tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all data-tooltips */
[data-tooltip]::before,
[data-tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: 0.9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

[data-tooltip]::before {
  content: "";
  border: 5px solid transparent; /* opinion 4 */
  z-index: 2000000002; /* absurdity 1 */
}

[data-tooltip]::after {
  content: attr(data-tooltip); /* magic! */

  /* most of the rest of this is opinion */
  font-family: "DidactGothic-Regular";
  text-align: center;

  /*
    Let the content set the size of the data-tooltips
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 5px;
  box-shadow: 0 1em 2em -0.5em #835978;
  background: #c7adc1;
  color: #ffffff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the data-tooltips respond to hover */
[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
  display: block;
}

/* don't show empty data-tooltips */
[data-tooltip=""]::before,
[data-tooltip=""]::after {
  display: none !important;
}

/* FLOW: UP */
[data-tooltip]:not([data-flow])::before,
[data-tooltip][data-flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}

[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow^="up"]::after {
  bottom: calc(100% + 5px);
}

[data-tooltip]:not([data-flow])::before,
[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow^="up"]::before,
[data-tooltip][data-flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* FLOW: DOWN */
[data-tooltip][data-flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #63435b;
}

[data-tooltip][data-flow^="down"]::after {
  top: calc(100% + 5px);
}

[data-tooltip][data-flow^="down"]::before,
[data-tooltip][data-flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, 0.5em);
}

/* FLOW: LEFT */
[data-tooltip][data-flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-0.5em, -50%);
}

[data-tooltip][data-flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-0.5em, -50%);
}

/* data-flow: RIGHT */
[data-tooltip][data-flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(0.5em, -50%);
}

[data-tooltip][data-flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(0.5em, -50%);
}

/* KEYFRAMES */
@keyframes data-tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

@keyframes data-tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}

.sendModalSearchResults {
  max-height: 352px;
  overflow-y: scroll;
}

/* FX All The Things */
[data-tooltip]:not([data-flow]):hover::before,
[data-tooltip]:not([data-flow]):hover::after,
[data-tooltip][data-flow^="up"]:hover::before,
[data-tooltip][data-flow^="up"]:hover::after,
[data-tooltip][data-flow^="down"]:hover::before,
[data-tooltip][data-flow^="down"]:hover::after {
  animation: data-tooltips-vert 300ms ease-out forwards;
}

[data-tooltip][data-flow^="left"]:hover::before,
[data-tooltip][data-flow^="left"]:hover::after,
[data-tooltip][data-flow^="right"]:hover::before,
[data-tooltip][data-flow^="right"]:hover::after {
  animation: data-tooltips-horz 300ms ease-out forwards;
}

.sideView {
  position: relative;
  min-width: 360px;
  //min-height: 70vh;
  padding: 88px 48px;
  border: 1px solid #DAD8D9;
  border-radius: 8px;

  >div {
    width: 100%;
  }

  &Mobile {
    display: none;
  }

  &Close {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
  }
}

.show {
  display: flex;
  //position: absolute;
  //top: 0;
  //left: 0;
  //background: white;
  //z-index: 100;
  //width: 100%;
}
