@import "../../../../styles/colors";

.guestsPage  {
  display: flex;
  justify-content: space-between;

  &Mobile, &Tablet {
    flex-direction: column;
    margin-top: 16px;
    padding: 0 16px;
    gap: 16px;
  }
}

.searchBar {
  height: 56px;
  display: flex;
  //justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 724px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.63px;
  flex-direction: row;
  padding: 16px;
  gap: 8px;
  border: 1px solid #dad8d9;
  border-radius: 8px;

  &Active {
    border: 1px solid $brand-operational-accent;
  }
}

.searchBar > input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 728px;
  border: 0px;
  font-size: 16px;
}
.headBtns {
  //position: relative;
  display: flex;
  flex-direction: row;
  //justify-content: flex-end;
  //align-items: center;
  gap: 16px;
  justify-content: center;
  .maxContentBtn {
    height: 58px;
    width: max-content;
  }
}

.sendDropdown {
   position: absolute;
   display: none;
   flex-direction: column;
   gap: 12px;
   background-color: $white;
   border-radius: 8px;
   border: 1px solid $secondary-strong-accent;
   width: 309px;
   margin-top: 8px;
   //margin-left: -150px;
   padding: 8px 12px;
   z-index: 101;

   &Active {
     display: flex;

     &Mobile {
       left: 0;
       margin-left: 20px !important;
     }
   }



   > &Button {
     display: flex;
     justify-content: space-between;
     align-items: center;
     cursor: pointer;
     border: 0;
     padding: 4px 8px;
     background: 0;
     font-size: 16px;

     &:disabled {
       opacity: 0.4;
     }

     &Active {
       background-color: $brand-operational-accent;
     }
   }
 }

.dropdown {
  position: absolute;
  display: none;
  flex-direction: column;
  gap: 12px;

  background-color: $secondary-strong-accent;
  border-radius: 8px;
  border: 1px solid $brand-background-accent;
  padding: 8px 12px;
  z-index: 1000;
  width: 464px !important;
  * {
    width: 100%;
    color: $brand-background-accent !important;
  }

  &Active {
    display: flex;
    position: absolute;
    margin-left: -218px;
    margin-top: 6px;
    width: 464px !important;
    padding: 28px;

    &Tablet  {
      display: flex;
      top: 0px;
      position: absolute;
      margin-top: 70px;
      width: 464px !important;
      padding: 28px;
    }
    &Mobile {
      top: 80px;
      position: fixed;
      margin-top: 90px;
      margin-left: 0;
      left: 0;
      width: 100%;
      max-width: 100vw;
    }
  }

  > button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    border: 0;
    background: 0;
    font-size: 16px;
    width: 100%;
    height: 30px;
    z-index: 1000;
    background-color: #fdf6eb;
    color: black;
    &:disabled {
      opacity: 0.4;
    }
    h4 {
      text-overflow: ellipsis;
      width: max-content !important;
      color: black !important;
      padding: 0 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &Active {
      background-color: $brand-operational-accent;
      border-radius: 8px;
      margin-right: -20px;
    }
  }
}

.tooltip {
  max-width: 140px;
  position: absolute;
  background-color: $secondary-strong-accent;
  color: $brand-background-accent;
  font-size: 14px;
  border-radius: 6px;
  padding: 8px;
  z-index: 1000;
}
