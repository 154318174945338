@import "../TheArtist.module.scss";

.TheArtistContactInfoComponent {
  background-color: #FFF;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 91px 16px;
  gap: 32px;
  > h1 {
    font-family: Syne; 
    font-size: 50px;
    font-weight: 400;
    text-align: center;
    color: #000;
  }
  > div:last-child {
    max-width: 896px;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.Card {
  background-color: transparent;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex-direction: column;
  width: 350px;
  height: 200px;
  border: 1px solid #000;

  > div:first-child {
    display: flex;
    justify-content: center;
    gap: 16px;
    align-items: center;
    h4 {
      font-family: CenturyGothic;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px; /* 160% */
      text-align: left;
      color: #000;
    }
    h3 {
      font-family: CenturyGothic; 
      font-size: 24px;
      font-weight: 400;
      line-height: 32px; /* 133.333% */
      letter-spacing: -0.03em;
      text-align: left;
      text-transform: uppercase;
      color: #000;
    }
  }
}

//mobile
@media only screen and (max-width: 768px) {
  .TheArtistContactInfoComponent {
    height: 60vh !important;
  }
  .TheArtistContactInfoComponent {
    background-position: center;
    background-size: 400px !important;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 568px) {
  .TheArtistContactInfoComponent {
    height: 50vh !important;
  }
  .TheArtistContactInfoComponent {
    background-position: center;
    background-size: 350px !important;
    background-repeat: no-repeat;
  }
}
