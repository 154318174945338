@import "../../../../styles/colors";

:root {
  --dark: $main-strong-accent;
  --primaryDark: $secondary-strong-accent;
  --primary: $secondary-operational-accent;
  --primaryLight: #c7adc1;
  --accent: $brand-strong-accent;
  --accentLight: $brand-operational-accent;
  --greenLight: $brand-hover-accent;
}

.flex {
  display: flex;
  gap: 8px;
}

.tableContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  .scrollLeft, .scrollRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: $brand-hover-accent;
    border: none;
    cursor: pointer;
    z-index: 10;
    padding: 8px;
    border-radius: 50%;

    // Consider adding :hover styles for better user interaction feedback
    &:hover {
      background-color: lighten($brand-hover-accent, 5%);
    }
  }

  .scrollLeft {
    left: 10px; // Adjust as needed based on your layout
  }

  .scrollRight {
    right: 10px; // Adjust as needed based on your layout
  }
}

.tableWrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  &Mobile {
    height: 45vh;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
  }
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    outline: 1px solid $brand-hover-accent;
    background-color: $brand-hover-accent;
  }
}

// Base styles for the react-virtualized table
.reactVisualizedTable {
  > .headerRow {
    background: none;
  }
  > .rowClass {
    border: none !important;
    background: none !important;
  }

  .gridClass {
    &::-webkit-scrollbar {
      width: 4px;
      height: 0;
    }
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      outline: 1px solid $brand-hover-accent;
      background-color: $brand-hover-accent;
    }
  }

  // Styles for the header
  .headerClass {
    font-size: 16px;
    text-transform: capitalize;
    &:hover {
      background: none;
    }
    > button,
    > span {
      background: none;
      border: none;
      width: 100%;
      text-align: left;
    }
  }

  // Styles for the rows
  .rowClass {
    overflow: visible !important;
    border-bottom: 1px solid $accent;
    &:hover {
      background-color: #FDF6EB;
    }
    &Selected {
      background-color: #FDF6EB;
    }
    &Parent {
      border: none;
    }
  }

  .groupButton {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #DAD8D9;
    background-color: #F9AC80;
    text-align: center;
  }

  // Styles for tags
  .tag {
    display: inline-block;
    padding: 3px 8px;
    margin-right: 5px;
    border-radius: 8px;
    background-color: #e1e4e8;
    color: #24292e;
    font-weight: 500;
  }

  // Styles for different statuses
  .status {
    width: fit-content;
    position: relative;
    padding: 8px 16px;
    border-radius: 30px;
    &::before {
      content: '\2022';
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 4px;
    }
  }

  .attendanceStatus {
    width: fit-content;
    padding: 8px 16px;
    border-radius: 30px;
    gap: 4px;
    background: #FFFFFF;
    border: 1px solid #DAD8D9;
  }

  .toolTip {
    z-index: 100;
  }
}

.smallText {
  color: $main-operational-accent;
  font-size: 12px;
}
