
.friday {
  &H1 {
    font-size: 3.157em;
    letter-spacing: -2px;
    font-weight: 400;
    font-family: 'Friday-Regular';
    line-height: 56px;

    @media only screen and (max-width: 1440px) {
      font-size: 41.05px;
      line-height: 48px;
    }

    @media only screen and (max-width: 1920px) and (-webkit-min-device-pixel-ratio: 1.25) {
      font-size: 41.05px;
      line-height: 48px;
    }
  }

  &H2 {
    font-size: 2.369em;
    letter-spacing: -1.5px;
    font-weight: 400;
    font-family: 'Friday-Regular';
    line-height: 40px;

    @media only screen and (max-width: 1440px) {
      font-size: 30.8px;
      line-height: 40px;
    }

    @media only screen and (max-width: 1920px) and (-webkit-min-device-pixel-ratio: 1.25) {
      font-size: 30.8px;
      line-height: 40px;
    }
  }

  &H3 {
    font-size: 1.777em;
    letter-spacing: -1.12px;
    font-weight: 400;
    font-family: 'Friday-Regular';
    line-height: 32px;

    @media only screen and (max-width: 1440px) {
      font-size: 23.1px;
      line-height: 32px;
    }

    @media only screen and (max-width: 1920px) and (-webkit-min-device-pixel-ratio: 1.25) {
      font-size: 23.1px;
      line-height: 32px;
    }
  }

  &H4 {
    font-size: 1.333em;
    letter-spacing: -0.84px;
    font-weight: 400;
    font-family: 'Friday-Regular';
    line-height: 24px;

    @media only screen and (max-width: 1440px) {
      font-size: 17.33px;
      line-height: 24px;
    }

    @media only screen and (max-width: 1920px) and (-webkit-min-device-pixel-ratio: 1.25) {
      font-size: 17.33px;
      line-height: 24px;
    }
  }

  &P {
    font-size: 1em;
    letter-spacing: -0.63px;
    font-weight: 400;
    line-height: 24px;
    font-family: 'Friday-Regular';
  }

  &Span {
    font-size: 0.75em;
    letter-spacing: -0.47px;
    font-weight: 400;
    line-height: 16px;
    font-family: 'Friday-Regular';
    word-break: break-word;
  }
}

.didactic {
  &H1 {
    font-size: 3.157em;
    letter-spacing: -2px;
    font-weight: 400;
    font-family: 'DidactGothic-Regular';
    line-height: 64px;

    @media only screen and (max-width: 1440px) {
      font-size: 41.05px;
      line-height: 56px;
    }

    @media only screen and (max-width: 1920px) and (-webkit-min-device-pixel-ratio: 1.25) {
      font-size: 41.05px;
      line-height: 56px;
    }
  }

  &H2 {
    font-size: 2.369em;
    letter-spacing: -1.5px;
    font-weight: 400;
    font-family: 'DidactGothic-Regular';
    line-height: 48px;

    @media only screen and (max-width: 1440px) {
      font-size: 30.79px;
      line-height: 48px;
    }

    @media only screen and (max-width: 1920px) and (-webkit-min-device-pixel-ratio: 1.25) {
      font-size: 30.79px;
      line-height: 48px;
    }
  }

  &H3 {
    font-size: 1.777em;
    letter-spacing: -1.12px;
    font-weight: 400;
    font-family: 'DidactGothic-Regular';
    line-height: 40px;

    @media only screen and (max-width: 1440px) {
      font-size: 23.1px;
      line-height: 32px;
    }

    @media only screen and (max-width: 1920px) and (-webkit-min-device-pixel-ratio: 1.25) {
      font-size: 23.1px;
      line-height: 32px;
    }
  }

  &H4 {
    font-size: 1.333em;
    letter-spacing: -0.84px;
    font-weight: 400;
    font-family: 'DidactGothic-Regular';
    line-height: 32px;

    @media only screen and (max-width: 1440px) {
      font-size: 17.33px;
      line-height: 32px;
    }

    @media only screen and (max-width: 1920px) and (-webkit-min-device-pixel-ratio: 1.25) {
      font-size: 17.33px;
      line-height: 32px;
    }
  }

  &P {
    font-size: 1em;
    letter-spacing: -0.63px;
    font-weight: 400;
    line-height: 24px;
    font-family: 'DidactGothic-Regular';
  }

  &Span {
    font-size: 0.75em;
    letter-spacing: -0.47px;
    font-weight: 400;
    line-height: 16px;
    font-family: 'DidactGothic-Regular';
    word-break: break-word;
  }
}

.tutorial {
  &Div {
    text-align: left;
    max-width: 620px;
    width: 85vw;
    max-height: 580px;
    height: 100%;
    overflow: hidden;
    padding: 24px;
    border-radius: 16px;
  }

  &Iframe {
    max-width: 550px;
    width: 100%;
    max-height: 400px;
    height: 25vh;
    min-height: 250px;
  }

  &IframeMobile {
    max-width: 300px;
    width: 100%;
    max-height: 530px;
    height: 27vh;
    min-height: 250px;
  }
}