.headerSimple {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  > div > img {
    height: 40px !important;
    max-width: 220px;
  }
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px 72px;
  width: 100%;
  
  z-index: 10;

  @media screen and (max-width: 1024px) {
    padding: 24px;
  }

  > img {
    max-width: 220px;
    margin: 2vw 0 7vw;
  }
}
.header > a {
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    margin-right: 0px;
  }
}

.mobileList {
  display: flex;
  flex-direction: column;
  width: 100svw;
  height: 100svh;
}

.list {
  position: absolute;
  right: 0;
  height: 100svh;
  width: 100svw;
  margin-top: 100px; 
  flex-direction: column; 
  align-items: center; 
  gap: 16px;
  font-family: 'Didact Gothic', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30.79px;
  line-height: 48px;
  /* identical to box height, or 156% */

  letter-spacing: -0.03em;

  
   

  list-style-type: none;

 

}

.burger {
  display: block;
}


.header > div:last-child {
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.letsStartButton {
  width: 160px !important;
  height: 40px;
  background: #f9ac80;
  border-radius: 8px;
  color: #63435b;

  cursor: pointer;
  padding: 12px 32px;
}

.letsStartButtonDisabled {
  width: 160px !important;
  height: 40px;
  background: #63435b;
  border-radius: 8px;
  color: white;
  padding: 12px 32px;
  line-height: 24px;
}
.loginButton {
  width: 100px;
  height: 20px;
}
.header > div > img {
  height: 40px;
}

.header > div:nth-child(2) > a {
  margin-right: 40px;
}

//Tablet && Devices
@media only screen and (max-width: 1023px) {
  .letsStartButton {
    width: fit-content !important;
    height: fit-content;
    background: #f9ac80;
    border-radius: 8px;
    color: #63435b;
    padding: 12px 8px;
  }
 
  .letsStartButtonDisabled {
    width: fit-content !important;
    height: fit-content;
    background: #63435b;
    border-radius: 8px;
    color: white; 
    padding: 12px 8px;
  }
  .header > div:nth-child(2) > a {
    display: none;
  }
  .header > div:nth-child(2) > a:last-child {
    display: block;
    margin-right: 1vw;
  }
  .header > img {
    width: 150px;
  }
  .headerSimple > img {
    width: 150px;
  }

}

//Mobile Devices
@media only screen and (max-width: 425px) {
  .header > div:nth-child(2) > a:last-child {
    width: 100px;
    text-align: center;
  }
  .header > div:nth-child(2) {
    margin-left: 20px;
    width: fit-content;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .letsStartButton {
    padding: 8px 4px;
  }
  .letsStartButtonDisabled {
    padding: 8px 4px;
  }
}
