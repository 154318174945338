@import "../../../styles/colors";

.loadingBox {
  height: fit-content;
  width: 100%;
  backdrop-filter: blur(0.5px);
  padding-bottom: 0px;
  margin-top: -88px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden;
  z-index: 100;
}

.weddingCard {
  padding: 8px 8px 24px 8px;
  background: white;
  border-radius: 8px;
  cursor: pointer;


}
.weddingCard:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}

.weddingCard > div > img , .weddingCard > img, .weddingImage{
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  object-fit: cover;
  height: 216px;
  max-height: 216px;
  border-radius: 8px;
  margin: 0;
}
 
@media only screen and (max-width: 820px) {
  .weddingCard {
    padding: 8px 8px 8px 8px;
    background: white;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 100%;
    // height: fit-content;

    > div {
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      width: max-content;
    }
    > div > h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(40vw - 20px);
      text-align: left;
    }
    > div > p {
      text-align: left;
    }
  }
  .weddingCard > div > img {
    width: 100%;
    min-width: 120px;
    max-width: 120px;
    object-fit: cover;
    min-height: 120px;
    max-height: 120px;
    border-radius: 8px;
    margin: 0;
  }
  .weddingCard > img {
    width: 100%;
    min-width: 120px;
    max-width: 120px;
    object-fit: cover;
    min-height: 120px;
    max-height: 120px;
    border-radius: 8px;
    margin: 0;
  }
}
