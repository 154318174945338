@import "../../../styles/colors";

.loadingBox {
  height: fit-content;
  width: 100%;
  backdrop-filter: blur(0.5px);
  padding-bottom: 0px;
  margin-top: -88px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden;
  z-index: 100;
}

.weddingCard { 
  padding: 8px 8px 24px 8px;
  background: $brand-background-accent;
  border-radius: 8px;
  cursor: pointer;
   
    position: relative;
    max-width: 338px;
    width: 90%;
    height: 300px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
   
}
.weddingCard:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}

.weddingCard > div > img , .weddingCard > img, .weddingImage{
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  object-fit: cover;
  height: 216px;
  max-height: 216px;
  border-radius: 8px;
  margin: 0;
}
 
@media only screen and (max-width: 820px) {
  .weddingCard {
    padding: 8px 8px 8px 8px;
    background: white;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100%;
    // height: fit-content;

    > div {
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      width: max-content;
    }
    > div > h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(40vw - 20px);
      text-align: left;
    }
    > div > p {
      text-align: left;
    }
  }
  .weddingCard > div > img {
    width: 100%;
    min-width: 120px;
    max-width: 120px;
    object-fit: cover;
    min-height: 120px;
    max-height: 120px;
    border-radius: 8px;
    margin: 0;
  }
  .weddingCard > img {
    width: 100%;
    min-width: 120px;
    max-width: 120px;
    object-fit: cover;
    min-height: 120px;
    max-height: 120px;
    border-radius: 8px;
    margin: 0;
  }
}


/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid gray;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbonTopRight {
  top: -10px;
  right: -10px;
}
.ribbonTopRight::before,
.ribbonTopRight::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbonTopRight::before {
  top: 0;
  left: 0;
}
.ribbonTopRight::after {
  bottom: 0;
  right: 0;
}
.ribbonTopRight span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}
